import React from "react";
import useReorderWithArrowKeys from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridReorderRows/useReorderWithArrowKeys";
import { GenericObject } from "../../../../../interfaces/GenericObject";
import {
  FunctionReorderInternal,
  ReorderRowsConfig,
} from "./gridReorderRowsInterfaces";

export function useGridReorderRows(
  reorderRowsConfig: ReorderRowsConfig | undefined,
  isReorderingEnabled: boolean,
  selectedItems: GenericObject[],
  dataResultWithSelectedField: GenericObject[],
  dataItemKey: string
) {
  const hintRef = React.useRef<HTMLDivElement>(null);
  const [draggedItems, setDraggedItems] = React.useState<GenericObject[]>([]);
  const [isReordering, setIsReordering] = React.useState(false);

  const itemIsBeingDragged = React.useCallback(
    (item: GenericObject) =>
      draggedItems.some(
        (draggedItem) => draggedItem[dataItemKey] === item[dataItemKey]
      ),
    [draggedItems]
  );

  const unselectedItemIsBeingDragged = React.useMemo(
    () =>
      draggedItems.length === 1 &&
      !selectedItems.some(
        (selectedItem) =>
          selectedItem[dataItemKey] === draggedItems[0][dataItemKey]
      ),
    [draggedItems, selectedItems]
  );

  const reorder: FunctionReorderInternal = async (
    anchorItem: GenericObject,
    direction: "before" | "after" | null
  ) => {
    if (itemIsBeingDragged(anchorItem) || direction === null) return;

    const itemsToReorder = unselectedItemIsBeingDragged
      ? draggedItems
      : selectedItems;

    setDraggedItems([]);
    if (!reorderRowsConfig) return Promise.resolve();

    setIsReordering(true);
    try {
      await reorderRowsConfig.reorderRows(
        itemsToReorder,
        anchorItem,
        direction
      );
    } catch {}
    setIsReordering(false);
  };

  const dragStart = (draggedItem: GenericObject) => {
    const shouldDragAllSelectedItems = selectedItems.some(
      (item) => item[dataItemKey] === draggedItem[dataItemKey]
    );

    if (shouldDragAllSelectedItems) setDraggedItems(selectedItems);
    else setDraggedItems([draggedItem]);
  };

  const dragEnd = () => {
    setDraggedItems([]);
  };

  useReorderWithArrowKeys(
    isReorderingEnabled,
    selectedItems,
    dataResultWithSelectedField,
    dataItemKey,
    isReordering,
    reorder
  );

  return {
    hintRef,
    draggedItems,
    reorder,
    dragStart,
    dragEnd,
    itemIsBeingDragged,
  };
}
