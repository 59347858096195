import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { EintragFrontend } from "../../../../../interfaces/parameterlisten.types";
import { QUERY_KEY_GET_EINTRAEGE } from "../../useGetEintraege";
import getQueryDataFromFirstEintrag from "./getQueryDataFromFirstEintrag";

type MutationMoveEintragDown = {
  selectedEintraege: EintragFrontend[];
  anchorEintrag: EintragFrontend;
  mode: ModeMoveEintrag;
};
type ModeMoveEintrag = "move-up" | "move-down";

export default function useMoveEintraege(
  setIsGridLoading: React.Dispatch<React.SetStateAction<boolean>>
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      selectedEintraege,
      anchorEintrag,
      mode,
    }: MutationMoveEintragDown) => {
      setIsGridLoading(true);

      const params: Record<string, string> = {};
      const itemId = anchorEintrag.ITEMID;
      if (mode === "move-up") params.insertbefore = itemId;
      if (mode === "move-down") params.insertafter = itemId;

      const eintraegeToMove = createEintraegeToMove(selectedEintraege, mode);
      const { dbName, contId, plID } =
        getQueryDataFromFirstEintrag(selectedEintraege);

      for (const eintrag of eintraegeToMove) {
        await axios.patch(
          `/api/db/${dbName}/cont/${contId}/pl/${plID}/items/${eintrag.ITEMID}`,
          null,
          {
            params: params,
          }
        );
      }
    },
    onSuccess: (_response, variables) => {
      const { dbName, contId, plID } = getQueryDataFromFirstEintrag(
        variables.selectedEintraege
      );

      return queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_GET_EINTRAEGE, dbName, contId, plID],
      });
    },
    onError: () => {
      setIsGridLoading(false);
    },
  });
}

/*
  Wir machen ein `toReversed` auf das Array, ansonsten werden die Einträge
  rückwertig verschoben.
*/
const createEintraegeToMove = (
  eintraege: EintragFrontend[],
  mode: ModeMoveEintrag
) => (mode === "move-up" ? eintraege : eintraege.toReversed());
