import React from "react";
import { GridRef } from "src/abk-shared/components/organisms/ABKBasisGrid/components/BasisGridContextProvider/contexts";
import { getGridVirtualScrollingContainer } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useFocusOnGridOnMount/getGridVirtualScrollingContainer";
import { getScrollTopLocalStorage } from "./scrollTopLocalStorage";

export default function useSetInitialScrollTop(
  gridRef: GridRef,
  isMobileGrid: boolean,
  gridUniqueId: string,
  rowIndexForInitialScrollTop?: number
) {
  // Inspiriert von: https://feedback.telerik.com/kendo-react-ui/1657695-data-grid-with-virtual-scrolling-scrolls-jumps-to-top-when-the-total-number-of-records-changes
  React.useEffect(() => {
    if (isMobileGrid) return;

    let calculatedScrollTop = -1;
    if (gridRef?.current && rowIndexForInitialScrollTop != null) {
      gridRef.current.scrollIntoView({
        rowIndex: rowIndexForInitialScrollTop,
      });
      return;
    }

    const element = getGridVirtualScrollingContainer(gridRef);
    const scrollTop = getScrollTopLocalStorage(gridUniqueId, isMobileGrid);

    if (element) {
      if (calculatedScrollTop !== -1) element.scrollTop = calculatedScrollTop;
      else element.scrollTop = scrollTop;
    }
  }, [isMobileGrid, rowIndexForInitialScrollTop, gridRef, gridUniqueId]);
}
