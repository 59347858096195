import { CatalogItem } from "src/modules/Datei/pages/components/CatalogItemsWrapper/CatalogItemsWrapper";
import { statusMapper } from "src/modules/Datei/pages/components/CatalogItemsWrapper/statusMapper";
import { ParameterlisteFrontend } from "../../../interfaces/parameterlisten.types";

export const backendCatalogItemToFrontend = (parameterliste: CatalogItem) =>
  ({
    ...parameterliste,
    Kenndaten: {
      ...parameterliste.Kenndaten,
      STATUS: statusMapper.get(parameterliste.Kenndaten.STATUS),
    },
  }) as ParameterlisteFrontend;
