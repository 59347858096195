import { classNames } from "@progress/kendo-react-common";
import React from "react";
import {
  BasisGridPropsContext,
  SelectionContext,
} from "src/abk-shared/components/organisms/ABKBasisGrid/components/BasisGridContextProvider/contexts";
import { GenericObject } from "src/abk-shared/interfaces/GenericObject";
import "./style.scss";

export type CustomRowProps = React.HTMLAttributes<HTMLTableRowElement> & {
  dataItem: GenericObject;
};

export const CustomRow = React.forwardRef<HTMLTableRowElement, CustomRowProps>(
  function CustomRow(props, ref) {
    const { selection, dataItemKey } = React.useContext(BasisGridPropsContext);
    const { selectedState } = React.useContext(SelectionContext);
    const { setDataItemSelected } = React.useContext(SelectionContext);

    const isHighlightedItem =
      selection?.highlightedItem?.[dataItemKey] === props.dataItem[dataItemKey];
    const isSelected = selectedState[props.dataItem[dataItemKey]] === true;

    const { dataItem, ...trProps } = props;

    return (
      <tr
        {...trProps}
        ref={ref}
        className={classNames(
          {
            "k-selected": isSelected,
          },
          trProps.className,
          {
            "custom-row-highlighted-item": isHighlightedItem,
          }
        )}
        {...(isHighlightedItem && { "data-testid": "grid-highlighted-row" })}
        /*
          When we click on the row, we should select the item.
          We shouldn't need this `onClick` prop normally, because the selection
          is already handled by the Grid prop `selectable.enabled`.

          However, when we override a cell and place a link inside the overridden
          cell, we have a problem: the user will not be able to click on the
          link and will only be able to select the item, even when clicking on
          the link.
          Therefore, we need to remove the default `tdProps` in the overridden cell.

          But if we remove the `tdProps`, then the user can click on the link,
          but is not able to click on the cell containing the link to select the item.
          This `onClick` event handler below solves this problem.
        */
        onClick={() => setDataItemSelected(props.dataItem, true, true)}
      />
    );
  }
);
