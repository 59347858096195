import Dexie, { type Table } from "dexie";
import { Gliederungssystem } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import {
  EintragFrontend,
  Parameterliste,
} from "../../modules/Datei/interfaces/parameterlisten.types";
import { Cont } from "../interfaces/Cont";

interface ContainerInfoDraft {
  database: string;
  contId: string;
  etag: string;
  edited: Cont;
  sections: Map<string, boolean>;
}

interface ParameterlisteDraft {
  database: string;
  contId: string;
  dataId: string;
  etag: string;
  edited: Parameterliste;
  sections: Map<string, boolean>;
}

interface GliederungssystemDraft {
  database: string;
  contId: string;
  dataId: string;
  etag: string;
  edited: Gliederungssystem;
  sections: Map<string, boolean>;
}

interface EintragDraft {
  database: string;
  contId: string;
  dataId: string;
  itemId: string;
  etag: string;
  edited: EintragFrontend;
  sections: Map<string, boolean>;
}

const dbDrafts = new Dexie("Drafts") as Dexie & {
  containerInfoDrafts: Table<ContainerInfoDraft, string>;
  parameterListeDrafts: Table<ParameterlisteDraft, [string, string]>;
  gliederungssystemDrafts: Table<GliederungssystemDraft, [string, string]>;
  eintraegeDrafts: Table<EintragDraft, [string, string, string]>;
};

// Schema declaration:
dbDrafts.version(1).stores({
  containerInfoDrafts: "++contId, database",
  parameterListeDrafts: "[contId+dataId], database",
  gliederungssystemDrafts: "[contId+dataId], database",
  eintraegeDrafts: "[contId+dataId+itemId], database",
});

export { dbDrafts };
export type {
  ContainerInfoDraft,
  EintragDraft,
  GliederungssystemDraft,
  ParameterlisteDraft,
};
