import React from "react";
import ABKControlledExpansionPanel from "src/abk-shared/components/atoms/ABKControlledExpansionPanel";
import ExpansionPanelTitle from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/ExpansionPanelTitle";
import generatePreviewTextForListOfIFields from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/previewTextGenerator";
import { ABKLoader } from "src/abk-shared/components/atoms/ABKLoader/ABKLoader";
import ABKFormFieldInput from "../../../../abk-shared/components/atoms/ABKFormFieldInput";
import ABKFormRow from "../../../../abk-shared/components/atoms/ABKFormRow";
import useGetDBStatus from "../../queries/useGetDBStatus";
import DbActionButtons from "./DbActionButtons";
import "./style.css";

const ID_ZUSTAND = "Zustand";
const DbStatusPage = () => {
  const { data: db, isLoading } = useGetDBStatus();
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [statusIsExpanded, setStatusIsExpanded] = React.useState(true);

  let statusText: string;
  // Class für die Input, um die gleiche Width zu haben
  let statusClass = "k-input k-input-md k-rounded-md k-input-solid ";

  switch (db?.DBStatus?.Condition) {
    case "OK":
      statusText = "OK";
      statusClass += "status-ok";
      break;
    case "UpdateRequired":
      statusText = "Struktur-Update erforderlich";
      statusClass += "status-update-required";
      break;
    case "DBNotFound":
      statusText = "Datenbank nicht vorhanden";
      statusClass += "status-structure-error";
      break;
    case "StructureError":
      statusText = "Datenbankstruktur ungültig";
      statusClass += "status-structure-error";
      break;
    case "ConnectError":
      statusText = "Verbindung zur Datenbank nicht möglich";
      statusClass += "status-connect-error";
      break;
    default:
      statusText = "Unbekannter Status";
      statusClass += "status-unknown";
  }
  const dbConfigFields = [
    { label: "Bezeichnung", value: db?.DBNAME || "", isCheckbox: false },
    { label: "Server", value: db?.SERVER || "", isCheckbox: false },
    { label: "Typ", value: db?.DBTYPE || "", isCheckbox: false },
    { label: "Name", value: db?.DATABASE || "", isCheckbox: false },
  ];
  const dbConfigPreview = generatePreviewTextForListOfIFields(dbConfigFields);

  const statusFields = [
    { label: "Zustand", value: statusText || "", isCheckbox: false },
    {
      label: "Meldung",
      value:
        statusText !== "OK" ? db?.DBStatus?.ExceptionInfo?.title || "" : "",
      isCheckbox: false,
    },
    {
      label: "Aktuell",
      value:
        db?.DBStatus?.VersionRequired === db?.DBStatus?.VersionActual
          ? "Ja"
          : "Nein",
      isCheckbox: false,
    },
    {
      label: "Version",
      value: db?.DBStatus?.VersionActual || "",
      isCheckbox: false,
    },
  ];
  const statusPreview = generatePreviewTextForListOfIFields(statusFields);

  React.useEffect(() => {
    const statusElement = document.getElementById(ID_ZUSTAND);
    if (statusElement) {
      statusElement.textContent = statusText;
      statusElement.className = statusClass;
    } else {
      // eslint-disable-next-line no-console
      console.error("Status element not found");
    }
  }, [isLoading]);

  if (isLoading) return <ABKLoader />;

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <ABKControlledExpansionPanel
        title={
          <ExpansionPanelTitle
            title="Datenbankkonfiguration"
            previewText={dbConfigPreview}
            shouldShowPreview={!isExpanded}
          />
        }
        expanded={isExpanded}
        onAction={() => setIsExpanded(!isExpanded)}
      >
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="Bezeichnung"
            label="Bezeichnung"
            value={db?.DBNAME}
          />
          <ABKFormFieldInput
            editorId="Datenbanktyp"
            label="Datenbanktyp"
            value={db?.DBTYPE}
          />
        </ABKFormRow>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="Server"
            label="Server"
            value={db?.SERVER}
          />
          <ABKFormFieldInput
            editorId="Databasename"
            label="Databasename"
            value={db?.DATABASE}
          />
        </ABKFormRow>
      </ABKControlledExpansionPanel>
      <ABKControlledExpansionPanel
        title={
          <ExpansionPanelTitle
            title="Status"
            previewText={statusPreview}
            shouldShowPreview={!statusIsExpanded}
          />
        }
        expanded={statusIsExpanded}
        onAction={() => setStatusIsExpanded(!statusIsExpanded)}
      >
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="Server-Version"
            label="Server-Version"
            useJSXForValue={true}
            value={
              <span title={db?.DBStatus?.DBServerVersion}>
                {db?.DBStatus?.DBServerVersion}
              </span>
            }
            title={db?.DBStatus?.DBServerVersion}
          />
          <ABKFormFieldInput
            editorId="Datenbank-ID"
            label="Datenbank-ID"
            value={db?.DBStatus?.DBID}
          />
        </ABKFormRow>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="Version erforderlich"
            label="Version erforderlich"
            value={db?.DBStatus?.VersionRequired}
          />
          <ABKFormFieldInput
            editorId="Version aktuell"
            label="Version aktuell"
            value={db?.DBStatus?.VersionActual}
          />
        </ABKFormRow>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId={ID_ZUSTAND}
            label="Zustand"
            value={statusText}
            className={statusClass}
          />
          <ABKFormFieldInput
            editorId="Meldung"
            label="Meldung"
            value={
              db?.DBStatus?.ExceptionInfo?.title
                ? db?.DBStatus?.ExceptionInfo?.title
                : ""
            }
          />
        </ABKFormRow>
        <DbActionButtons db={db} />
      </ABKControlledExpansionPanel>
    </div>
  );
};

export default DbStatusPage;
