import { format } from "date-fns";
import React from "react";
import ABKDatePickerInput from "../../../../../../abk-shared/components/atoms/ABKDatePickerInput";
import { isValidDate } from "../../../../../../abk-shared/utility/dateUtils";
import { AllgemeinFormGeneralProps } from "./AllgemeinForm";

export default function KenndatenDate({
  readOnly,
  setKenndaten,
  kenndaten,
}: AllgemeinFormGeneralProps) {
  const backendKey = "VERSIONSDATE";
  const versionDateParsed = new Date(kenndaten[backendKey] ?? "");
  const versionDate = isValidDate(versionDateParsed) ? versionDateParsed : null;
  const setVersionDate = React.useCallback(
    (date: Date | null) => {
      const dateString = date == null ? null : format(date, "yyyy-MM-dd");

      return setKenndaten({
        ...kenndaten,
        [backendKey]: dateString,
      });
    },
    [kenndaten, setKenndaten]
  );

  return (
    <ABKDatePickerInput
      editorId="datum"
      label="Datum"
      value={versionDate}
      setValue={setVersionDate}
      readOnly={readOnly}
      backendKey={backendKey}
    />
  );
}
