import { Checkbox } from "@progress/kendo-react-inputs";
import React from "react";
import ABKToolbar from "src/abk-shared/components/molecules/ABKToolbar";
import { Navigation_More2 } from "../../../../../interfaces/abk9SvgIcons";
import ABKNavigationButtonItem from "../../../../atoms/ABKNavigationButtonItem";
import ABKOptionsIconButton from "../../../../atoms/ABKOptionsIconButton";
import ABKSearchBar from "../../../../molecules/ABKSearchBar";
import { FunctionColumnToggleVisibility } from "../../hooks/useGridColumns/toggleColumnVisibility";
import { GridColumnPropsCustom } from "../../interfaces/GridColumns";
import ABKToggleColumns from "./ABKToggleColumns";
import ABKToolbarGridActions from "./ABKToolbarGridActions";
import "./style.scss";

type Props = {
  filterValue: string;
  onFilterChange: (newValue: string) => void;
  excelExport: {
    disabled: boolean;
    excelExport: () => void;
  };
  isMobileGrid: boolean;
  headerSelectionValue: boolean | null;
  onHeaderSelectionChange: (event: any) => void;
  hasCheckboxSelection: boolean;
  columns: GridColumnPropsCustom[];
  onColumnToggleVisibility: FunctionColumnToggleVisibility;
  handleAutoFitColumns: () => void;
};

const ABKBasisGridToolbar: React.FC<Props> = ({
  filterValue,
  onFilterChange,
  excelExport,
  isMobileGrid,
  headerSelectionValue,
  onHeaderSelectionChange,
  hasCheckboxSelection,
  columns,
  onColumnToggleVisibility,
  handleAutoFitColumns,
}) => {
  return (
    <ABKToolbar
      leftTools={<ABKToolbarGridActions isMobileGrid={isMobileGrid} />}
      rightTools={
        <>
          <ABKSearchBar
            isMobileGrid={isMobileGrid}
            filterValue={filterValue}
            onFilterChange={onFilterChange}
          />
          <ABKOptionsIconButton
            buttonClassName="grid-toolbar-button"
            title="Tabellenmenü"
            svgIcon={Navigation_More2}
            insidePopupElement={
              <>
                <ABKNavigationButtonItem
                  text="Nach Excel exportieren"
                  title="Nach Excel exportieren"
                  onClick={excelExport.excelExport}
                  icon="Data.Format.XLS"
                  disabled={excelExport.disabled}
                />
                {!isMobileGrid && (
                  <>
                    <ABKNavigationButtonItem
                      text="Spaltenbreiten automatisch anpassen"
                      title="Spaltenbreiten automatisch anpassen"
                      onClick={handleAutoFitColumns}
                      icon="View.Expand"
                    />
                    <ABKToggleColumns
                      columns={columns}
                      onColumnToggleVisibility={onColumnToggleVisibility}
                    />
                  </>
                )}
              </>
            }
          />
        </>
      }
      elementsAfterTools={
        <>
          {hasCheckboxSelection && isMobileGrid && (
            <div className="k-flex-none k-align-self-end">
              <Checkbox
                style={{
                  marginRight: 8,
                  marginBottom: 5,
                  width: 30,
                  height: 30,
                }}
                value={headerSelectionValue}
                onChange={(event) => {
                  onHeaderSelectionChange(event);
                }}
              />
            </div>
          )}
        </>
      }
    />
  );
};

export default ABKBasisGridToolbar;
