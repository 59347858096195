import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { useNavigate } from "react-router-dom";
import useTabNavigationStore, {
  TabNavigationItem,
} from "../../../stores/useTabNavigationStore";
import "./styles.css";

const AbkTabNavigation = () => {
  const { tabs, selectedTab, setSelectedTab } = useTabNavigationStore();
  const navigate = useNavigate();

  return (
    <>
      {tabs && tabs.length > 0 && (
        <div
          className="abk-tab-navigation"
          aria-label="AbkTabNavigation"
          data-testid="abk-tab-navigation"
        >
          <TabStrip
            className="abk-tabstrip"
            selected={selectedTab}
            onSelect={(e) => {
              setSelectedTab(e.selected);
              navigate(tabs[e.selected].to);
            }}
          >
            {tabs.map((view: TabNavigationItem) => (
              <TabStripTab
                key={view.id}
                title={view.label}
                data-testid={`tab-${view.id}`}
                aria-label={view.label}
              />
            ))}
          </TabStrip>
        </div>
      )}
    </>
  );
};

export default AbkTabNavigation;
