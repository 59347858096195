import { KenndatenStatus } from "src/modules/Datei/interfaces/kenndaten.types";
import { AllgemeinFormGeneralProps } from "src/modules/Datei/pages/components/KenndatenPanels/Allgemein/AllgemeinForm";
import ABKFormDropdown from "../../../../../../abk-shared/components/atoms/ABKFormDropdown";

export default function DropdownStatus({
  kenndaten,
  setKenndaten,
}: Omit<AllgemeinFormGeneralProps, "readOnly">) {
  const dataItemKey = "id";
  const textField = "text";
  const defaultValue = {
    [textField]: "Entwurf",
    [dataItemKey]: KenndatenStatus.Entwurf,
  };
  const statusOptions = [
    defaultValue,
    {
      [textField]: "Freigegeben",
      [dataItemKey]: KenndatenStatus.Freigegeben,
    },
  ];
  const backendKey = "STATUS";
  const value = statusOptions.find(
    (option) => option[dataItemKey] === kenndaten?.[backendKey]
  );

  return (
    <ABKFormDropdown
      label="Status"
      name="allgemein-status"
      textField={textField}
      dataItemKey={dataItemKey}
      data={statusOptions}
      value={value}
      onChange={(event) =>
        setKenndaten({
          ...kenndaten,
          [backendKey]: event.value[dataItemKey],
        })
      }
      editorId={"status"}
      backendKey={backendKey}
    />
  );
}
