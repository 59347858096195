import { State } from "@progress/kendo-data-query";
import { createContext } from "react";
import { GenericObject } from "../../../../../interfaces/GenericObject";

type DataStateContextType = {
  dataState: State;
  dataResultWithSelectedField: GenericObject[];
};

const DataStateContext = createContext<DataStateContextType>({
  dataState: {},
  dataResultWithSelectedField: [],
});

export default DataStateContext;
