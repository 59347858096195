import { useCallback, useState } from "react";
import ABKEigenschaftenfenster from "src/abk-shared/components/organisms/ABKEigenschaftenfenster";
import { EigenschaftenfensterContent } from "src/abk-shared/components/organisms/ABKEigenschaftenfenster/EigenschaftenfensterContent";
import useDrafts, {
  Draft,
  UseDraftsProps,
} from "src/abk-shared/hooks/useDrafts";

type WithDraftsProps<
  FormDataType,
  DraftType extends Draft<FormDataType>,
  KeyType,
> = {
  onClose: () => void;
  draftsProps: UseDraftsProps<FormDataType, DraftType, KeyType>;
  title: React.ReactNode;
  fensterKey: string;
  initialHeight?: number;
  renderChildren: (
    draftValues: ReturnType<typeof useDrafts<FormDataType, DraftType, KeyType>>
  ) => React.ReactNode;
};

export function ABKEigenschaftenfensterWithDrafts<
  FormDataType,
  DraftType extends Draft<FormDataType>,
  KeyType,
>({
  onClose,
  draftsProps,
  title,
  fensterKey,
  initialHeight,
  renderChildren,
}: WithDraftsProps<FormDataType, DraftType, KeyType>) {
  const [closeWindowFn, setCloseWindowFn] = useState<(() => void) | null>(null);

  const handleWindowClose = useCallback(() => {
    if (closeWindowFn) {
      closeWindowFn();
    } else {
      onClose?.();
    }
  }, [closeWindowFn, onClose]);

  return (
    <ABKEigenschaftenfenster
      title={title}
      onClose={handleWindowClose}
      initialHeight={initialHeight}
    >
      <EigenschaftenfensterContent<FormDataType, DraftType, KeyType>
        key={fensterKey}
        onClose={onClose}
        draftsProps={draftsProps}
        setCloseWindow={setCloseWindowFn}
        renderChildren={renderChildren}
      />
    </ABKEigenschaftenfenster>
  );
}
