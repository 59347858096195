import { TextArea, TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import { useEffect, useState } from "react";
import ExpansionPanelTitle from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/ExpansionPanelTitle";
import { IField } from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/IField";
import { generatePreviewForTextArea } from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/previewTextGenerator";
import ABKControlledExpansionPanel from "../../../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";
import ABKFormRow from "../../../../../../../abk-shared/components/atoms/ABKFormRow";
import ABKForm from "../../../../../../../abk-shared/components/molecules/ABKForm";
import { EintragFrontend } from "../../../../../interfaces/parameterlisten.types";
import { PropsEintragPanel } from "./ParTypePanelsMap";

const RechenansatzPanel = ({
  setPageState,
  pageState,
  sections,
  updateSections,
}: PropsEintragPanel) => {
  const [field, setField] = useState<IField>();

  const fieldRechenansatz: IField = {
    label: "Rechenansatz",
    value: pageState?.FORMEL || "",
    isCheckbox: false,
  };

  useEffect(() => {
    setField(fieldRechenansatz);
  }, [pageState]);

  const handleRechenansatzChanged = (event: TextAreaChangeEvent) => {
    setPageState({
      ...pageState,
      FORMEL: event.target.value,
    } as EintragFrontend);
  };

  const previewText = generatePreviewForTextArea(field);

  const isExpanded = sections?.get("rechenansatz") || false;

  const handlePanelToggle = () => {
    if (updateSections && sections) {
      const newSections = new Map(sections);
      newSections.set("rechenansatz", !isExpanded);
      updateSections(newSections);
    }
  };

  return (
    <ABKControlledExpansionPanel
      title={
        <ExpansionPanelTitle
          title="Rechenansatz"
          previewText={previewText}
          shouldShowPreview={!isExpanded}
        />
      }
      expanded={isExpanded}
      onAction={handlePanelToggle}
    >
      <ABKForm
        sections={[
          {
            formElements: [
              <ABKFormRow>
                <TextArea
                  defaultValue={"Textarea"}
                  autoSize={true}
                  style={{
                    resize: "none",
                    overflowX: "hidden",
                  }}
                  value={pageState?.FORMEL}
                  onChange={handleRechenansatzChanged}
                />
              </ABKFormRow>,
            ],
          },
        ]}
      />
    </ABKControlledExpansionPanel>
  );
};

export default RechenansatzPanel;
