import React from "react";
import { CreateDefaultGridActionsFunction } from "src/core/components/organisms/DefaultGrid/useDefaultGridDeleteFunction/useDefaultGridDeleteFunction.types";
import useIsGridLoading from "src/core/utility/useIsGridLoading";
import { createColumnsDefinitionForCatalogItemsGrid } from "src/modules/Datei/pages/components/CatalogItemsWrapper/CatalogItemsGrid/useCatalogItemsGrid/createColumnsDefinitionForCatalogItemsGrid";
import { useDeleteCatalogItem } from "src/modules/Datei/pages/components/CatalogItemsWrapper/CatalogItemsGrid/useCatalogItemsGrid/useDeleteCatalogItem";
import { CatalogType } from "src/modules/Datei/pages/components/CatalogItemsWrapper/CatalogItemsWrapper";
import { createCatalogItemURL } from "src/modules/Datei/pages/components/CatalogItemsWrapper/createCatalogItemURL";
import ABKBasisGridCellLink from "../../../../../../../abk-shared/components/organisms/ABKBasisGrid/components/ABKBasisGridCellLink";
import { PropsCustomCell } from "../../../../../../../abk-shared/components/organisms/ABKBasisGrid/components/CustomCell";
import { Parameterliste } from "../../../../../interfaces/parameterlisten.types";
import { useCatalogItemsGridActionHandlers } from "./useCatalogItemsGridActionHandlers/useCatalogItemsGridActionHandlers";

export function useCatalogItemsGrid(
  dbName: string,
  contId: string,
  isFetching: boolean,
  catalogType: CatalogType
) {
  const columnsDefinition = React.useMemo(
    createColumnsDefinitionForCatalogItemsGrid,
    []
  );

  const customCells = React.useMemo(
    () => [
      {
        columnField: "Kenndaten.BEZ",
        render: (props: PropsCustomCell) => (
          <ABKBasisGridCellLink
            {...props}
            text={props.dataItem.Kenndaten.BEZ}
            to={createCatalogItemURL(
              catalogType,
              props.dataItem.DATAID,
              props.dataItem.DBNAME,
              props.dataItem.CONTID
            )}
            testProps={{ dataTestid: `link-to-${catalogType}` }}
          />
        ),
      },
    ],
    [catalogType]
  );

  const { isGridLoading, setIsGridLoading } = useIsGridLoading(isFetching);
  const catalogItemsGridActionHandlers = useCatalogItemsGridActionHandlers(
    catalogType,
    dbName,
    contId,
    setIsGridLoading
  );
  const mutationDeleteCatalogItem = useDeleteCatalogItem(
    catalogType,
    dbName,
    contId,
    setIsGridLoading
  );

  const gridActions: CreateDefaultGridActionsFunction = React.useCallback(
    (gridRowSelection, _dataResult, _dataState, deleteFunction) => {
      const selectedItems = gridRowSelection.selectedItems as Parameterliste[];
      const numberOfSelectedItems = selectedItems.length;

      const defaultActions = [
        {
          icon: "Navigation.Open",
          text: "Öffnen",
          onClick: (event: any) =>
            catalogItemsGridActionHandlers.open(event, selectedItems),
        },
        {
          icon: "Grid.InsertEnd",
          text: "Hinzufügen am Tabellenende",
          onClick: async () => {
            await catalogItemsGridActionHandlers.createAtEnd();
          },
        },
        {
          icon: "Grid.InsertBefore",
          text: "Hinzufügen vor aktueller Zeile",
          onClick: async () => {
            await catalogItemsGridActionHandlers.createBeforeRow(
              selectedItems[0]
            );
          },
        },
        {
          icon: "Edit.Delete",
          text: "Löschen",
          onClick: (event: any) => deleteFunction(event, gridRowSelection),
        },
        {
          icon: "Grid.Duplicate",
          text: "Duplizieren",
          onClick: async () =>
            catalogItemsGridActionHandlers.duplicate(selectedItems[0]),
        },
      ];

      if (numberOfSelectedItems === 1) return defaultActions;

      if (numberOfSelectedItems > 1) {
        const filterTexts = [
          "Öffnen",
          "Duplizieren",
          "Hinzufügen vor aktueller Zeile",
        ];
        const filteredActions = defaultActions.filter(
          (action) => !filterTexts.includes(action.text)
        );
        return [
          <>{`${numberOfSelectedItems} ausgewählt`}</>,
          ...filteredActions,
        ];
      }

      return defaultActions.filter((action) =>
        action.text.includes("Hinzufügen am Tabellenende")
      );
    },
    [catalogItemsGridActionHandlers]
  );

  return {
    columnsDefinition,
    customCells,
    gridActions,
    isGridLoading,
    mutationDeleteCatalogItem,
  };
}
