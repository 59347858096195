import { State } from "@progress/kendo-data-query";
import { useLiveQuery } from "dexie-react-hooks";
import React from "react";
import {
  CreateDefaultGridActionsFunction,
  DeleteFunctionDefaultGrid,
} from "src/core/components/organisms/DefaultGrid/useDefaultGridDeleteFunction/useDefaultGridDeleteFunction.types";
import { dbDrafts } from "src/core/db/dbDrafts";
import useDeleteEintrag from "src/modules/Datei/pages/Parameterlisten/Eintraege/useEintraege/mutations/useDeleteEintrag";
import { DragAndDropDirection } from "../../../../../../abk-shared/components/organisms/ABKBasisGrid/hooks/useGridReorderRows/gridReorderRowsInterfaces";
import { GridRowSelection } from "../../../../../../abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import { GenericObject } from "../../../../../../abk-shared/interfaces/GenericObject";
import useIsGridLoading from "../../../../../../core/utility/useIsGridLoading";
import { EintragFrontend } from "../../../../interfaces/parameterlisten.types";
import createEintraegeColumnsDefinition from "./createEintraegeColumnsDefinition";
import createEintraegeCustomCells from "./createEintraegeCustomCells/createEintraegeCustomCells";
import createEintraegeGridActions from "./createEintraegeGridActions";
import useMoveEintraege from "./mutations/useMoveEintraege";
import useEintraegeActionHandlers from "./useEintraegeActionHandlers";

export default function useEintraege(
  isFetching: boolean,
  dataItemKey: "ITEMID",
  dbName: string
) {
  const columnsDefinition = React.useMemo(createEintraegeColumnsDefinition, []);

  const { isGridLoading, setIsGridLoading } = useIsGridLoading(isFetching);

  const mutationDeleteEintrag = useDeleteEintrag(setIsGridLoading, dataItemKey);

  const eintraegeActionHandlers = useEintraegeActionHandlers(
    setIsGridLoading,
    dataItemKey
  );

  const drafts = useLiveQuery(async () => {
    if (!dbName) {
      return [];
    } else {
      return dbDrafts.eintraegeDrafts
        .where("database")
        .equals(dbName)
        .toArray();
    }
  }, [dbName]);

  const customCells = React.useMemo(
    () => createEintraegeCustomCells(drafts, dataItemKey),
    [drafts, dataItemKey]
  );

  const gridActions: CreateDefaultGridActionsFunction = React.useCallback(
    (
      gridRowSelection: GridRowSelection,
      dataResult: GenericObject[],
      dataState: State,
      deleteFunction: DeleteFunctionDefaultGrid
    ) =>
      createEintraegeGridActions(
        eintraegeActionHandlers,
        gridRowSelection,
        dataResult as EintragFrontend[],
        dataState,
        dataItemKey,
        deleteFunction
      ),
    [eintraegeActionHandlers, dataItemKey]
  );

  const mutationMoveEintraege = useMoveEintraege(setIsGridLoading);
  const reorderRows = (
    selectedItems: GenericObject[],
    dataItem: GenericObject,
    direction: DragAndDropDirection
  ) => {
    return mutationMoveEintraege.mutateAsync({
      selectedEintraege: selectedItems as EintragFrontend[],
      anchorEintrag: dataItem as EintragFrontend,
      mode: direction === "before" ? "move-up" : "move-down",
    });
  };

  return {
    columnsDefinition,
    customCells,
    gridActions,
    reorderRows,
    isGridLoading,
    mutationDeleteEintrag,
  };
}
