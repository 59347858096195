import { Loader } from "@progress/kendo-react-indicators";
import React from "react";
import { CatalogItemsGrid } from "src/modules/Datei/pages/components/CatalogItemsWrapper/CatalogItemsGrid/CatalogItemsGrid";
import { CatalogType } from "src/modules/Datei/pages/components/CatalogItemsWrapper/CatalogItemsWrapper";
import { backendCatalogItemToFrontend } from "src/modules/Datei/pages/components/CatalogItemsWrapper/backendCatalogItemToFrontend";
import useGetAllCatalogItems from "src/modules/Datei/pages/components/CatalogItemsWrapper/useGetAllCatalogItems";

type Props = {
  dbName: string;
  contId: string;
  catalogType: CatalogType;
};

export function CatalogItems({ dbName, contId, catalogType }: Props) {
  const {
    data: catalogItems,
    isLoading,
    isFetching,
  } = useGetAllCatalogItems(dbName, contId, catalogType);

  const dataFrontend = React.useMemo(
    () => catalogItems?.map(backendCatalogItemToFrontend),
    [catalogItems]
  );

  if (isLoading) return <Loader />;

  return (
    <CatalogItemsGrid
      catalogItems={dataFrontend ?? []}
      dbName={dbName}
      contId={contId}
      isFetching={isFetching}
      catalogType={catalogType}
    />
  );
}
