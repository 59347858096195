import { useNavigate } from "react-router-dom";
import ABKCompactMobileCard from "src/abk-shared/components/molecules/ABKCompactMobileCard";
import { DefaultGrid } from "src/core/components/organisms/DefaultGrid/DefaultGrid";
import { GliederungssystemFrontend } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import { ParameterlisteFrontend } from "src/modules/Datei/interfaces/parameterlisten.types";
import { useCatalogItemsGrid } from "src/modules/Datei/pages/components/CatalogItemsWrapper/CatalogItemsGrid/useCatalogItemsGrid/useCatalogItemsGrid";
import {
  CatalogItem,
  CatalogType,
} from "src/modules/Datei/pages/components/CatalogItemsWrapper/CatalogItemsWrapper";

type Props = {
  dbName: string;
  contId: string;
  catalogItems: ParameterlisteFrontend[] | GliederungssystemFrontend[];
  isFetching: boolean;
  catalogType: CatalogType;
};

export function CatalogItemsGrid({
  catalogItems,
  dbName,
  contId,
  isFetching,
  catalogType,
}: Props) {
  const {
    columnsDefinition,
    customCells,
    gridActions,
    isGridLoading,
    mutationDeleteCatalogItem,
  } = useCatalogItemsGrid(dbName, contId, isFetching, catalogType);
  const navigate = useNavigate();

  return (
    <DefaultGrid
      data={catalogItems}
      columnsDefinition={columnsDefinition}
      persistedDataStateId={{
        sector: `${catalogType}-grid`,
        unique: `${catalogType}-grid-${dbName}-${contId}`,
      }}
      dataItemKey="DATAID"
      gridActions={gridActions}
      customCells={customCells}
      isLoading={isGridLoading}
      mobileGrid={{
        rowHeight: 40,
        renderRow: (
          rowRef,
          item,
          filterValue,
          isSelected,
          setDataItemSelected
        ) => (
          <ABKCompactMobileCard
            rowRef={rowRef}
            item={item}
            filterValue={filterValue}
            isSelected={isSelected}
            setDataItemSelected={setDataItemSelected}
            onRowClick={(item: CatalogItem) => {
              navigate(`${item.DATAID}`);
            }}
            cardTitle={item.Kenndaten.BEZ}
            cardExtraTitle={item.Kenndaten.KENNUNG}
            cardSubtitle={`Version ${item.Kenndaten.VERSIONSNR}`}
          />
        ),
      }}
      deleteItemsMutation={mutationDeleteCatalogItem}
    />
  );
}
