import ABKFormDropdown from "src/abk-shared/components/atoms/ABKFormDropdown";
import { GliederungssystemSubtype } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import { Kenndaten } from "src/modules/Datei/interfaces/kenndaten.types";
import { useGetGliederungssysteme } from "src/modules/Datei/queries/useGetGliederungssysteme";

const DEFAULT_NO_SELECTION = "(keine Auswahl)";

interface ZuordnungSetting {
  fieldId: "FUNKTGSDATAID" | "PRODGSDATAID";
  type: GliederungssystemSubtype;
  label: string;
  tooltip: string;
}

interface DropDownItemGS {
  label: string;
  glId: string;
}

interface Props {
  db: string;
  contId: string;
  kenndaten: Kenndaten;
  setKenndaten: (kenndaten: Kenndaten) => Promise<void>;
  readOnly?: boolean;
  zuordnungSetting: ZuordnungSetting;
}

export function DropdownZuordnung({
  db,
  contId,
  kenndaten,
  setKenndaten,
  readOnly = false,
  zuordnungSetting,
}: Props) {
  const currentSelectedGlId = kenndaten[zuordnungSetting.fieldId] as string;
  const gliederungsSystemeQuery = useGetGliederungssysteme(db, contId);
  const gliederungsSysteme = gliederungsSystemeQuery.data?.value;
  const dropdownOptions: DropDownItemGS[] = [
    { label: DEFAULT_NO_SELECTION, glId: "" },
  ];
  let currentSelectedLabel = currentSelectedGlId;

  gliederungsSysteme?.Items.forEach((x) => {
    if (x.SUBTYPE === zuordnungSetting.type) {
      dropdownOptions.push({
        label: `${x.Kenndaten.BEZ} [${x.Kenndaten.KENNUNG}]`,
        glId: x.DATAID,
      });
      if (x.DATAID === currentSelectedGlId) {
        currentSelectedLabel = `${x.Kenndaten.BEZ} [${x.Kenndaten.KENNUNG}]`;
      }
    }
  });

  const currentSelected: DropDownItemGS = currentSelectedGlId
    ? { label: currentSelectedLabel, glId: currentSelectedGlId }
    : { label: DEFAULT_NO_SELECTION, glId: "" };

  return (
    <ABKFormDropdown
      label={zuordnungSetting.label}
      tooltip={zuordnungSetting.tooltip}
      data={dropdownOptions}
      value={currentSelected}
      dataItemKey="glId"
      textField="label"
      onChange={(event) =>
        setKenndaten({
          ...kenndaten,
          [zuordnungSetting.fieldId]: event.value.glId,
        })
      }
      editorId={zuordnungSetting.fieldId}
      disabled={readOnly}
    />
  );
}
