import { useOutlet } from "react-router-dom";
import { Gliederungssystem } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import { Parameterliste } from "src/modules/Datei/interfaces/parameterlisten.types";
import { CatalogItems } from "src/modules/Datei/pages/components/CatalogItemsWrapper/CatalogItems";
import { useIsValidDbAndContIdInURL } from "../../../../../core/utility/useIsValidDB";

export type CatalogType = "pl" | "gs";
export type CatalogItem = Parameterliste | Gliederungssystem;
export type CatalogItems = Parameterliste[] | Gliederungssystem[];

type Props = { catalogType: CatalogType };
export function CatalogItemsWrapper({ catalogType }: Props) {
  const outlet = useOutlet();
  const { isValid, db, contId } = useIsValidDbAndContIdInURL();

  if (!isValid) return <></>;

  return (
    outlet || (
      <CatalogItems
        key={`catalog-items-${db}-${contId}-${catalogType}`}
        dbName={db!}
        contId={contId!}
        catalogType={catalogType}
      />
    )
  );
}
