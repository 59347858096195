import { GridCustomCellProps } from "@progress/kendo-react-grid";
import React from "react";
import {
  BasisGridPropsContext,
  SelectionContext,
} from "src/abk-shared/components/organisms/ABKBasisGrid/components/BasisGridContextProvider/contexts";
import { Cell } from "../../../components/CustomCell/HighlightedCell";

const CheckboxCell = (props: GridCustomCellProps) => {
  const { setDataItemSelected, selectedState } =
    React.useContext(SelectionContext);
  const { dataItemKey } = React.useContext(BasisGridPropsContext);

  const onClick = (event: any) => {
    /*
      When the grid property `selectable` has `enabled: false`, and we
      have a custom `rowRender` function, we need to stop the propagation,
      otherwise it will trigger the `onSelectionChange` function twice.
    */
    event.stopPropagation();
    props.tdProps?.onClick?.(event);

    /*
      We trigger a selection change not only when the user clicks on the
      checkbox, but also when he clicks on the cell which encompasses the
      checkbox.
      This is useful for users which miss the click on the checkbox by a
      few pixels.
    */
    const dataItem = props.dataItem;
    const itemKey = dataItem[dataItemKey];
    setDataItemSelected(dataItem, !selectedState[itemKey]);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLTableCellElement>) => {
    if (event.type === "keypress" || event.key === "Enter") {
      event.preventDefault();
      onClick(event);
    }
  };

  return (
    <Cell
      {...props}
      tdProps={{
        ...props.tdProps,
        className: `${props.tdProps?.className} checkbox-cell`,
        onClick,
        onKeyDown,
      }}
    />
  );
};

export default CheckboxCell;
