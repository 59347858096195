import { useLiveQuery } from "dexie-react-hooks";
import { dbDrafts } from "src/core/db/dbDrafts";

export function useEintraegeDrafts(dbName: string | undefined) {
  const drafts = useLiveQuery(async () => {
    if (!dbName) {
      return [];
    } else {
      return dbDrafts.eintraegeDrafts
        .where("database")
        .equals(dbName)
        .toArray();
    }
  }, [dbName]);

  return drafts;
}
