import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  CatalogItems,
  CatalogType,
} from "src/modules/Datei/pages/components/CatalogItemsWrapper/CatalogItemsWrapper";

export const QUERY_KEY_CATALOG_ITEM = "QUERY_KEY_CATALOG_ITEM";

function useGetAllCatalogItems(
  db: string,
  contId: string,
  catalogType: CatalogType,
  ignoreError?: boolean
) {
  const getAllCatalogItems = async () => {
    const response = await axios.get(
      `/api/db/${db}/cont/${contId}/${catalogType}`
    );
    try {
      const catalogItems = response.data.Items;
      return catalogItems as CatalogItems;
    } catch (error) {
      if (ignoreError) {
        return [] as CatalogItems;
      }
      throw error;
    }
  };

  return useQuery({
    queryKey: [QUERY_KEY_CATALOG_ITEM, db, contId, catalogType],
    queryFn: () => getAllCatalogItems(),
    enabled: Boolean(db) && Boolean(contId),
  });
}

export default useGetAllCatalogItems;
