import React from "react";
import { Versioned } from "src/abk-shared/interfaces/Versioned";
import { EintragFrontend } from "src/modules/Datei/interfaces/parameterlisten.types";
import { ITEM_ID_NEW_EINTRAG } from "src/modules/Datei/pages/Parameterlisten/Eintraege/Dialoge/NewItemDialog";
import { useGetSingleEintrag } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/useGetSingleEintrag";
import { getEintragIndexOfDraft } from "src/modules/Datei/pages/Parameterlisten/Eintraege/useEintraege/eintraegeDrafts/eintraegeDraftHelperFunctions";
import { useEintraegeStore } from "src/modules/Datei/pages/Parameterlisten/Eintraege/useEintraegeStore";

export function useSetEigenschaftenfensterForEintrag(
  db: string,
  contId: string,
  plID: string,
  itemId: string,
  eintraege: EintragFrontend[] | undefined,
  setIsSettingEintragFirstMount: React.Dispatch<React.SetStateAction<boolean>>,
  setInitialSelectedItems: React.Dispatch<
    React.SetStateAction<EintragFrontend[]>
  >,
  setHighlightedItem: React.Dispatch<
    React.SetStateAction<EintragFrontend | undefined>
  >
) {
  const { setCurrentEintrag, setOpenDialog, setShowNewItemDialog } =
    useEintraegeStore();

  const { data: currentEintragInBackend } = useGetSingleEintrag(
    db,
    contId,
    plID,
    itemId
  );

  const setEintragInEigenschaftenfenster = (
    itemId: string,
    currentEintragInBackend: Versioned<EintragFrontend> | undefined
  ) => {
    if (itemId === ITEM_ID_NEW_EINTRAG) {
      setCurrentEintrag(null);
      setInitialSelectedItems([]);
      setHighlightedItem(undefined);
      setShowNewItemDialog(true);
      return;
    }

    const eintragIndexOfDraft = getEintragIndexOfDraft(itemId, eintraege);

    if (
      itemId?.length &&
      currentEintragInBackend?.value &&
      eintragIndexOfDraft === -1
    ) {
      // Set data from backend, no draft found
      setCurrentEintrag(currentEintragInBackend.value);
      setInitialSelectedItems([currentEintragInBackend.value]);
      setHighlightedItem(currentEintragInBackend.value);
      setOpenDialog(true);
      return;
    }

    if (!currentEintragInBackend?.value && eintragIndexOfDraft === -1) {
      // Not found, set null
      setCurrentEintrag(null);
      setInitialSelectedItems([]);
      setHighlightedItem(undefined);
      setOpenDialog(false);
      return;
    }

    // Set draft
    const eintragOfDraft =
      (eintraege as EintragFrontend[])[eintragIndexOfDraft] ?? null;
    setCurrentEintrag(eintragOfDraft);
    setInitialSelectedItems([eintragOfDraft]);
    setHighlightedItem(eintragOfDraft);
    setOpenDialog(true);
  };

  React.useEffect(() => {
    setEintragInEigenschaftenfenster(itemId, currentEintragInBackend);
    setIsSettingEintragFirstMount(false);
    // eslint-disable-next-line
  }, [itemId, currentEintragInBackend?.value?.ITEMID]);
}
