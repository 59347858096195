import {
  FILE_WEITEREGLIEDERUNGSSYSTEME_SUFFIX,
  FILE_WEITEREPARAMETERLISTEN_SUFFIX,
  INFO_SUFFIX,
} from "../../../../core/constants/urls";
import { FileInfo } from "../FileTypeMap";

const CPLFileInfo: FileInfo = {
  drawerItems: [
    INFO_SUFFIX,
    FILE_WEITEREPARAMETERLISTEN_SUFFIX,
    FILE_WEITEREGLIEDERUNGSSYSTEME_SUFFIX,
  ],
};

export default CPLFileInfo;
