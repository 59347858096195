import { Checkbox } from "@progress/kendo-react-inputs";
import { EintragCheckboxValue } from "../../../../interfaces/parameterlisten.types";
import { booleanFieldReverseMapper } from "../maps";

type Props = {
  tdProps: any;
  booleanValue: EintragCheckboxValue;
};
export default function GridCheckBox({ tdProps, booleanValue }: Props) {
  const isChecked = booleanFieldReverseMapper.get(booleanValue);
  return <td {...tdProps}>{isChecked && <Checkbox value={isChecked} />}</td>;
}
