import ExpansionPanelTitle from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/ExpansionPanelTitle";
import { generatePreviewTextForAuswahlliste } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/previewTextGenerator";
import ABKControlledExpansionPanel from "../../../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";
import ABKListView, {
  DisplayItem,
} from "../../../../../../../abk-shared/components/organisms/ABKListView";
import useIsMobileTemplate from "../../../../../../../abk-shared/utility/useIsMobileTemplate";
import { PropsEintragPanel } from "./ParTypePanelsMap";

const useAWList = (props: PropsEintragPanel) => {
  const { setPageState, pageState } = props;
  const isZahl = pageState?.ITEMTYPE === "Zahl";
  const zahlData = pageState?.AWList_Num;
  const textData = pageState?.AWList_Text;
  const key = isZahl ? "AWList_Num" : "AWList_Text";
  const data = isZahl ? zahlData : textData;
  const setData = (data: any[]) => {
    if (pageState) {
      setPageState({
        ...pageState,
        [key]: data,
      });
    }
  };
  const textDisplayData: DisplayItem[] = [
    {
      key: "Eintrag",
      name: "Wert",
      type: "text",
    },
    {
      key: "Bezeichnung",
      name: "Bezeichnung",
      type: "text",
    },
  ];
  const zahlDisplayData: DisplayItem[] = [
    {
      key: "Wert",
      name: "Wert",
      type: "number",
      numberOfFractionDigits: props.pageState.NUMNK.internal || 0,
    },
  ];
  const displayData = isZahl ? zahlDisplayData : textDisplayData;

  return {
    displayData,
    data,
    setData,
  };
};

const AuswahllistePanel = ({
  setPageState,
  pageState,
  sections,
  updateSections,
}: PropsEintragPanel) => {
  const isMobileTemplate = useIsMobileTemplate();

  const { data, setData, displayData } = useAWList({
    setPageState,
    pageState,
  });

  const previewText = generatePreviewTextForAuswahlliste(pageState!);

  const isExpanded = sections?.get("awlist") || false;

  const handlePanelToggle = () => {
    if (updateSections && sections) {
      const newSections = new Map(sections);
      newSections.set("awlist", !isExpanded);
      updateSections(newSections);
    }
  };

  return (
    <ABKControlledExpansionPanel
      title={
        <ExpansionPanelTitle
          title="Auswahlliste"
          previewText={previewText}
          shouldShowPreview={!isExpanded}
        />
      }
      expanded={isExpanded}
      onAction={handlePanelToggle}
    >
      <ABKListView
        tools={{
          addItem: true,
          dragDrop: true,
          deleteAll: false,
        }}
        dataToDisplay={displayData}
        setData={setData}
        isMobile={isMobileTemplate}
        data={data || []}
      />
    </ABKControlledExpansionPanel>
  );
};

export default AuswahllistePanel;
