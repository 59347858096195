import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GridRowSelection } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import { deleteDefaultGridItems } from "src/core/components/organisms/DefaultGrid/useDefaultGridDeleteFunction/deleteDefaultGridItems";
import { useEintraegeStore } from "src/modules/Datei/pages/Parameterlisten/Eintraege/useEintraegeStore";
import { EintragFrontend } from "../../../../../interfaces/parameterlisten.types";
import { QUERY_KEY_GET_EINTRAEGE } from "../../useGetEintraege";
import getQueryDataFromFirstEintrag from "./getQueryDataFromFirstEintrag";

export default function useDeleteEintrag(
  setIsGridLoading: React.Dispatch<React.SetStateAction<boolean>>,
  dataItemKey: "ITEMID"
) {
  const queryClient = useQueryClient();
  const { currentEintrag, setCurrentEintrag, setOpenDialog } =
    useEintraegeStore();

  return useMutation({
    mutationFn: async (gridRowSelection: GridRowSelection) => {
      setIsGridLoading(true);

      const selectedEintraege =
        gridRowSelection.selectedItems as EintragFrontend[];
      const { dbName, contId, plID } =
        getQueryDataFromFirstEintrag(selectedEintraege);

      const url = `/api/db/${dbName}/cont/${contId}/pl/${plID}/items`;
      const itemIdsToDelete = selectedEintraege.map(
        (eintrag) => eintrag.ITEMID
      );

      return deleteDefaultGridItems(url, itemIdsToDelete);
    },
    onSuccess: async (_response, gridRowSelection) => {
      const selectedEintraege =
        gridRowSelection.selectedItems as EintragFrontend[];
      const { dbName, contId, plID } =
        getQueryDataFromFirstEintrag(selectedEintraege);

      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_GET_EINTRAEGE, dbName, contId, plID],
      });

      const eintragDiplayedInDialogHasBeenDeleted = selectedEintraege.some(
        (selectedEintrag) =>
          selectedEintrag[dataItemKey] === currentEintrag?.[dataItemKey]
      );

      if (eintragDiplayedInDialogHasBeenDeleted) {
        setOpenDialog(false);
        setCurrentEintrag(null);
      }
    },
    onError: () => {
      setIsGridLoading(false);
    },
  });
}
