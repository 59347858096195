import { ReactNode } from "react";
import { PropsCustomCell } from "../../../../../../../abk-shared/components/organisms/ABKBasisGrid/components/CustomCell";
import { HighlightedCell } from "../../../../../../../abk-shared/components/organisms/ABKBasisGrid/components/CustomCell/HighlightedCell";
import { parTypeMapper } from "../../maps";

type Props = {
  text: string;
  props: PropsCustomCell;
  children?: ReactNode;
};

export default function CellGridText({ text, props, children }: Props) {
  const isBold = props.dataItem.ITEMTYPE === parTypeMapper.get("Set");
  const isGreen = props.dataItem.ITEMTYPE === parTypeMapper.get("Head");

  const cellContentStyle = {
    fontWeight: isBold || isGreen ? "bold" : "normal",
    color: isGreen ? "#999900" : "black",
  };

  return (
    <HighlightedCell {...props}>
      <span className="k-text-ellipsis" title={text} style={cellContentStyle}>
        {children ? children : text}
      </span>
    </HighlightedCell>
  );
}
