interface ExpansionPanelTitleProps {
  title: string;
  previewText: string;
  shouldShowPreview: boolean;
}
const ExpansionPanelTitle = ({
  title,
  previewText,
  shouldShowPreview,
}: ExpansionPanelTitleProps) => {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="k-expander-title" style={{ minWidth: "fit-content" }}>
          {title}
        </div>
        {shouldShowPreview && (
          <span className={`Textvorschau test-e2e-expansion-panel-preview`}>
            {previewText}
          </span>
        )}
      </div>
    </>
  );
};

export default ExpansionPanelTitle;
