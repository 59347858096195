import { GridHandle } from "@progress/kendo-react-grid";
import React from "react";
import { BasisGridProps } from "src/abk-shared/components/organisms/ABKBasisGrid/BasisGrid";
import { GridRowSelection } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";

export const BasisGridPropsContext = React.createContext<BasisGridProps>({
  data: [],
  columnsDefinition: { columns: [] },
  persistedDataStateId: { sector: "", unique: "" },
  dataItemKey: "",
  mobileGrid: undefined,
  gridActions: undefined,
  customCells: [],
  groupable: false,
  sortable: false,
  selection: undefined,
  reorderRowsConfig: undefined,
  hasToolbar: false,
  enableMobileGrid: false,
  rowIndexForInitialScrollTop: undefined,
  isMobileGrid: false,
  wrapperGridRef: () => undefined,
});

export const SelectionContext = React.createContext<GridRowSelection>({
  selectedState: {},
  setSelectedState: () => undefined,
  setDataItemSelected: () => undefined,
  selectedItems: [],
  onSelectionChange: () => undefined,
  onHeaderSelectionChange: () => undefined,
  checkHeaderSelectionValue: () => false,
  addSelectedFieldToDataResult: () => [] as any,
  updateSelectedStateAfterFilter: () => undefined,
});

export const DragHintContext =
  React.createContext<React.RefObject<HTMLDivElement> | null>(null);

export type GridRef = React.RefObject<GridHandle> | null;
export const GridContext = React.createContext<GridRef>(null);
