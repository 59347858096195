import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  CatalogItem,
  CatalogType,
} from "src/modules/Datei/pages/components/CatalogItemsWrapper/CatalogItemsWrapper";
import { createCatalogItemURL } from "src/modules/Datei/pages/components/CatalogItemsWrapper/createCatalogItemURL";

export default function useAddCatalogItemAbove(catalogType: CatalogType) {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (catalogItem: CatalogItem) =>
      axios.post<CatalogItem>(
        `/api/db/${catalogItem.DBNAME}/cont/${catalogItem.CONTID}/${catalogType}`,
        undefined,
        {
          // "Content-Type" explizit hineingegeben, ohne den verursacht es einen Fehler
          headers: { "Content-Type": "application/json" },
          params: { insertbefore: catalogItem.DATAID },
        }
      ),
    onSuccess: (response) => {
      const newCatalogItem = response.data;
      const catalogItemURL = createCatalogItemURL(
        catalogType,
        newCatalogItem.DATAID,
        newCatalogItem.DBNAME,
        newCatalogItem.CONTID
      );
      navigate(catalogItemURL);
    },
  });
}
