import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { GenericObject } from "../../../../../interfaces/GenericObject";
import CardContainer from "../../../../molecules/ABKCardContainer";
import { FunctionSetDataItemSelected } from "../../hooks/useGridRowSelection/gridRowSelection.types";

import { WrapperFixedSizeList } from "src/abk-shared/components/organisms/ABKBasisGrid/components/VirtualizedMobileGridRows/WrapperFixedSizeList";

export type MobileGridProps = {
  rowHeight: number;
  renderRow: FunctionMobileGridRow;
};

export type FunctionMobileGridRow = (
  rowRef: React.RefObject<HTMLDivElement>,
  item: GenericObject,
  filterValue: string,
  isSelected: boolean,
  setDataItemSelected: FunctionSetDataItemSelected
) => React.ReactElement;

type Props = {
  dataItems: GenericObject[];
  mobileGridProps: MobileGridProps;
  filterValue: string;
  setDataItemSelected: FunctionSetDataItemSelected;
  gridUniqueId: string;
  rowIndexForInitialScrollTop?: number;
};

export default function VirtualizedMobileGridRows({
  dataItems,
  mobileGridProps,
  filterValue,
  setDataItemSelected,
  gridUniqueId,
  rowIndexForInitialScrollTop,
}: Props) {
  return (
    <CardContainer hasGridLayout={false}>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => {
          return (
            <WrapperFixedSizeList
              dataItems={dataItems}
              filterValue={filterValue}
              setDataItemSelected={setDataItemSelected}
              height={height}
              width={width}
              mobileGridProps={mobileGridProps}
              gridUniqueId={gridUniqueId}
              rowIndexForInitialScrollTop={rowIndexForInitialScrollTop}
            />
          );
        }}
      </AutoSizer>
    </CardContainer>
  );
}
