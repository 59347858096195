import { TreeList } from "@progress/kendo-react-treelist";
import { useMemo } from "react";
import { HighlightedCell } from "src/abk-shared/components/organisms/ABKBasisGrid/components/CustomCell/HighlightedCell";
import useIsMobileTemplate from "src/abk-shared/utility/useIsMobileTemplate";
import {
  DATA_ITEM_KEY,
  EXPAND_FIELD,
  SELECTED_FIELD,
  SUB_ITEMS_FIELD,
} from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/constants";
import { ZuordnungSelectionFunctions } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/hooks/useZuordnungSelection";
import { ZuordnungTreeData } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/hooks/useZuordnungTreeData/useZuordnungTreeData";
import { ZuordnungData } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/queries/useFetchZuordnungData";
import zuordnungColumns from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/zuordnungColumns";
import { CustomTreeListHeaderSelectionCell } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/ZuordnungSelection/CustomTreeListHeaderSelectionCell";
import { CustomTreeListSelectionCell } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/ZuordnungSelection/CustomTreeListSelectionCell";
import ZuordnungSelectionToolbar from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/ZuordnungSelection/ZuordnungSelectionToolbar";
import "./styles.scss";
type Props = {
  zuordnungData: ZuordnungData;
  zuordnungTreeData: ZuordnungTreeData;
  zuordnungSelection: ZuordnungSelectionFunctions;
  filterValue: string;
  setFilterValue: React.Dispatch<React.SetStateAction<string>>;
};

export default function ZuordnungSelection({
  zuordnungData,
  zuordnungTreeData,
  zuordnungSelection,
  filterValue,
  setFilterValue,
}: Props) {
  const isMobileTemplate = useIsMobileTemplate();
  const isDataTreePartlySelected = useMemo(() => {
    const numberOfSelected = zuordnungSelection.selectedItems?.length || 0;
    const totalLength = zuordnungData.data?.length || 0;
    return numberOfSelected < totalLength && numberOfSelected > 0;
  }, [zuordnungData.data, zuordnungSelection.selectedItems]);

  return (
    <div className="zuordnung-selection-container">
      <ZuordnungSelectionToolbar
        isMobile={isMobileTemplate}
        zuordnungTreeData={zuordnungTreeData}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
      />
      <TreeList
        className={`abk9-tree-list test-e2e-zuordnung-tree-list`}
        data={zuordnungTreeData.extendedData}
        selectedField={SELECTED_FIELD}
        expandField={EXPAND_FIELD}
        subItemsField={SUB_ITEMS_FIELD}
        dataItemKey={DATA_ITEM_KEY}
        onSelectionChange={zuordnungSelection.onSelectionChange}
        onHeaderSelectionChange={zuordnungSelection.onHeaderSelectionChange}
        onExpandChange={zuordnungTreeData.onExpandChange}
        columns={[
          {
            field: SELECTED_FIELD,
            width: "10%",
            headerSelectionValue: zuordnungSelection.headerSelectionValue(
              zuordnungData.dataTree,
              zuordnungSelection.selectedState
            ),
            headerCell: (props) => {
              return (
                <CustomTreeListHeaderSelectionCell
                  {...props}
                  isIndeterminate={isDataTreePartlySelected}
                />
              );
            },
            cell: (props) => {
              return <CustomTreeListSelectionCell {...props} />;
            },
          },
          {
            ...zuordnungColumns.nummer,
            expandable: true,
            width: "45%",
          },
          {
            ...zuordnungColumns.bezeichnung,
            width: "45%",
            cell: (props) => {
              const { dataItem } = props;
              const field = props.field || "";
              const cellData = dataItem[field];
              return (
                <HighlightedCell
                  dataIndex={0}
                  columns={[]}
                  /*
                    `props.level` from the TreeList is a number array.
                    That's why we use `as any`. The `props` will be passed
                    down the `td` component and there will be no problem.
                  */
                  {...(props as any)}
                  filterValue={filterValue}
                >
                  {cellData}
                </HighlightedCell>
              );
            },
          },
        ]}
      />
    </div>
  );
}
