import { SvgIcon } from "@progress/kendo-react-common";
import { SVGIcon } from "@progress/kendo-svg-icons";
import { NavigationItem } from "src/abk-shared/interfaces/NavigationItem";

export default function createNavigationItem(
  id: string,
  text: string,
  title: string,
  svgIcon: SVGIcon,
  url: string,
  shouldOverrideBreadcrumb: boolean,
  description?: string
): NavigationItem {
  const breadcrumbProperties = {
    icon: <SvgIcon icon={svgIcon} style={{ marginRight: 5 }} />,
    path: url,
    shouldOverrideBreadcrumb,
  };

  const drawerProperties = {
    svgIcon: svgIcon,
    route: url,
  };

  return {
    id,
    text,
    title,
    description,
    ...breadcrumbProperties,
    ...drawerProperties,
  };
}
