import { State } from "@progress/kendo-data-query";
import { PopupPropsContext } from "@progress/kendo-react-popup";
import React from "react";
import { BasisGridProps } from "src/abk-shared/components/organisms/ABKBasisGrid/BasisGrid";
import {
  BasisGridPropsContext,
  DragHintContext,
  GridContext,
  GridRef,
  SelectionContext,
} from "src/abk-shared/components/organisms/ABKBasisGrid/components/BasisGridContextProvider/contexts";
import { ReorderContext } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridReorderRows/gridReorderRowsContexts";
import { GenericObject } from "../../../../../interfaces/GenericObject";
import DataStateContext from "../../hooks/useGridDataState/DataStateContext";
import { GridReorderRows } from "../../hooks/useGridReorderRows/gridReorderRowsInterfaces";
import { GridRowSelection } from "../../hooks/useGridRowSelection/gridRowSelection.types";

type Props = {
  children: React.ReactNode;
  gridRef: GridRef;
  gridReorderRows: GridReorderRows;
  gridRowSelection: GridRowSelection;
  dataState: State;
  dataResultWithSelectedField: GenericObject[];
  inputProps: BasisGridProps;
};

const BasisGridContextProvider = ({
  gridRef,
  children,
  gridReorderRows,
  gridRowSelection,
  dataState,
  dataResultWithSelectedField,
  inputProps,
}: Props) => (
  <BasisGridPropsContext.Provider value={inputProps}>
    <GridContext.Provider value={gridRef}>
      <DragHintContext.Provider value={gridReorderRows.hintRef}>
        <ReorderContext.Provider
          value={{
            reorder: gridReorderRows.reorder,
            dragStart: gridReorderRows.dragStart,
            dragEnd: gridReorderRows.dragEnd,
            itemIsBeingDragged: gridReorderRows.itemIsBeingDragged,
          }}
        >
          <SelectionContext.Provider value={gridRowSelection}>
            <DataStateContext.Provider
              value={{
                dataState,
                dataResultWithSelectedField,
              }}
            >
              <PopupPropsContext.Provider
                /*
                  Ändere die `collision` props für all die Popups im Grid. Das behebt den
                  folgenden Fehler:
                  - Das Browser-Fenster klein machen
                  - Das Column-Menü einer Spalte klicken
                  - Das Column-Menü öffnet sich. Wenn das Column-Menü höher als das Fenster
                  ist, wird eine Vertical Scrollbar auftauchen
                  - Wenn die Scrollbar auftaucht, schließt sich sofort das Column-Menü
                  -> Der User kann nicht das Column-Menü mit einem kleinen Bildschirm benutzen!
                  Daher haben wir die `collision` props geändert, um keine Scrollbar im Body
                  zu erstellen, wenn wir ein Column-Menü öffnen.
                */
                value={(props) => ({
                  ...props,
                  collision: {
                    horizontal: "fit",
                    vertical: "fit",
                  },
                })}
              >
                {children}
              </PopupPropsContext.Provider>
            </DataStateContext.Provider>
          </SelectionContext.Provider>
        </ReorderContext.Provider>
      </DragHintContext.Provider>
    </GridContext.Provider>
  </BasisGridPropsContext.Provider>
);

export default BasisGridContextProvider;
