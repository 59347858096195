import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { useEffect, useState } from "react";
import ABKControlledExpansionPanel from "src/abk-shared/components/atoms/ABKControlledExpansionPanel";
import ExpansionPanelTitle from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/ExpansionPanelTitle";
import { IField } from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/IField";
import generatePreviewTextForListOfIFields from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/previewTextGenerator";
import ABKFormCheckbox from "src/abk-shared/components/atoms/ABKFormCheckbox";
import ABKFormDropdown from "src/abk-shared/components/atoms/ABKFormDropdown";
import ABKFormRow from "src/abk-shared/components/atoms/ABKFormRow";
import ABKForm from "src/abk-shared/components/molecules/ABKForm";
import {
  EintragFrontend,
  ShowIfDisplayed,
} from "src/modules/Datei/interfaces/parameterlisten.types";
import { PropsEintragPanel } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ParTypePanelsMap";
import {
  booleanFieldMapper,
  booleanFieldReverseMapper,
} from "src/modules/Datei/pages/Parameterlisten/Eintraege/maps";

const valuesEintragShowIf: ShowIfDisplayed[] = [
  ShowIfDisplayed.ueberallAnzeigen,
  ShowIfDisplayed.hoehererEbene,
  ShowIfDisplayed.niedrigerEbene,
  ShowIfDisplayed.immer,
];

const labels = {
  REQUIRED: "Eingabe erforderlich",
  FAVORIT: "Favorit",
  SHOWIF: "Ausblenden",
};

export const ErweitertPanel = ({
  setPageState,
  pageState,
  sections,
  updateSections,
}: PropsEintragPanel) => {
  const [fields, setFields] = useState<IField[]>([]);

  useEffect(() => {
    setFields([
      {
        label: labels.REQUIRED,
        value: pageState?.REQUIRED || false,
        isCheckbox: true,
      },
      {
        label: labels.FAVORIT,
        value: pageState?.FAVORIT || false,
        isCheckbox: true,
      },
      {
        label: labels.SHOWIF,
        value: pageState?.SHOWIF || "",
        isCheckbox: false,
      },
    ]);
  }, [pageState]);

  const previewText = generatePreviewTextForListOfIFields(fields);

  const isExpanded = sections?.get("erweitert") || false;

  const handlePanelToggle = () => {
    if (updateSections && sections) {
      const newSections = new Map(sections);
      newSections.set("erweitert", !isExpanded);
      updateSections(newSections);
    }
  };

  return (
    <ABKControlledExpansionPanel
      title={
        <ExpansionPanelTitle
          title="Erweitert"
          previewText={previewText}
          shouldShowPreview={!isExpanded}
        />
      }
      expanded={isExpanded}
      onAction={handlePanelToggle}
    >
      <ABKForm
        sections={[
          {
            formElements: [
              <ABKFormRow>
                <ABKFormCheckbox
                  editorId="REQUIRED"
                  label="Eingabe erforderlich"
                  value={booleanFieldReverseMapper.get(pageState?.REQUIRED)}
                  setValue={(value) => {
                    setPageState({
                      ...pageState,
                      REQUIRED: booleanFieldMapper.get(value),
                    } as EintragFrontend);
                  }}
                />
                <ABKFormCheckbox
                  editorId="FAVORIT"
                  label="Favorit"
                  value={booleanFieldReverseMapper.get(pageState?.FAVORIT)}
                  setValue={(value) => {
                    setPageState({
                      ...pageState,
                      FAVORIT: booleanFieldMapper.get(value),
                    } as EintragFrontend);
                  }}
                />
              </ABKFormRow>,
              <ABKFormRow isHalfRow={true}>
                <ABKFormDropdown
                  label="Ausblenden"
                  value={pageState?.SHOWIF}
                  data={valuesEintragShowIf}
                  editorId="Ausblenden"
                  onChange={(event: DropDownListChangeEvent) =>
                    setPageState({
                      ...pageState,
                      SHOWIF: event.value,
                    } as EintragFrontend)
                  }
                />
              </ABKFormRow>,
            ],
          },
        ]}
      />
    </ABKControlledExpansionPanel>
  );
};
