import React from "react";
import { GridSelectionProps } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import { QuickinfoContent } from "src/abk-shared/components/organisms/ABKSideBar/QuickInfo";
import { GenericObject } from "src/abk-shared/interfaces/GenericObject";
import { findNewItem } from "src/core/components/organisms/DefaultGrid/useDefaultGridSelection/findNewItem";
import { useQuickInfoOnGridSelection } from "src/core/components/organisms/DefaultGrid/useDefaultGridSelection/useQuickInfoOnGridSelection";

export type FunctionGenerateQuickInfoForItem = (
  item: any
) => Promise<QuickinfoContent>;

export function useDefaultGridSelection(
  generateQuickInfoForOneItem: FunctionGenerateQuickInfoForItem | undefined,
  selection: GridSelectionProps | undefined,
  dataItemKey: string
) {
  const [selectedItems, setSelectedItems] = React.useState<GenericObject[]>([]);
  const [highlightedItem, setHighlightedItem] = React.useState<
    GenericObject | undefined
  >(undefined);

  const { changeQuickInfo } = useQuickInfoOnGridSelection(
    generateQuickInfoForOneItem
  );
  const onSelectionChange = (newItemsSelected: GenericObject[]) => {
    const newlySelected = findNewItem({
      itemsSelected: selectedItems,
      newItemsSelected,
      dataItemKey,
    });

    setHighlightedItem(newlySelected);
    setSelectedItems([...newItemsSelected]);
    selection?.onSelectionChange?.(newItemsSelected);
    return changeQuickInfo(newItemsSelected, newlySelected);
  };

  const defaultGridSelection = {
    ...selection,
    onSelectionChange,
    highlightedItem,
    initialSelectedItems: selectedItems,
  };

  return { selection: defaultGridSelection, selectedItems };
}
