import { extendDataItem, mapTree } from "@progress/kendo-react-treelist";
import { SelectedState } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import { GliederungssystemEintrag } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import { SUB_ITEMS_FIELD } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/constants";
import idGetter from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/hooks/idGetter";

export default function extendData(
  dataTree: GliederungssystemEintrag[],
  selectedState: SelectedState,
  expandedState: { [n: number]: boolean }
) {
  return mapTree(dataTree, SUB_ITEMS_FIELD, (item) =>
    extendDataItem(item, SUB_ITEMS_FIELD, {
      selected: selectedState[idGetter(item)],
      expanded: expandedState[idGetter(item)],
    })
  );
}
