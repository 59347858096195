import { Button } from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import React from "react";
import ABKInfoPopup from "src/abk-shared/components/atoms/ABKInfoPopup";
import ABKLabel from "src/abk-shared/components/atoms/ABKLabel";
import { ABKUploadButtonWrapper } from "src/abk-shared/components/atoms/ABKUploadButtonWrapper";
import {
  Dummy_Missing,
  Edit_Delete,
  General_Export,
} from "src/abk-shared/interfaces/abk9SvgIcons";
import LogoDisplayer from "src/core/components/atoms/LogoDisplayer";
import downloadFile from "src/core/utility/downloadFile";
import {
  generateBase64Image,
  getLogoFormat,
  imageToBase64,
  LogoFormat,
} from "src/core/utility/imageProcessing";
import { AllgemeinFormGeneralProps } from "src/modules/Datei/pages/components/KenndatenPanels/Allgemein/AllgemeinForm";

const messageAvailableFormats = "Erlaubte Dateiformate: JPG, PNG, GIF";

export default function UploadLogo({
  kenndaten,
  setKenndaten,
  disabled,
}: Omit<AllgemeinFormGeneralProps, "readOnly"> & { disabled: boolean }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleFileUpload = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setErrorMessage("");
      setIsLoading(true);

      const inputFile = event.target.files?.[0];
      const userCanceledFileSelection = !inputFile;
      if (userCanceledFileSelection) {
        setIsLoading(false);
        return;
      }

      // Setze das Logo zurück, falls es ein Logo vorher eingegeben war
      void setKenndaten({
        ...kenndaten,
        LOGO: "",
        LOGOFORMAT: LogoFormat.EMPTY,
      });

      const type = inputFile.type;
      const logoFormat = getLogoFormat(type);
      if (logoFormat === LogoFormat.EMPTY || logoFormat === LogoFormat.SVG) {
        setErrorMessage(
          `Dateiformat nicht erlaubt. ${messageAvailableFormats}`
        );
        setIsLoading(false);
        return;
      }

      const fileBase64WithoutPrefix = await imageToBase64(inputFile);

      setIsLoading(false);
      return setKenndaten({
        ...kenndaten,
        LOGO: fileBase64WithoutPrefix,
        LOGOFORMAT: logoFormat,
      });
    },
    [kenndaten, setKenndaten]
  );

  const tooltip = `${messageAvailableFormats}; Maximale Dateigröße: 64kB. Dateien über 64kB werden automatisch verkleinert.`;

  const logoFormat = kenndaten.LOGOFORMAT;
  const { hasImage, imageBase64 } = generateBase64Image(
    kenndaten?.LOGO,
    logoFormat
  );

  const downloadImage = React.useCallback(() => {
    const fileName = `Logo.${logoFormat.toLowerCase()}`;
    downloadFile(imageBase64, fileName);
  }, [imageBase64, logoFormat]);

  return (
    <div>
      <ABKLabel editorId="allgemein-status">
        Logo
        <ABKInfoPopup popupContent={tooltip} />
      </ABKLabel>
      <div className="upload-logo-piktogramm-img-button-container">
        <div className="upload-logo-piktogramm-img-container">
          {hasImage && (
            <LogoDisplayer
              isLoading={isLoading}
              hasImage={hasImage}
              imageBase64={imageBase64}
              errorMessage={errorMessage}
            />
          )}
          {!hasImage && (
            <div
              style={{
                height: "110px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SvgIcon icon={Dummy_Missing} width={40} height={40} />
            </div>
          )}
        </div>

        <div className="upload-logo-piktogramm-button-container">
          <ABKUploadButtonWrapper
            buttonId="upload-logo-piktogramm"
            onChange={handleFileUpload}
            className="k-d-inline-block"
            disabled={false}
            multiple={false}
            accept=".jpg,.png"
            svgIconKey="General.Import"
            title="Hochladen"
          />
          <Button
            onClick={() =>
              setKenndaten({
                ...kenndaten,
                LOGO: "",
                LOGOFORMAT: LogoFormat.EMPTY,
              })
            }
            svgIcon={Edit_Delete}
            title="Löschen"
            disabled={disabled || !hasImage}
          />
          <Button
            onClick={downloadImage}
            svgIcon={General_Export}
            title="Herunterladen"
            disabled={disabled || !hasImage}
          />
        </div>
      </div>
    </div>
  );
}
