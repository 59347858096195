import "./style.scss";

export const ABKDraftHint = ({
  onClick,
}: {
  onClick?: (event: any) => void;
}) => (
  <span
    onClick={onClick}
    className="abk-draft-hint"
    style={{ cursor: onClick ? "pointer" : "default" }}
  >
    [Entwurf]
  </span>
);
