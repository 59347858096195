import { ListItemProps } from "@progress/kendo-react-dropdowns";
import React from "react";
import { Link } from "react-router-dom";
import { NavigationItem } from "../../../../../../abk-shared/interfaces/NavigationItem";

// Von diesem Beispiel inspiriert: https://www.telerik.com/kendo-react-ui/components/dropdowns/dropdownlist/custom-rendering/#toc-items
export const itemRender = (
  li: React.ReactElement<HTMLLIElement>,
  itemProps: ListItemProps
) => {
  const itemChildren = (
    <Link
      to={itemProps.dataItem.route}
      className="breadcrumb-link-dropdown-list test-e2e-breadcrumb-dropdown-item"
    >
      {itemProps.dataItem.icon} {li.props.children}
    </Link>
  );
  return React.cloneElement(li, li.props, itemChildren);
};

// Von diesem Beispiel inspiriert: https://www.telerik.com/kendo-react-ui/components/dropdowns/dropdownlist/custom-rendering/#toc-values
export const valueRender = (
  element: React.ReactElement<HTMLSpanElement>,
  value: NavigationItem
) => {
  if (!value) return element;

  const children = [
    <React.Fragment key={1}>{value.icon}</React.Fragment>,
    <span key={2}>{element.props.children as React.ReactNode}</span>,
  ];

  return React.cloneElement(
    element,
    {
      ...element.props,
    },
    children
  );
};
