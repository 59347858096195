import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Versioned } from "../../../abk-shared/interfaces/Versioned";
import { Parameterliste } from "../interfaces/parameterlisten.types";
import { formatWithEtag } from "./formatWithEtag";

export const QUERY_KEY_PARAMETERLISTE = "QUERY_KEY_PARAMETERLISTE";

const useGetSingleParameterliste = (
  db: string,
  contId: string,
  plID: string,
  ignoreError?: boolean
) => {
  const getSingleParameterliste = async () => {
    const url = `/api/db/${db}/cont/${contId}/pl/${plID}`;

    try {
      const response = await axios.get<Parameterliste>(url);
      return formatWithEtag(response);
    } catch (error) {
      if (ignoreError) {
        return null as any;
      }
      throw error;
    }
  };

  return useQuery<Versioned<Parameterliste>>({
    queryKey: [QUERY_KEY_PARAMETERLISTE, db, contId, plID],
    queryFn: () => getSingleParameterliste(),
    enabled: Boolean(db) && Boolean(contId) && Boolean(plID),
  });
};

export default useGetSingleParameterliste;
