import ABKToolbarButton from "src/abk-shared/components/atoms/ABKToolbarButton";
import { ABKUploadButtonWrapper } from "src/abk-shared/components/atoms/ABKUploadButtonWrapper";
import ABKSearchBar from "src/abk-shared/components/molecules/ABKSearchBar";
import ABKToolbar from "src/abk-shared/components/molecules/ABKToolbar";
import { Edit_Delete } from "src/abk-shared/interfaces/abk9SvgIcons";
import {
  getLogoFormat,
  imageToBase64,
  LogoFormat,
} from "src/core/utility/imageProcessing";
import { removeFileType } from "src/core/utility/removeFileType";
import {
  defaultPiktogramm,
  PiktogrammItem,
} from "src/modules/Datei/pages/Parameterlisten/Piktogramme/piktogramm.types";
import "./styles.scss";

interface Props {
  filterValue: string;
  isMobileGrid: boolean;
  showDeleteButton: boolean;
  onFilterChange: (newValue: string) => void;
  onInsertButtonClick: (file: PiktogrammItem) => void;
  onDeleteButtonClick: () => void;
}

export const PiktogrammToolbar = ({
  filterValue,
  isMobileGrid,
  showDeleteButton,
  onFilterChange,
  onInsertButtonClick,
  onDeleteButtonClick,
}: Props) => {
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newPiktogramm = { ...defaultPiktogramm };
    const inputFile = event.target.files?.[0];
    const userCanceledFileSelection = !inputFile;
    if (userCanceledFileSelection) {
      return;
    }

    const nameWithoutType = removeFileType(inputFile.name);
    newPiktogramm.BEZ = nameWithoutType;
    const type = inputFile.type;
    const logoFormat = getLogoFormat(type);
    if (logoFormat !== LogoFormat.SVG) {
      return;
    }

    const fileBase64WithoutPrefix = await imageToBase64(inputFile);
    newPiktogramm.CONTENT = fileBase64WithoutPrefix;
    onInsertButtonClick(newPiktogramm);
  };

  return (
    <>
      <ABKToolbar
        dataTestId="piktogramm-toolbar"
        leftTools={
          <>
            <ABKUploadButtonWrapper
              buttonId="toolbarUploader"
              onChange={handleFileUpload}
              className="k-d-inline-block"
              disabled={false}
              multiple={true}
              accept=".svg"
              svgIconKey="Grid.Insert"
              title="Hinzufügen"
            />
            {showDeleteButton && (
              <ABKToolbarButton
                isMobile={isMobileGrid}
                svgIcon={Edit_Delete}
                title="Löschen"
                onClick={onDeleteButtonClick}
              />
            )}
          </>
        }
        rightTools={
          <ABKSearchBar
            isMobileGrid={isMobileGrid}
            filterValue={filterValue}
            onFilterChange={onFilterChange}
          />
        }
      />
    </>
  );
};
