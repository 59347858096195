import { SvgIcon } from "@progress/kendo-react-common";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import createFunctionToMigrateColumns from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridColumns/persistedColumnsStore/createFunctionToMigrateColumns";
import ABKBasisGridCellLink from "../../../../abk-shared/components/organisms/ABKBasisGrid/components/ABKBasisGridCellLink";
import { PropsCustomCell } from "../../../../abk-shared/components/organisms/ABKBasisGrid/components/CustomCell";
import { DATA_KEY } from "../../../../abk-shared/components/organisms/ABKBasisGrid/constants";
import { GridRowSelection } from "../../../../abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import {
  GridColumnPropsCustom,
  GridColumnsMigrateFunction,
} from "../../../../abk-shared/components/organisms/ABKBasisGrid/interfaces/GridColumns";
import { AbkIconsMap } from "../../../../abk-shared/interfaces/abk9SvgIcons";
import { ContWithLastChangedModified } from "../../../../core/interfaces/Cont";
import {
  getFileInfoUrlForContainer,
  useContainersWithFormattedDate,
} from "../../../../core/utility/cont";
import useDuplicateCont from "../../hooks/useDuplicateCont";
import useGetCont, { RequestType } from "../../hooks/useGetCont";
import useSaveContAsTemplate from "../../hooks/useSaveContAsTemplate";

import { PRIMARY_COLUMN_PROPS } from "src/core/components/organisms/DefaultGrid/constants";
import {
  CreateDefaultGridActionsFunction,
  DeleteFunctionDefaultGrid,
} from "src/core/components/organisms/DefaultGrid/useDefaultGridDeleteFunction/useDefaultGridDeleteFunction.types";
import useIsGridLoading from "src/core/utility/useIsGridLoading";
import { ContainerToExport, ExportDialogResponse } from "./ExportDialog";
import usePostExport from "./ExportDialog/usePostExport";

const COLUMN_FIELDS = {
  DATEINAME: "BEZ",
  TYP: "ContTypeInfo.TXT",
  BESITZER: "OWNER",
  ÄNDERUNGSDATUM: "LASTCHANGED",
  ANMERKUNG: "INTANMERK",
  CONTID: "CONTID",
};

export default function useVerwalten(
  database: string,
  requestType: RequestType,
  setExportDialogPromise: React.Dispatch<
    React.SetStateAction<{
      resolve: (value: ExportDialogResponse) => void;
    } | null>
  >
) {
  const { data, isLoading, isFetching } = useGetCont(database, requestType);
  const containersWithFormattedDate = useContainersWithFormattedDate(data);

  // Inspired from: https://www.telerik.com/kendo-react-ui/components/grid/columns/width/#toc-setting-a-minimum-column-width
  const columnsDefinition = React.useMemo(() => {
    const columns: GridColumnPropsCustom[] = [
      {
        migrationId: COLUMN_FIELDS.DATEINAME,
        field: COLUMN_FIELDS.DATEINAME,
        title: "Dateiname",
        minWidth: 100,
        orderIndex: 1,
        ...PRIMARY_COLUMN_PROPS,
      },
      {
        migrationId: "Datei",
        field: "Datei",
        title: "Datei",
        orderIndex: 2,
        children: [
          {
            migrationId: COLUMN_FIELDS.TYP,
            field: COLUMN_FIELDS.TYP,
            title: "Typ",
            minWidth: 50,
            manualWidth: 50,
            removeFromFiltering: true,
            orderIndex: 3,
          },
          {
            migrationId: COLUMN_FIELDS.BESITZER,
            field: COLUMN_FIELDS.BESITZER,
            title: "Besitzer",
            minWidth: 100,
            orderIndex: 4,
          },
          {
            migrationId: COLUMN_FIELDS.ÄNDERUNGSDATUM,
            field: COLUMN_FIELDS.ÄNDERUNGSDATUM,
            title: "Änderungsdatum",
            minWidth: 100,
            orderIndex: 5,
            hasInternalValue: true,
          },
          {
            migrationId: COLUMN_FIELDS.CONTID,
            field: COLUMN_FIELDS.CONTID,
            title: "Container-ID",
            minWidth: 100,
            orderIndex: 6,
          },
        ],
      },
      {
        migrationId: "Notizen",
        field: "Notizen",
        title: "Notizen",
        orderIndex: 7,
        children: [
          {
            migrationId: COLUMN_FIELDS.ANMERKUNG,
            field: COLUMN_FIELDS.ANMERKUNG,
            title: "Interne Anmerkung",
            minWidth: 100,
            orderIndex: 8,
          },
        ],
      },
    ];

    const version = 8;

    const migrate: GridColumnsMigrateFunction = createFunctionToMigrateColumns(
      version,
      columns,
      { maxVersion: 7, props: ["orderIndex"] }
    );

    return { columns, version, migrate };
  }, []);

  const customCells = React.useMemo(
    () => [
      {
        columnField: COLUMN_FIELDS.DATEINAME,
        render: (props: PropsCustomCell) => (
          <ABKBasisGridCellLink
            {...props}
            text={props.dataItem.BEZ}
            to={getFileInfoUrlForContainer(
              props.dataItem.DBNAME,
              props.dataItem.CONTID
            )}
          />
        ),
      },
      {
        columnField: COLUMN_FIELDS.TYP,
        render: (props: PropsCustomCell) => (
          <td {...props.tdProps} title={props.dataItem.ContTypeInfo.TXT}>
            <SvgIcon
              width={24}
              icon={AbkIconsMap.get(props.dataItem.ContTypeInfo.ICONID)}
            />
          </td>
        ),
      },
    ],
    []
  );

  const generateQuickInfoForOneItem = async (
    item: ContWithLastChangedModified
  ) => {
    return [
      {
        title: "Dateiname",
        content: (
          <Link to={getFileInfoUrlForContainer(item.DBNAME, item.CONTID)}>
            {item.BEZ || ""}
          </Link>
        ),
      },
      {
        title: "Besitzer",
        content: item.OWNER || "",
      },
      {
        title: "Änderungsdatum",
        content: item.LASTCHANGED[DATA_KEY.DISPLAYED] || "",
      },
      {
        title: "Interne Anmerkung",
        content: item.INTANMERK || "Keine interne Anmerkung vorhanden",
      },
    ];
  };

  const [selectedContainers, setSelectedContainers] = React.useState<
    ContWithLastChangedModified[]
  >([]);

  const [isExportDialogOpen, setIsExportDialogOpen] = React.useState(false);
  const toggleExportDialog = React.useCallback(
    () => setIsExportDialogOpen(!isExportDialogOpen),
    [isExportDialogOpen]
  );

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const toggleDeleteDialog = React.useCallback(
    () => setIsDeleteDialogOpen(!isDeleteDialogOpen),
    [isDeleteDialogOpen]
  );

  const { isGridLoading, setIsGridLoading } = useIsGridLoading(isFetching);

  const navigate = useNavigate();
  const mutationDuplicate = useDuplicateCont(database);
  const mutationSaveAsTemplate = useSaveContAsTemplate(database);
  const mutationExport = usePostExport();

  const containerActionHandlers = React.useMemo(
    () => ({
      open: (_event: any, gridRowSelection: GridRowSelection) => {
        const selectedContainers =
          gridRowSelection.selectedItems as ContWithLastChangedModified[];
        const containerSelected = selectedContainers[0];
        const containerId = containerSelected.CONTID;
        if (database === undefined) return;

        navigate(getFileInfoUrlForContainer(database, containerId));
      },
      duplicate: async (_event: any, gridRowSelection: GridRowSelection) => {
        setIsGridLoading(true);

        const selectedContainers =
          gridRowSelection.selectedItems as ContWithLastChangedModified[];
        const containerSelected = selectedContainers[0];
        const containerId = containerSelected.CONTID;
        try {
          await mutationDuplicate.mutateAsync(containerId);
        } catch {}

        setIsGridLoading(false);
      },
      saveAsTemplate: async (
        _event: any,
        gridRowSelection: GridRowSelection
      ) => {
        setIsGridLoading(true);

        const selectedContainers =
          gridRowSelection.selectedItems as ContWithLastChangedModified[];
        const containerSelected = selectedContainers[0];
        const containerId = containerSelected.CONTID;
        try {
          await mutationSaveAsTemplate.mutateAsync(containerId);
        } catch {}

        setIsGridLoading(false);
      },
      export: async (_event: any, gridRowSelection: GridRowSelection) => {
        toggleExportDialog();
        const selectedContainers =
          gridRowSelection.selectedItems as ContWithLastChangedModified[];
        setSelectedContainers(selectedContainers);
        const { shouldProceed, containersToExport } =
          await new Promise<ExportDialogResponse>((resolve) => {
            setExportDialogPromise({ resolve });
          });
        if (!shouldProceed) return;

        setIsGridLoading(true);
        for (const container of containersToExport as ContainerToExport[]) {
          try {
            await mutationExport.mutateAsync({
              dbName: container.dbName,
              contId: container.contId,
              exportFormat: container.exportFormat,
            });
          } catch {}
        }
        setIsGridLoading(false);
      },
    }),
    [
      database,
      navigate,
      setIsGridLoading,
      mutationDuplicate,
      mutationSaveAsTemplate,
      toggleExportDialog,
      setExportDialogPromise,
      mutationExport,
    ]
  );

  const gridActions: CreateDefaultGridActionsFunction = React.useCallback(
    (
      gridRowSelection: GridRowSelection,
      _dataResult,
      _dataState,
      deleteFunction: DeleteFunctionDefaultGrid
    ) => {
      const numberOfSelectedItems = gridRowSelection.selectedItems.length;
      if (numberOfSelectedItems === 0) return [];

      const commonActions = [
        {
          icon: "Edit.Delete",
          text: "Löschen",
          onClick: deleteFunction,
        },
        {
          icon: "General.Export",
          text: "Exportieren",
          onClick: containerActionHandlers.export,
        },
      ];

      if (numberOfSelectedItems <= 1) {
        return [
          {
            icon: "Navigation.Open",
            text: "Öffnen",
            onClick: containerActionHandlers.open,
          },
          {
            icon: "Grid.Duplicate",
            text: "Duplizieren",
            onClick: containerActionHandlers.duplicate,
          },
          {
            icon: "Symbol.Star",
            text: "Als Vorlage speichern",
            onClick: containerActionHandlers.saveAsTemplate,
          },
          ...commonActions,
        ];
      }

      return [<>{`${numberOfSelectedItems} ausgewählt`}</>, ...commonActions];
    },
    [containerActionHandlers]
  );

  const shouldReturnLoader = !database || isLoading;

  return {
    shouldReturnLoader,
    containers: containersWithFormattedDate,
    columnsDefinition,
    gridActions,
    customCells,
    toggleDeleteDialog,
    isDeleteDialogOpen,
    toggleExportDialog,
    isExportDialogOpen,
    selectedContainers,
    isGridLoading,
    setIsGridLoading,
    generateQuickInfoForOneItem,
  };
}
