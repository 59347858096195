import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Gliederungssystem } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import { QUERY_KEY_Gliederungssystem } from "src/modules/Datei/queries/useGetSingleGliederungssystem";
import { Versioned } from "../../../abk-shared/interfaces/Versioned";
import { useValidationError } from "../../../abk-shared/stores/ValidationErrorStoreProvider";
import { setValidationErrorFromABKError } from "../../../abk-shared/stores/ValidationErrorStoreProvider/useGetValidationErrorMessage";
import { formatWithEtag } from "./formatWithEtag";

type MutationSaveGliederungssystem = {
  db: string;
  contId: string;
  gliederungssystem: Versioned<Gliederungssystem>;
};
export default function useSaveGliederungssystem() {
  const { setValidationErrors } = useValidationError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      db,
      contId,
      gliederungssystem,
    }: MutationSaveGliederungssystem) => {
      return axios.put(
        `/api/db/${db}/cont/${contId}/gs/${gliederungssystem.value.DATAID}`,
        gliederungssystem.value,
        {
          headers: {
            "If-Match": gliederungssystem.etag,
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: (response, variables) => {
      setValidationErrors([]);
      queryClient.setQueryData(
        [
          QUERY_KEY_Gliederungssystem,
          variables.db,
          variables.contId,
          variables.gliederungssystem.value.DATAID,
        ],
        formatWithEtag(response)
      );
    },
    onError: (error) =>
      setValidationErrorFromABKError(error, setValidationErrors),
  });
}
