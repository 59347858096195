import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Gliederungssystem } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import { Versioned } from "../../../abk-shared/interfaces/Versioned";
import { formatWithEtag } from "./formatWithEtag";

export const QUERY_KEY_Gliederungssystem = "gs";

const useGetSingleGliederungssystem = (
  db: string,
  contId: string,
  gsID: string,
  ignoreError?: boolean
) => {
  const getSingleGliederungssystem = async () => {
    const url = `/api/db/${db}/cont/${contId}/gs/${gsID}`;

    try {
      const response = await axios.get<Gliederungssystem>(url);
      return formatWithEtag(response);
    } catch (error) {
      if (ignoreError) {
        return null as any;
      }
      throw error;
    }
  };

  return useQuery<Versioned<Gliederungssystem>>({
    queryKey: [QUERY_KEY_Gliederungssystem, db, contId, gsID],
    queryFn: () => getSingleGliederungssystem(),
    enabled: Boolean(db) && Boolean(contId) && Boolean(gsID),
  });
};

export default useGetSingleGliederungssystem;
