import ABKControlledExpansionPanel from "src/abk-shared/components/atoms/ABKControlledExpansionPanel";
import ExpansionPanelTitle from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/ExpansionPanelTitle";
import { generatePreviewForTextArea } from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/previewTextGenerator";
import AbkOenormEditor from "../../../../../../../core/components/organisms/AbkOenormEditor";
import CustomViewHtmlHook from "../../../../../../../core/components/organisms/AbkOenormEditor/CustomHTMLDialog/CustomViewHtmlHook";
import editorTools from "../../../../../../../core/components/organisms/AbkOenormEditor/editorTools";
import { EintragFrontend } from "../../../../../interfaces/parameterlisten.types";
import { PropsEintragPanel } from "./ParTypePanelsMap";

const KommentarPanel = ({
  setPageState,
  pageState,
  sections,
  updateSections,
}: PropsEintragPanel) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(pageState?.KOMMENTAR, "text/html");
  const text = doc.body.textContent || "";

  const preview = generatePreviewForTextArea({
    label: "Kommentar",
    value: text,
    isCheckbox: false,
  });
  const isExpanded = sections?.get("kommentar") || false;

  const handlePanelToggle = () => {
    if (updateSections && sections) {
      const newSections = new Map(sections);
      newSections.set("kommentar", !isExpanded);
      updateSections(newSections);
    }
  };
  return (
    <ABKControlledExpansionPanel
      expanded={isExpanded}
      onAction={handlePanelToggle}
      title={
        <ExpansionPanelTitle
          title="Kommentar"
          previewText={preview}
          shouldShowPreview={!isExpanded}
        />
      }
      key="kommentarPanel"
    >
      <AbkOenormEditor
        tools={[
          ...editorTools.oenormTools,
          [CustomViewHtmlHook],
          ...editorTools.extraTools,
          ...editorTools.oenormTabelleTools,
          ...editorTools.tableTools,
        ]}
        value={pageState?.KOMMENTAR ?? ""}
        setValue={(value: string) => {
          setPageState({ ...pageState, KOMMENTAR: value } as EintragFrontend);
        }}
      />
    </ABKControlledExpansionPanel>
  );
};

export default KommentarPanel;
