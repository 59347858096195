import { useSearchParams } from "react-router-dom";
import { SEARCH_PARAM_SCROLL_TO_ITEM } from "src/core/components/templates/useDraftNotifications/generateDraftNotificationForEintrag";
import { EintragFrontend } from "src/modules/Datei/interfaces/parameterlisten.types";
import { getEintragIndexOfDraft } from "src/modules/Datei/pages/Parameterlisten/Eintraege/useEintraege/eintraegeDrafts/eintraegeDraftHelperFunctions";

export function useRowIndexForInitialScrollTop(
  itemId: string,
  eintraege: EintragFrontend[] | undefined
) {
  const [searchParams] = useSearchParams();
  const shouldScrollToItem =
    searchParams.get(SEARCH_PARAM_SCROLL_TO_ITEM) === true.toString();

  const eintragIndexOfDraft = getEintragIndexOfDraft(itemId, eintraege);

  const rowIndexForInitialScrollTop =
    shouldScrollToItem && eintragIndexOfDraft !== -1
      ? eintragIndexOfDraft
      : undefined;

  return rowIndexForInitialScrollTop;
}
