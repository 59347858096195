import { GenericObject } from "src/abk-shared/interfaces/GenericObject";

interface Args {
  itemsSelected: GenericObject[];
  newItemsSelected: GenericObject[];
  dataItemKey: string;
}

/**
 * Compares itemsSelected[] and newItemsSelected[] by dataItemKey and returns the new item in newItemsSelected[]
 */
export function findNewItem({
  itemsSelected,
  newItemsSelected,
  dataItemKey,
}: Args): GenericObject | undefined {
  const itemsSelectedIdentifiers = new Set(
    itemsSelected.map((item) => item[dataItemKey])
  );
  return (
    newItemsSelected.find(
      (newItem) => !itemsSelectedIdentifiers.has(newItem[dataItemKey])
    ) || undefined
  );
}
