import ABKControlledExpansionPanel from "src/abk-shared/components/atoms/ABKControlledExpansionPanel";
import ExpansionPanelTitle from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/ExpansionPanelTitle";
import ABKFormFieldInput from "src/abk-shared/components/atoms/ABKFormFieldInput";
import ABKFormRow from "src/abk-shared/components/atoms/ABKFormRow";
import ABKForm from "src/abk-shared/components/molecules/ABKForm";
import DownloadLink from "src/modules/Datei/pages/components/KenndatenPanels/Allgemein/DownloadLink";
import { DropDownGliederungsart } from "src/modules/Datei/pages/components/KenndatenPanels/Allgemein/DropDownGliederungsart";
import DropDownStatus from "src/modules/Datei/pages/components/KenndatenPanels/Allgemein/DropDownStatus";
import { generatePreviewTextAllgemeinKenndaten } from "src/modules/Datei/pages/components/KenndatenPanels/Allgemein/generatePreviewTextAllgemeinKenndaten";
import KenndatenDate from "src/modules/Datei/pages/components/KenndatenPanels/Allgemein/KenndatenDate";
import UploadLogo from "src/modules/Datei/pages/components/KenndatenPanels/Allgemein/UploadLogo";
import Version from "src/modules/Datei/pages/components/KenndatenPanels/Allgemein/Version";
import {
  GliederungssystemProps,
  KenndatenFormProps,
} from "src/modules/Datei/pages/components/KenndatenPanels/KenndatenPanels";

export type AllgemeinFormGeneralProps = Omit<
  KenndatenFormProps,
  "updateSections" | "isOpen"
>;

const AllgemeinForm = ({
  kenndaten,
  setKenndaten,
  isOpen,
  updateSections,
  readOnly,
  gliederungssystemSubtype,
  setGliederungssystemSubtype,
}: KenndatenFormProps & GliederungssystemProps) => {
  const previewText = generatePreviewTextAllgemeinKenndaten(
    kenndaten.BEZ,
    kenndaten.KENNUNG,
    kenndaten.VERSIONSNR,
    kenndaten.VERSIONSDATE,
    kenndaten.STATUS
  );

  const isGliederungssystem =
    gliederungssystemSubtype !== undefined &&
    setGliederungssystemSubtype !== undefined;

  return (
    <ABKControlledExpansionPanel
      title={
        <ExpansionPanelTitle
          title="Allgemein"
          previewText={previewText}
          shouldShowPreview={!isOpen}
        />
      }
      expanded={isOpen}
      onAction={updateSections}
    >
      <ABKFormRow>
        <ABKForm
          sections={[
            {
              formElements: [
                ...(isGliederungssystem
                  ? [
                      <ABKFormRow>
                        <DropDownGliederungsart
                          gliederungssystemSubtype={gliederungssystemSubtype}
                          setGliederungssystemSubtype={
                            setGliederungssystemSubtype
                          }
                        />
                      </ABKFormRow>,
                    ]
                  : []),
                <ABKFormRow>
                  <ABKFormFieldInput
                    editorId="bezeichnung"
                    label="Bezeichnung"
                    backendKey="BEZ"
                    value={kenndaten.BEZ || ""}
                    setValue={
                      readOnly
                        ? undefined
                        : (value) =>
                            setKenndaten({
                              ...kenndaten,
                              BEZ: value,
                            })
                    }
                  />
                </ABKFormRow>,
                <ABKFormRow>
                  <ABKFormFieldInput
                    editorId="kennung"
                    label="Kennung"
                    backendKey="KENNUNG"
                    value={kenndaten.KENNUNG || ""}
                    setValue={
                      readOnly
                        ? undefined
                        : (value) =>
                            setKenndaten({
                              ...kenndaten,
                              KENNUNG: value,
                            })
                    }
                  />
                </ABKFormRow>,
                <div className="version-date-status-container">
                  <Version
                    kenndaten={kenndaten}
                    setKenndaten={setKenndaten}
                    readOnly={readOnly}
                  />
                  <KenndatenDate
                    kenndaten={kenndaten}
                    setKenndaten={setKenndaten}
                    readOnly={readOnly}
                  />
                  <DropDownStatus
                    kenndaten={kenndaten}
                    setKenndaten={setKenndaten}
                  />
                </div>,
                <ABKFormRow>
                  <DownloadLink
                    kenndaten={kenndaten}
                    setKenndaten={setKenndaten}
                    readOnly={readOnly}
                  />
                </ABKFormRow>,
              ],
            },
          ]}
        />
        {/* Zusätzliches <div>, um dem HTML-Markup von `ABKForm` zu entsprechen  */}
        <div className="k-flex-1">
          <div>
            <UploadLogo
              kenndaten={kenndaten}
              setKenndaten={setKenndaten}
              disabled={readOnly}
            />
          </div>
        </div>
      </ABKFormRow>
    </ABKControlledExpansionPanel>
  );
};

export default AllgemeinForm;
