import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import { AbkIconsMap } from "../../../interfaces/abk9SvgIcons";

interface UploadButtonProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className: string;
  disabled: boolean;
  multiple: boolean;
  accept?: string;
  svgIconKey: string;
  title: string;
  buttonId: string;
}

export const ABKUploadButtonWrapper: React.FC<UploadButtonProps> = ({
  buttonId,
  onChange,
  className,
  disabled,
  multiple,
  accept = "",
  svgIconKey,
  title,
}) => {
  const handleClick = () => {
    const fileInput = document.getElementById(buttonId) as HTMLInputElement;
    fileInput.click();
  };

  return (
    <div className={className}>
      <input
        id={buttonId}
        type="file"
        style={{ display: "none" }}
        onChange={onChange}
        multiple={multiple}
        accept={accept}
        /*
          Allow the user to select the same file twice.
          From: https://stackoverflow.com/a/40429197
        */
        onClick={(event) => ((event.target as any).value = null)}
      />
      <Button
        onClick={handleClick}
        svgIcon={AbkIconsMap.get(svgIconKey)}
        title={title}
        disabled={disabled}
      />
    </div>
  );
};
