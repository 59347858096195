import React from "react";
import { useNavigate } from "react-router-dom";
import { useAddCatalogItem } from "src/modules/Datei/pages/components/CatalogItemsWrapper/CatalogItemsGrid/useCatalogItemsGrid/useCatalogItemsGridActionHandlers/useAddCatalogItem";
import {
  CatalogItems,
  CatalogType,
} from "src/modules/Datei/pages/components/CatalogItemsWrapper/CatalogItemsWrapper";
import { createCatalogItemURL } from "src/modules/Datei/pages/components/CatalogItemsWrapper/createCatalogItemURL";
import { Parameterliste } from "../../../../../../interfaces/parameterlisten.types";
import useAddCatalogItemAbove from "./useAddCatalogItemAbove";
import { useDuplicateCatalogItem } from "./useDuplicateCatalogItem";

export function useCatalogItemsGridActionHandlers(
  catalogType: CatalogType,
  dbName: string,
  contId: string,
  setIsGridLoading: React.Dispatch<React.SetStateAction<boolean>>
) {
  const navigate = useNavigate();

  const mutationAddCatalogItem = useAddCatalogItem(catalogType, dbName, contId);
  const mutationAddCatalogItemAbove = useAddCatalogItemAbove(catalogType);
  const mutationDuplicateCatalogItem = useDuplicateCatalogItem(catalogType);

  const catalogItemsGridActionHandlers = React.useMemo(
    () => ({
      open: (_event: any, selectedCatalogItems: CatalogItems) => {
        const catalogItemSelected = selectedCatalogItems[0];
        const catalogItemURL = createCatalogItemURL(
          catalogType,
          catalogItemSelected.DATAID,
          catalogItemSelected.DBNAME,
          catalogItemSelected.CONTID
        );
        navigate(catalogItemURL);
      },

      createAtEnd: async () => {
        setIsGridLoading(true);
        try {
          await mutationAddCatalogItem.mutateAsync();
        } catch {
          setIsGridLoading(false);
        }
      },

      createBeforeRow: async (selectedItem: Parameterliste) => {
        setIsGridLoading(true);
        try {
          await mutationAddCatalogItemAbove.mutateAsync(selectedItem);
        } catch {
          setIsGridLoading(false);
        }
      },

      duplicate: async (selectedItem: Parameterliste) => {
        setIsGridLoading(true);
        try {
          const duplicatedItem =
            await mutationDuplicateCatalogItem.mutateAsync(selectedItem);
          return duplicatedItem.data;
        } catch {
          setIsGridLoading(false);
          return;
        }
      },
    }),
    [
      catalogType,
      mutationAddCatalogItem,
      mutationAddCatalogItemAbove,
      mutationDuplicateCatalogItem,
      navigate,
      setIsGridLoading,
    ]
  );

  return catalogItemsGridActionHandlers;
}
