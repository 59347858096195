import { AllgemeinFormGeneralProps } from "src/modules/Datei/pages/components/KenndatenPanels/Allgemein/AllgemeinForm";
import ABKFormFieldInput from "../../../../../../abk-shared/components/atoms/ABKFormFieldInput";
import addHttpToLinkIfNotPresent from "../addHttpToLinkIfNotPresent";

export default function DownloadLink({
  kenndaten,
  setKenndaten,
  readOnly,
}: AllgemeinFormGeneralProps) {
  const keyDownloadLink = "DLURL";

  const value = readOnly ? (
    <a
      className="kenndaten-link"
      href={addHttpToLinkIfNotPresent(kenndaten[keyDownloadLink])}
      target="_blank"
      rel="noreferrer"
    >
      {kenndaten[keyDownloadLink]}
    </a>
  ) : (
    kenndaten[keyDownloadLink] || ""
  );

  const setValue = readOnly
    ? undefined
    : (value: string) =>
        setKenndaten({
          ...kenndaten,
          [keyDownloadLink]: value,
        });

  return (
    <ABKFormFieldInput
      editorId="downloadlink"
      label="Downloadlink"
      value={value}
      setValue={setValue}
      useJSXForValue={readOnly}
      backendKey={keyDownloadLink}
    />
  );
}
