import React from "react";
import { DialogResponse } from "src/core/components/organisms/DefaultGrid/useDefaultGridDeleteFunction/DeleteDialog";
import { EintragFrontend } from "src/modules/Datei/interfaces/parameterlisten.types";
import { SaveType } from "src/modules/Datei/pages/Parameterlisten/Eintraege/SaveTypeEnum";
import { createStore, StoreApi, useStore } from "zustand";

/*
  Der Code hier wurde von diesem Artikel inspiriert:
  https://tkdodo.eu/blog/zustand-and-react-context

  Auch in der Zustand-Dokumentation vorhanden:
  https://zustand.docs.pmnd.rs/hooks/use-store#using-scoped-(non-global)-vanilla-store-in-react
*/

type DeleteDialogPromise = {
  resolve: (value: DialogResponse) => void;
} | null;

export interface EintragBearbeitenState {
  currentEintrag: EintragFrontend | null;
  setCurrentEintrag: (eintrag: EintragFrontend | null) => void;

  pageState: EintragFrontend | null;
  setPageState: (eintrag: EintragFrontend) => void;

  openDialog: boolean;
  setOpenDialog: (open: boolean) => void;

  showDeleteDialog: boolean;
  setShowDeleteDialog: (show: boolean) => void;

  selectedEintraege: EintragFrontend[];
  setSelectedEintraege: (eintraege: EintragFrontend[]) => void;

  deleteDialogPromise: DeleteDialogPromise;
  setDeleteDialogPromise: (deleteDialogPromise: DeleteDialogPromise) => void;

  showNewItemDialog: boolean;
  setShowNewItemDialog: (show: boolean) => void;

  newItemSaveType: SaveType;
  setNewItemSaveType: (saveType: SaveType) => void;
}

const initialState = Object.freeze({
  currentEintrag: null,
  pageState: null,
  openDialog: false,
  showDeleteDialog: false,
  selectedEintraege: [],
  deleteDialogPromise: null,
  showNewItemDialog: false,
  newItemSaveType: SaveType.NewAtEnd,
});

const EintraegePageStoreContext =
  React.createContext<StoreApi<EintragBearbeitenState> | null>(null);

const useEintragePageStore = (
  selector: (state: EintragBearbeitenState) => EintragBearbeitenState
) => {
  const store = React.useContext(EintraegePageStoreContext);
  if (!store) throw new Error("Missing EintraegePageStoreContext");

  return useStore(store, selector);
};

export const useEintraegeStore = () =>
  useEintragePageStore((state: EintragBearbeitenState) => state);

const useCreateStoreDuringFirstRender = () =>
  React.useState(() =>
    createStore<EintragBearbeitenState>((set) => ({
      ...initialState,
      setCurrentEintrag: (eintrag: EintragFrontend | null) =>
        set({ currentEintrag: eintrag }),
      setPageState: (eintrag: EintragFrontend) => set({ pageState: eintrag }),
      setOpenDialog: (open: boolean) => set({ openDialog: open }),
      setShowDeleteDialog: (show: boolean) => set({ showDeleteDialog: show }),
      setSelectedEintraege: (eintraege: EintragFrontend[]) =>
        set({ selectedEintraege: eintraege }),
      setDeleteDialogPromise: (deleteDialogPromise: DeleteDialogPromise) =>
        set({ deleteDialogPromise }),
      setShowNewItemDialog: (show: boolean) => {
        set({ showNewItemDialog: show });
        if (show === true) {
          set({ openDialog: false });
        }
      },
      setNewItemSaveType: (saveType: SaveType) =>
        set({ newItemSaveType: saveType }),
    }))
  );

export function EintraegePageStoreProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [store] = useCreateStoreDuringFirstRender();

  return (
    <EintraegePageStoreContext.Provider value={store}>
      {children}
    </EintraegePageStoreContext.Provider>
  );
}
