import { mapTree } from "@progress/kendo-react-treelist";
import { SelectedState } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import { GliederungssystemEintrag } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import { GliederungssystemEintragId } from "src/modules/Datei/interfaces/parameterlisten.types";
import {
  DATA_ITEM_KEY,
  PARENT_FIELD,
  SUB_ITEMS_FIELD,
} from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/constants";

export function convertArrayToSelectedState(
  selectedItems: GliederungssystemEintragId[],
  dataTree: any[]
) {
  const itemsMap = createSelectedItemsMap(selectedItems, dataTree);
  const selectedState: SelectedState = {};

  mapTree(dataTree, SUB_ITEMS_FIELD, (item) => {
    const isSelected = itemsMap.get(item[DATA_ITEM_KEY])?.isSelected;
    if (isSelected) {
      selectedState[item[DATA_ITEM_KEY]] = true;
      return;
    }

    const isChild = !!item[PARENT_FIELD];
    if (!isChild) return;

    const parentEintragId = item[PARENT_FIELD];

    const parentIsSelected = itemIsSelectedOrHasSelectedParentUpInTheHierarchy(
      parentEintragId,
      itemsMap
    );

    if (parentIsSelected) selectedState[item[DATA_ITEM_KEY]] = true;
    return;
  });

  return selectedState;
}

function itemIsSelectedOrHasSelectedParentUpInTheHierarchy(
  itemId: string,
  itemsMap: ItemsMap
) {
  const item = itemsMap.get(itemId);

  const isSelected = item?.isSelected;
  if (isSelected) return true;

  const parentId = item?.item?.PARENTID;
  const isChild = !!parentId;
  if (!isChild) return isSelected;

  return itemIsSelectedOrHasSelectedParentUpInTheHierarchy(parentId, itemsMap);
}

export function convertSelectedStateToArray(selectedState: SelectedState) {
  const array = Object.keys(selectedState)
    .filter((eintragId) => selectedState[eintragId] === true)
    .map((item) => ({ ItemID: item }));
  return array;
}

type ItemsMap = Map<
  string,
  {
    isSelected: boolean;
    item: GliederungssystemEintrag;
  }
>;
export function createSelectedItemsMap(
  selectedItems: GliederungssystemEintragId[],
  dataTree: any[]
) {
  const selectedItemsIdMap = selectedItems.reduce((map, eintrag) => {
    map.set(eintrag.ItemID, eintrag);
    return map;
  }, new Map<string, GliederungssystemEintragId>());

  const selectedItemsMap: ItemsMap = new Map();

  mapTree(dataTree, SUB_ITEMS_FIELD, (item) => {
    const isSelected = selectedItemsIdMap.has(item[DATA_ITEM_KEY]);
    selectedItemsMap.set(item[DATA_ITEM_KEY], { isSelected, item });
  });

  return selectedItemsMap;
}
