import { EintragDraft } from "src/core/db/dbDrafts";
import { EintragFrontend } from "src/modules/Datei/interfaces/parameterlisten.types";

export const hasDraft = (drafts: EintragDraft[] | undefined, itemId: string) =>
  !!drafts && drafts.some((draft) => draft.itemId === itemId);

export function getEintragIndexOfDraft(
  itemId: string | undefined,
  eintraege: EintragFrontend[] | undefined
) {
  if (!eintraege || !itemId) return -1;

  return eintraege.findIndex((eintrag) => eintrag.ITEMID === itemId);
}
