import React from "react";
import ABKNavigationButtonItem from "src/abk-shared/components/atoms/ABKNavigationButtonItem";
import ABKOptionsIconButton from "src/abk-shared/components/atoms/ABKOptionsIconButton";
import ABKToolbarButton from "src/abk-shared/components/atoms/ABKToolbarButton";
import {
  ToolbarAction,
  ToolbarActionObject,
} from "src/abk-shared/components/molecules/ABKToolbar/abkToolbar.types";
import { AbkIconsMap } from "src/abk-shared/interfaces/abk9SvgIcons";

export function createToolbarActions(
  isMobile: boolean,
  numberOfActionsDisplayedInMobile: number,
  actions: ToolbarAction[]
) {
  const numberOfActionsDisplayed = isMobile
    ? numberOfActionsDisplayedInMobile
    : // Zeige alle Aktionen für die Desktop-Version
      undefined;

  const firstActions = actions.slice(0, numberOfActionsDisplayed);
  const actionsDisplayed = firstActions.map((action, index) => {
    if (React.isValidElement(action)) {
      return (
        <span className="k-align-self-center" key={`reactNode_${index}`}>
          {action}
        </span>
      );
    }

    const actionObject = action as ToolbarActionObject;
    return (
      <ABKToolbarButton
        isMobile={isMobile}
        svgIcon={AbkIconsMap.get(actionObject.icon)}
        title={actionObject.text}
        onClick={actionObject.onClick}
        key={actionObject.text}
      />
    );
  });

  const remainingActions = isMobile
    ? actions.slice(numberOfActionsDisplayed)
    : []; // Zeige alle Aktionen für die Desktop-Version
  const actionsInDropdown =
    remainingActions.length === 0 ? null : (
      <React.Fragment key="menu">
        <ABKOptionsIconButton
          buttonClassName="grid-toolbar-button"
          title="Weitere Funktionen"
          insidePopupElement={
            <>
              {(remainingActions as ToolbarActionObject[]).map(
                (actionObject) => (
                  <ABKNavigationButtonItem
                    text={actionObject.text}
                    onClick={actionObject.onClick}
                    icon={actionObject.icon}
                    key={actionObject.text}
                  />
                )
              )}
            </>
          }
        />
      </React.Fragment>
    );

  const toolbarActions = [...actionsDisplayed, actionsInDropdown];

  return <>{toolbarActions}</>;
}
