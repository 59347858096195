import {
  CATALOG_TYPE_PLACEHOLDER,
  CONTID_PLACEHOLDER,
  DATA_ID_PLACEHOLDER,
  DATABASE_PLACEHOLDER,
  FILE_KENNDATEN,
} from "src/core/constants/urls";
import { CatalogType } from "src/modules/Datei/pages/components/CatalogItemsWrapper/CatalogItemsWrapper";

export const createCatalogItemURL = (
  catalogType: CatalogType,
  dataId: string,
  dbName: string,
  contId: string
) =>
  FILE_KENNDATEN.replace(CATALOG_TYPE_PLACEHOLDER, catalogType)
    .replace(DATA_ID_PLACEHOLDER, dataId)
    .replace(DATABASE_PLACEHOLDER, dbName)
    .replace(CONTID_PLACEHOLDER, contId);
