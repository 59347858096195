import { PromiseExtended } from "dexie";
import React from "react";
import { ABKLoader } from "src/abk-shared/components/atoms/ABKLoader/ABKLoader";
import { Versioned } from "src/abk-shared/interfaces/Versioned";
import { useValidationError } from "src/abk-shared/stores/ValidationErrorStoreProvider";
import { EintragFrontend } from "src/modules/Datei/interfaces/parameterlisten.types";
import { parTypePanelsMap } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ParTypePanelsMap";
import { useSaveEintraegeSelectionChange } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/useSaveEintraegeSelectionChange";

type Props = {
  currentEintrag: EintragFrontend | null;
  saveEintragFunctionRef: React.MutableRefObject<(() => Promise<void>) | null>;
  pageState: Versioned<EintragFrontend> | undefined;
  updatePageState: (pageState: Partial<EintragFrontend>) => Promise<void>;
  sections: Map<string, boolean>;
  updateSections: (sections: Map<string, boolean>) => void;
  handleSave: () => Promise<void>;
  handleReset: () => PromiseExtended<void> | undefined;
  conflictMessage?: string;
  isEdited: boolean;
};

export function EigenschaftenfensterPanels({
  saveEintragFunctionRef,
  pageState,
  updatePageState,
  sections,
  updateSections,
  handleSave,
}: Props) {
  const { setValidationErrors } = useValidationError();

  React.useEffect(() => {
    saveEintragFunctionRef.current = async () => {
      await handleSave();
      setValidationErrors([]);
    };
    // eslint-disable-next-line
  }, [handleSave]);

  useSaveEintraegeSelectionChange({
    handleSaveRef: saveEintragFunctionRef,
  });

  if (!pageState) return <ABKLoader />;

  const panels = parTypePanelsMap.get(pageState.value.ITEMTYPE) || [];

  return (
    <>
      {panels.map((panel, index) => (
        <div key={index}>
          {panel &&
            panel(pageState.value, updatePageState, sections, updateSections)}
        </div>
      ))}
    </>
  );
}
