import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Versioned } from "src/abk-shared/interfaces/Versioned";
import { formatWithEtag } from "src/modules/Datei/queries/formatWithEtag";
import { QUERY_KEY_PARAMETERLISTE } from "src/modules/Datei/queries/useGetSingleParameterliste";
import { PiktogrammeBackendResponse } from "./piktogramm.types";

export const QUERY_KEY_PIKTOGRAMME = "QUERY_KEY_PIKTOGRAMME";

export default function useGetPiktogramme(
  dbName: string,
  contId: string,
  plID: string,
  ignoreError?: boolean
) {
  return useQuery({
    queryKey: [
      dbName,
      contId,
      plID,
      QUERY_KEY_PARAMETERLISTE,
      QUERY_KEY_PIKTOGRAMME,
    ],
    queryFn: async () => {
      try {
        const response = await axios.get(
          `/api/db/${dbName}/cont/${contId}/pl/${plID}/pikto`
        );
        return formatWithEtag(
          response
        ) as Versioned<PiktogrammeBackendResponse>;
      } catch (error) {
        if (ignoreError) {
          return null as unknown as Versioned<PiktogrammeBackendResponse>;
        }
        throw error;
      }
    },
    enabled: Boolean(dbName) && Boolean(contId) && Boolean(plID),
  });
}
