import { Address } from "src/abk-shared/components/atoms/ABKAddressSelect";
import { LogoFormat } from "src/core/utility/imageProcessing";

export interface Kenndaten {
  KENNUNG: string;
  VERSIONSNR: number;
  VERSIONSDATE: string | null;
  STATUS: KenndatenStatus;
  BEZ: string;
  BESCH: string;
  DLURL: string;
  Herausgeber: Herausgeber;
  LOGO: string;
  LOGOFORMAT: LogoFormat;
  FUNKTGSDATAID: string;
  PRODGSDATAID: string;
}

export interface Herausgeber {
  firma: Firma;
  person: Kontaktperson;
}

export interface Kommunikation {
  adresse: Address;
  email: string;
  telefon: string;
  fax: string;
  www: string;
  zusatzinfo: string;
}

export interface Firma {
  name: string;
  kommunikation: Kommunikation;
}

export interface Kontaktperson {
  vorname: string;
  nachname: string;
  kommunikation: Kommunikation;
}

export type KenndatenFrontend = Omit<Kenndaten, "STATUS"> & {
  STATUS: KenndatenStatusFrontend;
};

export enum KenndatenStatus {
  Entwurf = "E",
  Freigegeben = "F",
}

export enum KenndatenStatusFrontend {
  Entwurf = "Entwurf",
  Freigegeben = "Freigegeben",
}
