import ABKControlledExpansionPanel from "src/abk-shared/components/atoms/ABKControlledExpansionPanel";
import ExpansionPanelTitle from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/ExpansionPanelTitle";
import generatePreviewTextForListOfIFields from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/previewTextGenerator";
import { OHNE_INHALT } from "src/abk-shared/constants/text";
import { GliederungssystemSubtype } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import { KenndatenFormProps } from "src/modules/Datei/pages/components/KenndatenPanels/KenndatenPanels";
import { DropdownZuordnung } from "src/modules/Datei/pages/components/KenndatenPanels/Zuordnungen/DropdownZuordnung";
import { useGetGliederungssysteme } from "src/modules/Datei/queries/useGetGliederungssysteme";
import "./styles.scss";

const ZuordnungenForm = ({
  db,
  contId,
  kenndaten,
  setKenndaten,
  isOpen,
  updateSections,
  readOnly,
}: KenndatenFormProps & {
  db: string;
  contId: string;
}) => {
  const gliederungsSystemeQuery = useGetGliederungssysteme(db, contId);
  const gliederungsSysteme = gliederungsSystemeQuery.data?.value?.Items || [];

  const resolveNameById = (id: string, subtype: GliederungssystemSubtype) => {
    const item = gliederungsSysteme.find(
      (x) => x.SUBTYPE === subtype && x.DATAID === id
    );
    return item ? `${item.Kenndaten.BEZ} [${item.Kenndaten.KENNUNG}]` : "";
  };

  const funktionName = resolveNameById(
    kenndaten?.FUNKTGSDATAID,
    GliederungssystemSubtype.Funktion
  );

  const produktgruppeName = resolveNameById(
    kenndaten?.PRODGSDATAID,
    GliederungssystemSubtype.Produkt
  );

  const previewFields = [];

  if (funktionName) {
    previewFields.push({
      label: "Funktion",
      value: funktionName,
      isCheckbox: false,
    });
  }

  if (produktgruppeName) {
    previewFields.push({
      label: "Produktgruppe",
      value: produktgruppeName,
      isCheckbox: false,
    });
  }

  const previewText =
    previewFields.length === 0
      ? OHNE_INHALT
      : generatePreviewTextForListOfIFields(previewFields);

  return (
    <ABKControlledExpansionPanel
      title={
        <ExpansionPanelTitle
          title="Zuordnungen"
          previewText={previewText}
          shouldShowPreview={!isOpen}
        />
      }
      expanded={isOpen}
      onAction={updateSections}
    >
      <div className="parameter-listen-zuordnungen-panel-flex-row">
        <DropdownZuordnung
          db={db}
          contId={contId}
          kenndaten={kenndaten}
          setKenndaten={setKenndaten}
          readOnly={readOnly}
          zuordnungSetting={{
            fieldId: "FUNKTGSDATAID",
            type: GliederungssystemSubtype.Funktion,
            label: "Gliederung Funktion",
            tooltip: "Verwendetes Gliederungssystem für Funktion",
          }}
        />
        <DropdownZuordnung
          db={db}
          contId={contId}
          kenndaten={kenndaten}
          setKenndaten={setKenndaten}
          readOnly={readOnly}
          zuordnungSetting={{
            fieldId: "PRODGSDATAID",
            type: GliederungssystemSubtype.Produkt,
            label: "Gliederung Produktgruppe",
            tooltip: "Verwendetes Gliederungssystem für Produktgruppe",
          }}
        />
      </div>
    </ABKControlledExpansionPanel>
  );
};

export default ZuordnungenForm;
