import { useLiveQuery } from "dexie-react-hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { INotification } from "src/abk-shared/components/organisms/ABKSideBar/NotificationBox";
import { generateDraftNotificationForEintrag } from "src/core/components/templates/useDraftNotifications/generateDraftNotificationForEintrag";
import { generateDraftNotificationForFileInfo } from "src/core/components/templates/useDraftNotifications/generateDraftNotificationForFileInfo";
import { useEintraegeDrafts } from "src/core/components/templates/useDraftNotifications/useEintraegeDrafts";
import { dbDrafts } from "src/core/db/dbDrafts";
import { ITEM_ID_NEW_EINTRAG } from "src/modules/Datei/pages/Parameterlisten/Eintraege/Dialoge/NewItemDialog";

export function useDraftNotifications(database: string | undefined) {
  const drafts = useLiveQuery(async () => {
    if (!database) {
      return [];
    } else {
      return dbDrafts.containerInfoDrafts
        .where("database")
        .equals(database)
        .toArray();
    }
  }, [database]);

  const draftsEintraege = useEintraegeDrafts(database);

  const [draftNotifications, setDraftNotifications] = useState<INotification[]>(
    []
  );

  const navigate = useNavigate();

  useEffect(() => {
    const newDraftNotifications: INotification[] = [];

    if (drafts) {
      const notifications = drafts.map((draft) =>
        generateDraftNotificationForFileInfo(draft, navigate)
      );
      newDraftNotifications.push(...notifications);
    }

    if (draftsEintraege) {
      const notifications = draftsEintraege.map((draft) => {
        if (draft.itemId === ITEM_ID_NEW_EINTRAG) {
          return generateDraftNotificationForEintrag(draft, navigate);
        }

        return generateDraftNotificationForEintrag(draft, navigate);
      });
      newDraftNotifications.push(...notifications);
    }

    setDraftNotifications(newDraftNotifications);
    // eslint-disable-next-line
  }, [drafts, draftsEintraege]);

  return draftNotifications;
}
