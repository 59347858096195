import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Parameterliste } from "../interfaces/parameterlisten.types";
import { QUERY_KEY_PARAMETERLISTE } from "./useGetSingleParameterliste";

const useGetAllContainerParameterlisten = (
  db: string,
  contId: string,
  ignoreError?: boolean
) => {
  const getAllContainerParameterlisten = async () => {
    const response = await axios.get(`/api/db/${db}/cont/${contId}/pl`);
    try {
      const parameterlisten = response.data.Items;
      return parameterlisten as Parameterliste[];
    } catch (error) {
      if (ignoreError) {
        return [] as Parameterliste[];
      }
      throw error;
    }
  };

  return useQuery({
    queryKey: [QUERY_KEY_PARAMETERLISTE, db, contId],
    queryFn: () => getAllContainerParameterlisten(),
    enabled: Boolean(db) && Boolean(contId),
  });
};

export default useGetAllContainerParameterlisten;
