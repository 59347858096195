import { GenericObject } from "src/abk-shared/interfaces/GenericObject";
import ABKConfirmationDialog from "../../../../../abk-shared/components/molecules/ABKConfirmationDialog";

export type DialogResponse = { shouldProceed: boolean };

type Props = {
  toggleDialog: () => void;
  deleteDialogPromise: {
    resolve: (value: DialogResponse) => void;
  } | null;
  selectedItems: GenericObject[];
};

const DeleteDialog = ({
  toggleDialog,
  deleteDialogPromise,
  selectedItems,
}: Props) => {
  return (
    <ABKConfirmationDialog
      onClose={() => {
        toggleDialog();
        if (deleteDialogPromise)
          deleteDialogPromise.resolve({ shouldProceed: false });
      }}
      message={
        <p>
          Sind Sie sicher, dass Sie die Auswahl löschen wollen? (
          {selectedItems.length})
        </p>
      }
      onConfirm={() => {
        toggleDialog();
        if (deleteDialogPromise)
          deleteDialogPromise.resolve({ shouldProceed: true });
      }}
    />
  );
};

export default DeleteDialog;
