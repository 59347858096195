import {
  TreeListHeaderCellProps,
  TreeListHeaderSelectionCell,
} from "@progress/kendo-react-treelist";

const testClassName = "test-class-tree-header-selection-checkbox";

interface Props extends TreeListHeaderCellProps {
  isIndeterminate: boolean;
}

export const CustomTreeListHeaderSelectionCell = (props: Props) => {
  let className = testClassName;
  if (props.isIndeterminate) {
    className += " tree-list-checkbox-indeterminate";
  }

  return (
    <div className={className}>
      <TreeListHeaderSelectionCell {...props} />
    </div>
  );
};
