import {
  FILE_EINTRAEGE_SUFFIX,
  FILE_GRAFIKEN_SUFFIX,
  FILE_KENNDATEN_SUFFIX,
  FILE_KENNDATEN_VORVERSION_SUFFIX,
  FILE_WEITEREGLIEDERUNGSSYSTEME_SUFFIX,
} from "src/core/constants/urls";
import { useSetLastBreadcrumbForDataType } from "src/modules/Datei/pages/components/useSetLastBreadcrumbForDataType";
import useGetAllContainerGliederungssysteme from "src/modules/Datei/queries/useGetAllContainerGliederungssysteme";
import TabsLayout from "src/Router/Layout/TabsLayout";

type Props = { gsID: string; dbName: string; contId: string };

export const GliederungssystemLayout = ({ gsID, dbName, contId }: Props) => {
  const { data: gliederungssysteme } = useGetAllContainerGliederungssysteme(
    dbName,
    contId
  );

  useSetLastBreadcrumbForDataType(gsID, gliederungssysteme);

  return (
    <TabsLayout
      routePrefix={FILE_WEITEREGLIEDERUNGSSYSTEME_SUFFIX}
      tabs={[
        {
          id: 0,
          label: "Kenndaten",
          to: `${FILE_WEITEREGLIEDERUNGSSYSTEME_SUFFIX}/${gsID}/${FILE_KENNDATEN_SUFFIX}`,
        },
        {
          id: 1,
          label: "Vorversion",
          to: `${FILE_WEITEREGLIEDERUNGSSYSTEME_SUFFIX}/${gsID}/${FILE_KENNDATEN_VORVERSION_SUFFIX}`,
        },
        {
          id: 2,
          label: "Einträge",
          to: `${FILE_WEITEREGLIEDERUNGSSYSTEME_SUFFIX}/${gsID}/${FILE_EINTRAEGE_SUFFIX}`,
        },
        {
          id: 3,
          label: "Grafiken",
          to: `${FILE_WEITEREGLIEDERUNGSSYSTEME_SUFFIX}/${gsID}/${FILE_GRAFIKEN_SUFFIX}`,
        },
      ]}
    />
  );
};
