import { InfoItemContent } from ".";
import ItemContent from "./ItemContent";

type Props = { title: string; content: InfoItemContent };

const QuickInfoItem = ({ title, content }: Props) => (
  <div className={`quick-info-item test-e2e-quick-info-wrapper-item`}>
    <p className="quick-info-item-title">{title}</p>
    <ItemContent itemContent={content} />
  </div>
);

export default QuickInfoItem;
