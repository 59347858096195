import React from "react";
import { DefaultGrid } from "src/core/components/organisms/DefaultGrid/DefaultGrid";
import { useEintraegeDrafts } from "src/core/components/templates/useDraftNotifications/useEintraegeDrafts";
import EintraegeCard from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintraegeCard";
import useQueryZuordnungData from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/queries/useFetchZuordnungData";
import { hasDraft } from "src/modules/Datei/pages/Parameterlisten/Eintraege/useEintraege/eintraegeDrafts/eintraegeDraftHelperFunctions";
import { useSetCurrentEintrag } from "src/modules/Datei/pages/Parameterlisten/Eintraege/useEintraege/useSetCurrentEintrag/useSetCurrentEintrag";
import {
  EintragBackend,
  EintragFrontend,
} from "../../../interfaces/parameterlisten.types";
import { backendEintragToFrontend } from "./convertBackendEintrag";
import useEintraege from "./useEintraege";
import useGetEintraege from "./useGetEintraege";

const dataItemKey = "ITEMID";

type Props = {
  dbName: string;
  contId: string;
  plID: string;
  itemId: string;
};

const PLEintraegeGrid = ({ dbName, contId, plID, itemId }: Props) => {
  const zuordnungDataProdukt = useQueryZuordnungData(
    dbName,
    contId,
    plID,
    "Produkt"
  );
  const zuordnungDataFunktion = useQueryZuordnungData(
    dbName,
    contId,
    plID,
    "Funktion"
  );

  const { data, isLoading, isError, error, isFetching } = useGetEintraege(
    dbName,
    contId,
    plID
  );

  const eintraege = React.useMemo(
    () =>
      data?.map((backendEintrag: EintragBackend) =>
        backendEintragToFrontend(backendEintrag, [
          ...zuordnungDataProdukt.data,
          ...zuordnungDataFunktion.data,
        ])
      ),
    [data, zuordnungDataFunktion.data, zuordnungDataProdukt.data]
  );

  const {
    columnsDefinition,
    customCells,
    gridActions,
    reorderRows,
    isGridLoading,
    mutationDeleteEintrag,
  } = useEintraege(isFetching, dataItemKey, dbName);

  const drafts = useEintraegeDrafts(dbName);

  const { isSettingEintragFirstMount, selection, rowIndexForInitialScrollTop } =
    useSetCurrentEintrag({
      db: dbName,
      contId,
      plID,
      itemId,
      eintraege,
    });

  const shouldDisplayLoader =
    isGridLoading ||
    isFetching ||
    isLoading ||
    !eintraege ||
    isSettingEintragFirstMount;

  if (isError) return <div>{error.message}</div>;

  return (
    <DefaultGrid
      data={eintraege ?? []}
      rowIndexForInitialScrollTop={rowIndexForInitialScrollTop}
      selection={selection}
      groupable={false}
      sortable={false}
      isLoading={shouldDisplayLoader}
      columnsDefinition={columnsDefinition}
      customCells={customCells}
      persistedDataStateId={{
        sector: "pl",
        unique: `pl-${dbName}-${contId}-${plID}`,
      }}
      dataItemKey={dataItemKey}
      gridActions={gridActions}
      reorderRowsConfig={{ reorderRows, dragHintKey: "BEZ" }}
      mobileGrid={{
        rowHeight: 60,
        renderRow: (
          rowRef,
          item,
          filterValue,
          isSelected,
          setDataItemSelected
        ) => (
          <EintraegeCard
            rowRef={rowRef}
            item={item as EintragFrontend}
            filterValue={filterValue}
            isSelected={isSelected}
            setDataItemSelected={setDataItemSelected}
            hasDraft={hasDraft(drafts, item[dataItemKey])}
          />
        ),
      }}
      deleteItemsMutation={mutationDeleteEintrag}
      generateQuickInfoForOneItem={(item: EintragFrontend) => {
        return Promise.resolve([
          { title: item.BEZ, content: "ToDo for Ticket ABK9-812" },
        ]);
      }}
    />
  );
};

export default PLEintraegeGrid;
