import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import ABKControlledExpansionPanel from "src/abk-shared/components/atoms/ABKControlledExpansionPanel";
import ExpansionPanelTitle from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/ExpansionPanelTitle";
import ABKFormDropdown from "src/abk-shared/components/atoms/ABKFormDropdown";
import ABKFormFieldInput from "src/abk-shared/components/atoms/ABKFormFieldInput";
import ABKFormRow from "src/abk-shared/components/atoms/ABKFormRow";
import ABKForm from "src/abk-shared/components/molecules/ABKForm";
import { EintragFrontend } from "src/modules/Datei/interfaces/parameterlisten.types";
import { PropsEintragPanel } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ParTypePanelsMap";
import { parTypeDisplayedList } from "src/modules/Datei/pages/Parameterlisten/Eintraege/maps";

export default function AllgemeinPanel({
  setPageState,
  pageState,
  sections,
  updateSections,
}: PropsEintragPanel) {
  const bezeichnungText = pageState?.VARNAME
    ? `${pageState?.BEZ || ""} [${pageState?.VARNAME}]`
    : pageState?.BEZ || "";
  const previewText = `Bezeichnung: ${bezeichnungText}; Kategorie: ${pageState?.ITEMTYPE || ""}`;

  const isExpanded = sections?.get("allgemein") || false;

  const handlePanelToggle = () => {
    if (updateSections && sections) {
      const newSections = new Map(sections);
      newSections.set("allgemein", !isExpanded);
      updateSections(newSections);
    }
  };
  return (
    <ABKControlledExpansionPanel
      expanded={isExpanded}
      onAction={handlePanelToggle}
      title={
        <ExpansionPanelTitle
          title="Allgemein"
          previewText={previewText}
          shouldShowPreview={!isExpanded}
        />
      }
    >
      <ABKForm
        sections={[
          {
            formElements: [
              <ABKFormRow>
                <ABKFormDropdown
                  label="Kategorie"
                  editorId="PARTYP"
                  value={pageState?.ITEMTYPE}
                  data={parTypeDisplayedList}
                  backendKey="ITEMID"
                  onChange={(event: DropDownListChangeEvent) =>
                    setPageState({
                      ...pageState,
                      ITEMTYPE: event.value,
                    } as EintragFrontend)
                  }
                />
                <ABKFormFieldInput
                  label="Bezeichnung"
                  editorId="BEZ"
                  value={pageState?.BEZ}
                  setValue={(value: string) =>
                    setPageState({
                      ...pageState,
                      BEZ: value,
                    } as EintragFrontend)
                  }
                  backendKey="BEZ"
                />
              </ABKFormRow>,
              <ABKFormRow isHalfRow>
                <ABKFormFieldInput
                  label="Parameterkennung"
                  editorId="VARNAME"
                  value={pageState?.VARNAME}
                  setValue={(value: string) => {
                    setPageState({
                      ...pageState,
                      VARNAME: value,
                    } as EintragFrontend);
                  }}
                  backendKey="VARNAME"
                />
              </ABKFormRow>,
            ],
          },
        ]}
      />
    </ABKControlledExpansionPanel>
  );
}
