import { mapTree } from "@progress/kendo-react-treelist";
import { SelectedState } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import {
  DATA_ITEM_KEY,
  PARENT_FIELD,
  SUB_ITEMS_FIELD,
} from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/constants";
import idGetter from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/hooks/idGetter";

export default function computeMinimalSelection(
  selectedState: SelectedState,
  dataTree: any[]
) {
  const minimalSelection: string[] = [];
  const selectedSubItemsMappedToParentId = new Map<string, string[]>();

  /*
    `mapTree` iterates over the sub-items, then on the parents of these sub-items
    The code below is based on the fact that the selected sub-items are discovered
    before the parent is discovered.
  */
  mapTree(dataTree, SUB_ITEMS_FIELD, (item) => {
    const isSelected = selectedState[idGetter(item)] as boolean | undefined;
    const itemId = item[DATA_ITEM_KEY];

    const isParent = item[SUB_ITEMS_FIELD] && item[SUB_ITEMS_FIELD].length > 0;
    const isChild = !!item[PARENT_FIELD];

    if (!isParent) {
      if (!isSelected) return;

      if (!isChild) {
        minimalSelection.push(itemId);
        return;
      }

      setNewSelectedSubItem(item, itemId, selectedSubItemsMappedToParentId);
      return;
    }

    const subItemsSelected = selectedSubItemsMappedToParentId.get(itemId) ?? [];
    const allSubItemsAreSelected =
      subItemsSelected.length === item[SUB_ITEMS_FIELD].length;

    if (allSubItemsAreSelected) {
      if (!isChild) {
        minimalSelection.push(itemId);
        return;
      }

      setNewSelectedSubItem(item, itemId, selectedSubItemsMappedToParentId);
      return;
    }

    minimalSelection.push(...subItemsSelected);
    return;
  });

  return minimalSelection.map((item) => ({ ItemID: item }));
}

function setNewSelectedSubItem(
  item: any,
  itemId: string,
  selectedSubItemsMappedToParentId: Map<string, string[]>
) {
  const selectedSubItemsForParentId =
    selectedSubItemsMappedToParentId.get(item[PARENT_FIELD]) ?? [];
  selectedSubItemsForParentId.push(itemId);

  selectedSubItemsMappedToParentId.set(
    item[PARENT_FIELD],
    selectedSubItemsForParentId
  );
}
