import _ from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import { ABKEigenschaftenfensterWithDrafts } from "src/abk-shared/components/organisms/ABKEigenschaftenfenster/ABKEigenschaftenfensterWithDrafts";
import { Versioned } from "src/abk-shared/interfaces/Versioned";
import { dbDrafts, EintragDraft } from "src/core/db/dbDrafts";
import { useIsValidDbAndContIdInURL } from "src/core/utility/useIsValidDB";
import { useRemoveParam } from "src/core/utility/useRemoveParam";
import { EintragFrontend } from "src/modules/Datei/interfaces/parameterlisten.types";
import { EigenschaftenfensterPanels } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/EigenschaftenfensterPanels";
import { useGetSingleEintrag } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/useGetSingleEintrag";
import useUpdateEintrag from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/useUpdateEintrag";
import { useEintraegeStore } from "src/modules/Datei/pages/Parameterlisten/Eintraege/useEintraegeStore";
import ValidationErrorStoreProvider from "../../../../../../abk-shared/stores/ValidationErrorStoreProvider";
import useIsMobileTemplate from "../../../../../../abk-shared/utility/useIsMobileTemplate";

export const WrapperEintragBearbeitenDialog = () => (
  <ValidationErrorStoreProvider>
    <EintragBearbeitenDialog />
  </ValidationErrorStoreProvider>
);

const EintragBearbeitenDialog = () => {
  const { isValid } = useIsValidDbAndContIdInURL();
  const { plID = "", itemId = "" } = useParams();
  const { currentEintrag, setOpenDialog, setCurrentEintrag } =
    useEintraegeStore();
  const isMobile = useIsMobileTemplate();
  const browserWindowHeight = isMobile
    ? window.innerHeight
    : window.innerHeight * 0.7;
  const saveEintragFunctionRef = React.useRef<null | (() => Promise<void>)>(
    null
  );
  const { removeParam } = useRemoveParam();

  const { data: eintragStateInBackend, refetch } = useGetSingleEintrag(
    currentEintrag?.DBNAME,
    currentEintrag?.CONTID,
    currentEintrag?.DATAID,
    currentEintrag?.ITEMID
  );
  const updateEintragMutation = useUpdateEintrag();

  const draftOptions = {
    is404: false,
    isFetching: false,
    initialPageState: eintragStateInBackend,
    initialSections: new Map([
      ["allgemein", true],
      ["zahl", false],
      ["rechenansatz", false],
      ["awlist", false],
      ["kommentar", false],
      ["zuordnungFunktion", false],
      ["zuordnungProduktgruppe", false],
      ["piktogramm", false],
      ["erweitert", false],
    ]),
    backend: {
      doSave: async (eintrag: Versioned<EintragFrontend>) =>
        updateEintragMutation.mutateAsync({
          itemId: eintrag?.value?.ITEMID || "",
          dbName: eintrag?.value?.DBNAME || "",
          contId: eintrag?.value?.CONTID || "",
          dataId: eintrag?.value?.DATAID || "",
          eintrag: eintrag,
        }),
      doRefetch: refetch,
    },
    drafts: {
      key: (eintrag: EintragFrontend): [string, string, string] => [
        eintrag.CONTID,
        eintrag.DATAID,
        eintrag.ITEMID,
      ],
      new: (
        data: Versioned<EintragFrontend>,
        sections: Map<string, boolean>
      ): EintragDraft => ({
        database: data.value.DBNAME,
        contId: data.value.CONTID,
        dataId: data.value.DATAID,
        itemId: data.value.ITEMID,
        etag: data.etag,
        edited: _.cloneDeep(data.value),
        sections: sections,
      }),
      table: dbDrafts.eintraegeDrafts,
    },
  };
  if (!isValid || !plID) return <></>;

  return (
    <ABKEigenschaftenfensterWithDrafts
      initialHeight={browserWindowHeight}
      title="Eintrag bearbeiten"
      draftsProps={draftOptions}
      fensterKey={`${currentEintrag?.CONTID}-${currentEintrag?.DATAID}-${currentEintrag?.ITEMID}`}
      onClose={async () => {
        void saveEintragFunctionRef.current?.();
        saveEintragFunctionRef.current = null;
        setOpenDialog(false);
        setCurrentEintrag(null);
        removeParam(itemId);
      }}
      renderChildren={(draftValues) => (
        <EigenschaftenfensterPanels
          currentEintrag={currentEintrag}
          saveEintragFunctionRef={saveEintragFunctionRef}
          pageState={draftValues.pageState}
          updatePageState={draftValues.updatePageState}
          sections={draftValues.sections}
          updateSections={draftValues.updateSections}
          handleSave={draftValues.handleSave}
          handleReset={draftValues.handleReset}
          conflictMessage={draftValues.conflictMessage || ""}
          isEdited={draftValues.isEdited}
        />
      )}
    />
  );
};

export default EintragBearbeitenDialog;
