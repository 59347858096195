import {
  mapTree,
  TreeListExpandChangeEvent,
} from "@progress/kendo-react-treelist";
import React from "react";
import { SelectedState } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import {
  DATA_ITEM_KEY,
  SUB_ITEMS_FIELD,
} from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/constants";
import idGetter from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/hooks/idGetter";
import extendData from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/hooks/useZuordnungTreeData/extendData";

export type ZuordnungTreeData = ReturnType<typeof useZuordnungTreeData>;

type ExpandedState = Record<string, boolean>;

export default function useZuordnungTreeData(
  dataTree: any[],
  selectedState: SelectedState
) {
  const [expandedState, setExpandedState] = React.useState<ExpandedState>({});

  const onExpandChange = React.useCallback(
    (e: TreeListExpandChangeEvent) => {
      setExpandedState({ ...expandedState, [idGetter(e.dataItem)]: !e.value });
    },
    [expandedState]
  );

  const expandAll = React.useCallback(() => {
    const newExpandedState: ExpandedState = {};

    mapTree(dataTree, SUB_ITEMS_FIELD, (item) => {
      const itemId = item[DATA_ITEM_KEY];

      const isParent =
        item[SUB_ITEMS_FIELD] && item[SUB_ITEMS_FIELD].length > 0;

      if (isParent) newExpandedState[itemId] = true;
    });
    setExpandedState(newExpandedState);
  }, [dataTree]);

  const collapseAll = React.useCallback(() => {
    setExpandedState({});
  }, []);

  const extendedData = extendData(dataTree, selectedState, expandedState);

  return {
    onExpandChange,
    expandedState,
    expandAll,
    collapseAll,
    extendedData,
  };
}
