import React, { CSSProperties } from "react";
import { FixedSizeList } from "react-window";
import { MobileGridProps } from "src/abk-shared/components/organisms/ABKBasisGrid/components/VirtualizedMobileGridRows/VirtualizedMobileGridRows";
import { FIELD_SELECTED } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridColumns/gridActionColumns";
import { FunctionSetDataItemSelected } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import {
  getScrollTopLocalStorage,
  setScrollTopLocalStorage,
} from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useSetInitialScrollTop/scrollTopLocalStorage";
import { ungroupDataItems } from "src/abk-shared/components/organisms/ABKBasisGrid/utils/groupingFunctions";
import { GenericObject } from "src/abk-shared/interfaces/GenericObject";

type Props = {
  dataItems: GenericObject[];
  filterValue: string;
  setDataItemSelected: FunctionSetDataItemSelected;
  height: number;
  width: number;
  mobileGridProps: MobileGridProps;
  gridUniqueId: string;
  rowIndexForInitialScrollTop: number | undefined;
};
export function WrapperFixedSizeList({
  dataItems,
  filterValue,
  setDataItemSelected,
  height,
  width,
  mobileGridProps,
  gridUniqueId,
  rowIndexForInitialScrollTop,
}: Props) {
  const [gridDimensions, setGridDimensions] = React.useState({
    width: 0,
    height: 0,
  });
  if (gridDimensions.height === 0 && height !== 0)
    setGridDimensions({ height, width });

  const listRef = React.useRef<FixedSizeList>(null);
  const mobileScrollContainerRef = React.useRef<HTMLDivElement>(null);
  const flattenedDataItems = React.useMemo(
    () => ungroupDataItems(dataItems),
    [dataItems]
  );

  const Row = ({ index, style }: { index: number; style: CSSProperties }) => {
    const rowRef = React.useRef<HTMLDivElement>(null);

    return (
      <div
        style={{
          ...style,
          borderBottom:
            index === flattenedDataItems.length - 1
              ? "1px solid black"
              : undefined, // so wird nur bei der letzten Zeile ein border-bottom hinzugefügt.
        }}
        ref={rowRef}
      >
        {mobileGridProps.renderRow(
          rowRef,
          flattenedDataItems[index],
          filterValue,
          flattenedDataItems[index][FIELD_SELECTED],
          setDataItemSelected
        )}
      </div>
    );
  };

  React.useEffect(() => {
    if (rowIndexForInitialScrollTop == null || !listRef.current) return;

    listRef.current.scrollToItem(rowIndexForInitialScrollTop);
  }, [rowIndexForInitialScrollTop]);

  return (
    <FixedSizeList
      ref={listRef}
      outerRef={mobileScrollContainerRef}
      height={height}
      width={width}
      itemCount={flattenedDataItems.length}
      initialScrollOffset={
        rowIndexForInitialScrollTop != null
          ? undefined
          : getScrollTopLocalStorage(gridUniqueId, true)
      }
      itemSize={mobileGridProps.rowHeight}
      onScroll={() => {
        const element = mobileScrollContainerRef.current;
        const numberOfShownItems =
          gridDimensions.height / (mobileGridProps?.rowHeight || 1);
        if (!element) return;
        // Only scroll back to last element IF there are enough items to scroll
        // (this is a Bugfix for https://ib-data.atlassian.net/browse/ABK9-975)
        if (numberOfShownItems === 0) return;
        if (flattenedDataItems.length < numberOfShownItems) return;
        setScrollTopLocalStorage(element, gridUniqueId, true);
      }}
    >
      {Row}
    </FixedSizeList>
  );
}
