import { useParams } from "react-router-dom";
import useGetJobsOfContainer from "src/core/queries/jobs/useGetJobsOfContainer";
import useGetTemplateItem from "src/modules/Datei/pages/Parameterlisten/Eintraege/Dialoge/useGetTemplateItem";
import useGetPiktogramme from "src/modules/Datei/pages/Parameterlisten/Piktogramme/useGetPiktogramme";
import useGetAllContainerParameterlisten from "src/modules/Datei/queries/useGetAllContainerParameterlisten";
import { useGetGliederungssysteme } from "src/modules/Datei/queries/useGetGliederungssysteme";
import useGetSingleGliederungssystem from "src/modules/Datei/queries/useGetSingleGliederungssystem";
import useGetSingleParameterliste from "src/modules/Datei/queries/useGetSingleParameterliste";
import useGetContInfo from "src/modules/Startseite/hooks/useGetContInfo";

export const usePrefetchDateiAll = () => {
  const { db = "", contId = "", plID = "" } = useParams();
  const ignoreError = true;

  useGetContInfo(db, contId, ignoreError);
  useGetGliederungssysteme(db, contId, ignoreError);
  useGetAllContainerParameterlisten(db, contId, ignoreError);
  useGetJobsOfContainer(db, contId, ignoreError);

  useGetSingleParameterliste(db, contId, plID, ignoreError);
  useGetSingleGliederungssystem(db, contId, plID, ignoreError);
  useGetPiktogramme(db, contId, plID, ignoreError);
  useGetTemplateItem(db, contId, plID, ignoreError);
};
