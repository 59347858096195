import React from "react";
import { Data_Datatype_GS } from "src/abk-shared/interfaces/abk9SvgIcons";
import BreadcrumbDropDown from "src/core/components/molecules/ABKBreadcrumbs/ABKBreadcrumbLink/BreadcrumbDropDown";
import useLastBreadcrumb from "src/core/components/molecules/ABKBreadcrumbs/ABKBreadcrumbLink/useLastBreadcrumb";
import { Gliederungssystem } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import { Parameterliste } from "src/modules/Datei/interfaces/parameterlisten.types";
import { createCatalogItemURL } from "src/modules/Datei/pages/components/CatalogItemsWrapper/createCatalogItemURL";
import createNavigationItem from "src/Router/Layout/navigationItems/createNavigationItem";

export function useSetLastBreadcrumbForDataType(
  dataId: string,
  items: Gliederungssystem[] | Parameterliste[] | undefined
) {
  const { setLastBreadcrumb } = useLastBreadcrumb();

  React.useEffect(() => {
    if (!items || items.length === 0) return;

    const dropDownItems = items.map((item) =>
      createNavigationItem(
        item.DATAID,
        item.Kenndaten.KENNUNG,
        item.Kenndaten.KENNUNG,
        Data_Datatype_GS,
        createCatalogItemURL("gs", item.DATAID, item.DBNAME, item.CONTID),
        false,
        ""
      )
    );

    setLastBreadcrumb(
      <BreadcrumbDropDown
        navigationItems={dropDownItems}
        currentItemId={dataId}
        keyForItemId="id"
      />
    );
  }, [items, setLastBreadcrumb, dataId]);
}
