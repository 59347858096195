import { Button } from "@progress/kendo-react-buttons";
import { Link, NavigateFunction } from "react-router-dom";
import { INotification } from "src/abk-shared/components/organisms/ABKSideBar/NotificationBox";
import { formatDateTimeToBrowserDefault } from "src/abk-shared/utility/dateUtils";
import { DraftNotificationMessage } from "src/core/components/templates/useDraftNotifications/DraftNotificationMessage";
import { ContainerInfoDraft } from "src/core/db/dbDrafts";
import { getFileInfoUrlForContainer } from "src/core/utility/cont";

export function generateDraftNotificationForFileInfo(
  draft: ContainerInfoDraft,
  navigate: NavigateFunction
) {
  const url = getFileInfoUrlForContainer(draft.database, draft.contId);
  return {
    message: (
      <DraftNotificationMessage>
        <>
          Ungespeicherte Eingabe in Ordner Datei-Info (
          <Link to={getFileInfoUrlForContainer(draft.database, draft.contId)}>
            {draft.edited.BEZ}
          </Link>
          )
        </>
      </DraftNotificationMessage>
    ),
    type: "info",
    quickInfo: [
      {
        title: "Entwurf",
        content:
          "Ungespeicherte Eingabe in Ordner Datei-Info (" +
          draft.edited.BEZ +
          ")",
      },
      {
        title: "Letzte Änderung",
        content: formatDateTimeToBrowserDefault(
          new Date(draft.edited.LASTCHANGED ?? 0)
        ),
      },
      { title: "Type", content: "INFO" },
    ],
    notificationButton: (
      <Button
        themeColor="primary"
        onClick={() => {
          navigate(url);
        }}
      >
        Öffnen
      </Button>
    ),
    closable: false,
  } as INotification;
}
