import { AxiosError } from "axios";
import { produce } from "immer";
import _ from "lodash";
import { ABKLoader } from "src/abk-shared/components/atoms/ABKLoader/ABKLoader";
import { Kenndaten } from "src/modules/Datei/interfaces/kenndaten.types";
import { KenndatenPanels } from "src/modules/Datei/pages/components/KenndatenPanels/KenndatenPanels";
import useDrafts from "../../../../../abk-shared/hooks/useDrafts";
import { Versioned } from "../../../../../abk-shared/interfaces/Versioned";
import { ParameterlisteDraft, dbDrafts } from "../../../../../core/db/dbDrafts";
import { Parameterliste } from "../../../interfaces/parameterlisten.types";
import useGetSingleParameterliste from "../../../queries/useGetSingleParameterliste";
import useSaveParameterliste from "../../../queries/useSaveParameterliste";
import PageTemplate from "../../PageTemplate";

type Props = { dbName: string; contId: string; plID: string };
export const ParameterlisteKenndaten = ({ dbName, contId, plID }: Props) => {
  const {
    data: parameterliste,
    isLoading,
    isFetching,
    error: parameterlisteLoadingError,
    refetch,
  } = useGetSingleParameterliste(dbName, contId, plID);

  const { mutateAsync } = useSaveParameterliste();

  const is404 =
    parameterlisteLoadingError instanceof AxiosError &&
    parameterlisteLoadingError.status === 404;

  // -----------------------------------------------------------------------------------
  // @Michael, wenn du das siehst, bitte folgendes bild im browser öffnen:
  // https://i.pinimg.com/originals/52/ee/77/52ee7751e0fe6df4fdb7513a1b16102f.jpg
  // -----------------------------------------------------------------------------------

  const {
    isEdited,
    handleReset,
    handleSave,
    pageState,
    updatePageState,
    sections,
    updateSections,
    conflictMessage,
  } = useDrafts<Parameterliste, ParameterlisteDraft, [string, string]>({
    is404,
    isFetching,
    initialPageState: parameterliste,
    initialSections: new Map([
      ["allgemein", true],
      ["herausgeber", false],
      ["beschreibung", false],
      ["zuordnungen", false],
    ]),
    backend: {
      doSave: async (parameterliste: Versioned<Parameterliste>) =>
        mutateAsync({
          db: dbName,
          contId,
          parameterliste,
        }),
      doRefetch: refetch,
    },
    drafts: {
      key: (pl: Parameterliste) => [pl.CONTID, pl.DATAID],
      new: (
        data: Versioned<Parameterliste>,
        sections: Map<string, boolean>
      ): ParameterlisteDraft => ({
        database: dbName,
        contId: data.value.CONTID,
        dataId: data.value.DATAID,
        etag: data.etag,
        edited: _.cloneDeep(data.value),
        sections: sections,
      }),
      table: dbDrafts.parameterListeDrafts,
    },
  });

  if (isLoading || !pageState) return <ABKLoader />;

  const setKenndaten = (kenndaten: Kenndaten) => {
    const newPageStateValue = produce(pageState.value, (draft) => {
      draft.Kenndaten = kenndaten;
    });
    return updatePageState(newPageStateValue);
  };

  /*
    `readOnly` ist hier absichtlich als `false` definiert.
    Vorher war hier die folgende Zeile:
    `const readOnly = pageState.value.Kenndaten.STATUS === ParameterlisteStatus.Freigegeben;`
    Der Grund ist, wir wollen dieses Feature mit `readOnly` für jetzt ausschalten.
    Dieses Feature wird später implementiert werden. Aber jetzt wollen wir nicht
    nur in einer Stelle es implementieren. Daher haben wir es ausgeschaltet.
    Mehr dazu hier: https://ib-data.atlassian.net/browse/ABK9-970
  */
  const readOnly = false;

  return (
    <PageTemplate
      db={dbName}
      contId={contId}
      balkenProps={{
        edited: is404 || isEdited,
        onSave: handleSave,
        onReset: handleReset,
        conflictMessage,
      }}
    >
      <KenndatenPanels
        dbName={dbName}
        contId={contId}
        kenndaten={pageState.value.Kenndaten}
        setKenndaten={setKenndaten}
        readOnly={readOnly}
        sections={sections}
        updateSections={updateSections}
      />
    </PageTemplate>
  );
};
