import { SvgIcon } from "@progress/kendo-react-common";
import { SortableItemUIProps } from "@progress/kendo-react-sortable";
import ABKNumericInput from "src/abk-shared/components/atoms/ABKNumericInput";
import { General_DragDrop } from "src/abk-shared/interfaces/abk9SvgIcons";
import { DisplayItem } from "..";
import ABKFormFieldInput from "../../../atoms/ABKFormFieldInput";
import "../styles.scss";
import useListItem from "../useListItem";
import ListItemButtons from "./ListItemButtons";

interface ListItemProps extends SortableItemUIProps {
  objectKeys: string[];
  setData: (data: any[]) => void;
  data: any[];
  isEditMode?: boolean;
  dataToDisplay: DisplayItem[];
  noDnd?: boolean;
}

const ListItem: React.FC<ListItemProps> = ({
  dataItem,
  isActive,
  style,
  forwardRef,
  attributes,
  setData,
  data,
  dataToDisplay,
  isEditMode,
  noDnd,
}) => {
  const { handleDelete } = useListItem(dataItem, data, setData);

  return (
    <div
      ref={forwardRef}
      {...attributes}
      className="list-item"
      data-testid="list-item"
      style={{
        border: isActive ? "1px solid #B5B5B5" : "1px solid #F5F5F5",
        ...style,
      }}
    >
      {!noDnd && (
        <SvgIcon
          style={{ cursor: isEditMode ? "not-allowed" : "move" }}
          data-testid="drag-and-drop-icon"
          icon={General_DragDrop}
          width={16}
          color={isEditMode ? "lightgray" : undefined}
        />
      )}
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          gap: 8,
        }}
      >
        {dataToDisplay.map((item) => {
          const isReadOnly = Boolean(!isEditMode || noDnd);
          const setValue = (value: any) => {
            setData(
              data.map((item_) =>
                item_.id === dataItem.id
                  ? { ...item_, [item.key]: value }
                  : item_
              )
            );
          };
          if (item.type === "number") {
            return (
              <>
                <ABKNumericInput
                  key={item.key}
                  data-testid={`list-item-input-${item.key}`}
                  editorId={item.key}
                  value={
                    !dataItem[item.key] && !isEditMode ? 0 : dataItem[item.key]
                  }
                  readOnly={isReadOnly}
                  setValue={isReadOnly ? undefined : setValue}
                  customFormat={{
                    maximumFractionDigits: item.numberOfFractionDigits,
                    minimumFractionDigits: item.numberOfFractionDigits,
                    useGrouping: true,
                    style: "decimal",
                  }}
                />
              </>
            );
          }
          if (item.type === "text") {
            return (
              <ABKFormFieldInput
                data-testid={`list-item-input-${item.key}`}
                key={item.key}
                type="text"
                editorId={item.key}
                placeHolder="(leer)"
                value={
                  !dataItem[item.key] && !isEditMode
                    ? "leer"
                    : dataItem[item.key]
                }
                setValue={isReadOnly ? undefined : setValue}
              />
            );
          }
          // eslint-disable-next-line no-console
          console.error(`ListItem type ${item.type} is not implemented!`, {
            item,
          });
          return null;
        })}
      </div>

      <ListItemButtons handleDelete={handleDelete} dataItem={dataItem} />
    </div>
  );
};

export default ListItem;
