import {
  KenndatenStatus,
  KenndatenStatusFrontend,
} from "src/modules/Datei/interfaces/kenndaten.types";

// Mapper für Frontend nach Backend
export const statusMapper = new Map<KenndatenStatus, KenndatenStatusFrontend>([
  [KenndatenStatus.Entwurf, KenndatenStatusFrontend.Entwurf],
  [KenndatenStatus.Freigegeben, KenndatenStatusFrontend.Freigegeben],
]);
