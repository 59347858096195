import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { RouterProvider } from "react-router";
import { isMobile } from "src/abk-shared/utility/detectDevice";
import ABKError404NotificationButton from "./core/components/atoms/ABKError404NotificationButton";
import { BACKEND_URL } from "./core/constants/urls";
import useNotificationStore, {
  ErrorNotification,
} from "./core/stores/useNotificationStore";
import { getMessageFromError, isError404 } from "./core/utility/handleError";
import { Router } from "./Router/Router";

const AppLayout = () => {
  const { addError } = useNotificationStore();
  const [requireAuthentication, setRequireAuthentication] =
    useState<boolean>(false);

  useEffect(() => {
    if (requireAuthentication) {
      let currentUrl = window.location.href;

      window.location.href =
        BACKEND_URL + "/api/login?successurl=" + currentUrl;
    }
  }, [requireAuthentication]);

  const onErrorHandler = (error: Error) => {
    if (error instanceof AxiosError && error.response) {
      const errorData = error.response.data;
      const message = getMessageFromError(errorData, error);
      const status = error.response.status;
      if (status === 401) {
        setRequireAuthentication(true);
      } else {
        const abkError = typeof errorData === "string" ? undefined : errorData;
        const errorToAdd: ErrorNotification = {
          message: message,
          type: "error",
          closable: true,
          ABKError: abkError,
          JSError: error,
        };
        if (isError404(errorData))
          errorToAdd.notificationButton = (
            <ABKError404NotificationButton error={errorData} />
          );
        addError(errorToAdd);
      }
    } else {
      addError({
        message: error.message,
        type: "error",
        closable: true,
        JSError: error,
      });
    }
  };

  /*
    Ohne dieses "React.useMemo", wird dieses Component nach jedem Fehler nochmal
    einen neuen QueryClient anlegen. Und der Cache vom vorherigen QueryClient
    wird gelöscht.
    Das behebe einen Bug, in dem wir eine Mutation ausführen sollen: der QueryCache
    wurde zurückgesetzt, und nach der Mutation wurde die useQuery nicht nochmal
    ausgeführt, und die neueste Daten auf der Seite nicht angezeigt.
  */
  const queryClient = React.useMemo(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error: Error) => {
            onErrorHandler(error);
          },
        }),
        mutationCache: new MutationCache({
          onError: (error: Error) => {
            onErrorHandler(error);
          },
        }),
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
          },
        },
      }),
    []
  );

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={Router} />
      {!isMobile() && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
};

export default AppLayout;
