import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  CatalogItem,
  CatalogType,
} from "src/modules/Datei/pages/components/CatalogItemsWrapper/CatalogItemsWrapper";
import { createCatalogItemURL } from "src/modules/Datei/pages/components/CatalogItemsWrapper/createCatalogItemURL";

export function useDuplicateCatalogItem(catalogType: CatalogType) {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (catalogItem: CatalogItem) => {
      // requestBody = BE request mit new bezeichnung
      return axios.post<CatalogItem>(
        `/api/db/${catalogItem.DBNAME}/cont/${catalogItem.CONTID}/${catalogType}`,
        null,
        {
          headers: { "Content-Type": "application/json" },
          params: {
            copyfromdataid: catalogItem.DATAID,
          },
        }
      );
    },
    onSuccess: (response) => {
      const newCatalogItem = response.data;
      const catalogItemURL = createCatalogItemURL(
        catalogType,
        newCatalogItem.DATAID,
        newCatalogItem.DBNAME,
        newCatalogItem.CONTID
      );
      navigate(catalogItemURL);
    },
  });
}
