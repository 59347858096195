import {
  CATALOG_TYPE_PLACEHOLDER,
  CONTID_PLACEHOLDER,
  DATA_ID_PLACEHOLDER,
  DATABASE_PLACEHOLDER,
  FILE_EINTRAG_ID,
  ITEM_ID_PLACEHOLDER,
} from "src/core/constants/urls";
import { CatalogType } from "src/modules/Datei/pages/components/CatalogItemsWrapper/CatalogItemsWrapper";

export const createEintragURL = (
  dbName: string,
  contId: string,
  catalogType: CatalogType,
  dataId: string,
  itemId: string
) => {
  const url = FILE_EINTRAG_ID.replace(DATABASE_PLACEHOLDER, dbName)
    .replace(CONTID_PLACEHOLDER, contId)
    .replace(CATALOG_TYPE_PLACEHOLDER, catalogType)
    .replace(DATA_ID_PLACEHOLDER, dataId)
    .replace(ITEM_ID_PLACEHOLDER, itemId);

  return url;
};
