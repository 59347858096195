import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Versioned } from "src/abk-shared/interfaces/Versioned";
import { EintragFrontend } from "src/modules/Datei/interfaces/parameterlisten.types";
import { backendEintragToFrontend } from "src/modules/Datei/pages/Parameterlisten/Eintraege/convertBackendEintrag";
import { formatWithEtag } from "src/modules/Datei/queries/formatWithEtag";

export const QUERY_KEY_GET_SINGLE_EINTRAG = "QUERY_KEY_GET_SINGLE_EINTRAG";

export function useGetSingleEintrag(
  dbName: string | undefined,
  contId: string | undefined,
  plID: string | undefined,
  itemId: string | undefined
) {
  return useQuery({
    queryKey: [QUERY_KEY_GET_SINGLE_EINTRAG, dbName, contId, plID, itemId],
    queryFn: async () => {
      const response = await axios.get(
        `/api/db/${dbName}/cont/${contId}/pl/${plID}/items/${itemId}`
      );
      const frontendEintrag = backendEintragToFrontend(response.data);
      return formatWithEtag({
        ...response,
        data: frontendEintrag,
      }) as Versioned<EintragFrontend>;
    },
    enabled: !!dbName && !!contId && !!plID && !!itemId,
  });
}
