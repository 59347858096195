import AllgemeinPanelOhneKennung from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/AllgemeinPanelOhneKennung";
import { ErweitertPanel } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ErweitertPanel";
import PiktogrammPanel from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/PiktogrammPanel";
import ZuordnungPanel from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel";
import { EintragFrontend } from "../../../../../interfaces/parameterlisten.types";
import { ItemTypeDisplayed } from "../../maps";
import AllgemeinPanel from "./AllgemeinPanel";
import AuswahllistePanel from "./AuswahllistePanel";
import KommentarPanel from "./KommentarPanel";
import RechenansatzPanel from "./Rechenansatz";
import ZahlPanel from "./ZahlPanel";

export type PropsEintragPanel = {
  pageState: EintragFrontend;
  setPageState: (eintrag: EintragFrontend) => void;
  sections?: Map<string, boolean>;
  updateSections?: (newSections: Map<string, boolean>) => void;
};

const createAllgemeinPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void,
  sections: Map<string, boolean>,
  updateSections: (newSections: Map<string, boolean>) => void
) => (
  <AllgemeinPanel
    pageState={pageState}
    setPageState={setPageState}
    sections={sections}
    updateSections={updateSections}
  />
);

const createAllgemeinPanelOhneKennung = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void,
  sections: Map<string, boolean>,
  updateSections: (newSections: Map<string, boolean>) => void
) => (
  <AllgemeinPanelOhneKennung
    pageState={pageState}
    setPageState={setPageState}
    sections={sections}
    updateSections={updateSections}
  />
);

const createZahlPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void,
  sections: Map<string, boolean>,
  updateSections: (newSections: Map<string, boolean>) => void
) => (
  <ZahlPanel
    pageState={pageState}
    setPageState={setPageState}
    sections={sections}
    updateSections={updateSections}
  />
);

const createRechenansatzPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void,
  sections: Map<string, boolean>,
  updateSections: (newSections: Map<string, boolean>) => void
) => (
  <RechenansatzPanel
    pageState={pageState}
    setPageState={setPageState}
    sections={sections}
    updateSections={updateSections}
  />
);

const createAuswahllistePanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void,
  sections: Map<string, boolean>,
  updateSections: (newSections: Map<string, boolean>) => void
) => (
  <AuswahllistePanel
    pageState={pageState}
    setPageState={setPageState}
    sections={sections}
    updateSections={updateSections}
  />
);

const createKommentarPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void,
  sections: Map<string, boolean>,
  updateSections: (newSections: Map<string, boolean>) => void
) => (
  <KommentarPanel
    pageState={pageState}
    setPageState={setPageState}
    sections={sections}
    updateSections={updateSections}
  />
);

const createZuordnungFunktionenPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void,
  sections: Map<string, boolean>,
  updateSections: (newSections: Map<string, boolean>) => void
) => (
  <ZuordnungPanel
    zuordnungType="Funktion"
    pageState={pageState}
    setPageState={setPageState}
    sections={sections}
    updateSections={updateSections}
  />
);
const createZuordnungProduktgruppenPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void,
  sections: Map<string, boolean>,
  updateSections: (newSections: Map<string, boolean>) => void
) => (
  <ZuordnungPanel
    zuordnungType="Produkt"
    pageState={pageState}
    setPageState={setPageState}
    sections={sections}
    updateSections={updateSections}
  />
);

const createPiktogrammPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void,
  sections: Map<string, boolean>,
  updateSections: (newSections: Map<string, boolean>) => void
) => {
  return (
    <PiktogrammPanel
      pageState={pageState}
      setPageState={setPageState}
      sections={sections}
      updateSections={updateSections}
    />
  );
};

const createErweitertPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void,
  sections: Map<string, boolean>,
  updateSections: (newSections: Map<string, boolean>) => void
) => {
  return (
    <ErweitertPanel
      pageState={pageState}
      setPageState={setPageState}
      sections={sections}
      updateSections={updateSections}
    />
  );
};

export const parTypePanelsMap: Map<ItemTypeDisplayed, Function[]> = new Map([
  ["Überschrift", [createAllgemeinPanelOhneKennung, createKommentarPanel]],
  [
    "Parameterset",
    [
      createAllgemeinPanelOhneKennung,
      createKommentarPanel,
      createZuordnungFunktionenPanel,
      createZuordnungProduktgruppenPanel,
    ],
  ],
  [
    "Zahl",
    [
      createAllgemeinPanel,
      createZahlPanel,
      createRechenansatzPanel,
      createAuswahllistePanel,
      createKommentarPanel,
      createPiktogrammPanel,
      createErweitertPanel,
    ],
  ],
  [
    "Text (einzeilig)",
    [
      createAllgemeinPanel,
      createAuswahllistePanel,
      createKommentarPanel,
      createPiktogrammPanel,
      createErweitertPanel,
    ],
  ],
  [
    "Text (mehrzeilig)",
    [
      createAllgemeinPanel,
      createKommentarPanel,
      createPiktogrammPanel,
      createErweitertPanel,
    ],
  ],
  [
    "Datum",
    [
      createAllgemeinPanel,
      createKommentarPanel,
      createPiktogrammPanel,
      createErweitertPanel,
    ],
  ],
  [
    "Ja/Nein",
    [
      createAllgemeinPanel,
      createKommentarPanel,
      createPiktogrammPanel,
      createErweitertPanel,
    ],
  ],
]);
