import { Loader } from "@progress/kendo-react-indicators";

type Props = {
  isLoading?: boolean;
  hasImage: boolean;
  imageBase64: string;
  errorMessage: string;
  ["data-testid"]?: string;
};

export default function LogoDisplayer({
  isLoading,
  hasImage,
  imageBase64,
  errorMessage,
  ["data-testid"]: dataTestId,
}: Props) {
  if (isLoading) return <Loader />;

  if (hasImage)
    return (
      <img
        src={imageBase64}
        alt="Hochgeladenes Logo"
        className="logo-img"
        data-testid={dataTestId}
      />
    );

  if (errorMessage) return <>{errorMessage}</>;

  return <>Kein Logo vorhanden</>;
}
