import _ from "lodash";
import { useLayoutEffect, useState } from "react";
import ABKConfirmationDialog from "src/abk-shared/components/molecules/ABKConfirmationDialog";
import ABKSaveBalken from "src/abk-shared/components/molecules/ABKSaveBalken";
import ABKEigenschaftenfenster from "src/abk-shared/components/organisms/ABKEigenschaftenfenster";
import ValidationErrorStoreProvider, {
  useValidationError,
} from "src/abk-shared/stores/ValidationErrorStoreProvider";
import useIsMobileTemplate from "src/abk-shared/utility/useIsMobileTemplate";
import { PiktogrammItem } from "../piktogramm.types";
import { useUpdatePiktogramm } from "../useUpdatePiktogramm";
import PiktogrammDetailsPanel from "./PiktogrammDetailsPanel";

export const WrapperUpdatePiktogrammDialog = (props: Props) => (
  <ValidationErrorStoreProvider>
    <UpdatePiktogrammDialog {...props} />
  </ValidationErrorStoreProvider>
);

type Props = {
  db: string;
  contId: string;
  plID: string;
  selectedPiktogramm: PiktogrammItem;
  setSelectedPiktogramm: React.Dispatch<React.SetStateAction<PiktogrammItem>>;
  closeDialog: () => void;
};

export const UpdatePiktogrammDialog = ({
  db,
  contId,
  plID,
  selectedPiktogramm,
  setSelectedPiktogramm,
  closeDialog,
}: Props) => {
  const [confirmationCloseDialog, setConfirmationCloseDialog] =
    useState<boolean>(false);
  const [updatedPiktogramm, setUpdatedPiktogramm] =
    useState<PiktogrammItem>(selectedPiktogramm);
  const mutationUpdatePiktogramm = useUpdatePiktogramm(db, contId, plID);
  const isMobile = useIsMobileTemplate();

  const browserWindowHeight = isMobile
    ? window.innerHeight
    : window.innerHeight * 0.7;

  const onCancelCloseDialog = () => {
    setConfirmationCloseDialog(false);
  };

  const onConfirmCloseDialog = () => {
    setConfirmationCloseDialog(false);
    closeDialog();
  };

  useLayoutEffect(() => {
    // User can change selected Piktogramm while this dialog is open by pressing the update button on the card
    setUpdatedPiktogramm(selectedPiktogramm);
  }, [selectedPiktogramm]);

  const { setValidationErrors } = useValidationError();

  return (
    <div>
      {confirmationCloseDialog && (
        <ABKConfirmationDialog
          message="Sind sie sicher? Ihre Eingaben gehen dabei verloren."
          onClose={onCancelCloseDialog}
          onConfirm={onConfirmCloseDialog}
        />
      )}

      <ABKEigenschaftenfenster
        title={`Eigenschaften`}
        initialHeight={browserWindowHeight}
        onClose={() => {
          if (!_.isEqual(updatedPiktogramm, selectedPiktogramm)) {
            setConfirmationCloseDialog(true);
            return;
          }
          closeDialog();
        }}
      >
        <div className="wrapper-panels-and-balken">
          <div className="panels">
            <PiktogrammDetailsPanel
              piktogramm={updatedPiktogramm}
              setPiktogramm={setUpdatedPiktogramm}
            />
          </div>
          <div className="save-balken-container">
            <ABKSaveBalken
              edited={!_.isEqual(updatedPiktogramm, selectedPiktogramm)}
              onSave={async () => {
                mutationUpdatePiktogramm.mutate(updatedPiktogramm, {
                  onSuccess: (mutatedPikto) => {
                    setSelectedPiktogramm(mutatedPikto.value);
                  },
                });
              }}
              onReset={() => {
                setUpdatedPiktogramm(selectedPiktogramm);
                setValidationErrors([]);
              }}
              isInline={true}
              saveButtonText="Speichern"
            />
          </div>
        </div>
      </ABKEigenschaftenfenster>
    </div>
  );
};
