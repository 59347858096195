import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Loader } from "@progress/kendo-react-indicators";
import { useParams } from "react-router-dom";
import ABKControlledExpansionPanel from "src/abk-shared/components/atoms/ABKControlledExpansionPanel";
import ExpansionPanelTitle from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/ExpansionPanelTitle";
import { OHNE_INHALT } from "src/abk-shared/constants/text";
import { useIsValidDbAndContIdInURL } from "src/core/utility/useIsValidDB";
import { EintragFrontend } from "src/modules/Datei/interfaces/parameterlisten.types";
import { PropsEintragPanel } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ParTypePanelsMap";
import PiktogrammForm from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/PiktogrammForm";
import {
  defaultPiktogramm,
  PiktogrammItem,
} from "src/modules/Datei/pages/Parameterlisten/Piktogramme/piktogramm.types";
import useGetPiktogramme from "src/modules/Datei/pages/Parameterlisten/Piktogramme/useGetPiktogramme";

export default function PiktogrammPanel({
  setPageState,
  pageState,
  sections,
  updateSections,
}: PropsEintragPanel) {
  const { isValid, db, contId } = useIsValidDbAndContIdInURL();
  const { plID } = useParams();

  const {
    data: piktogrammeFromBackend,
    isLoading: isPiktogrammeLoading,
    error: piktogrammeLoadingError,
  } = useGetPiktogramme(db as string, contId as string, plID!);

  let piktogramme = piktogrammeFromBackend?.value?.Items ?? [];

  const keinPiktogrammOption: PiktogrammItem = {
    ...defaultPiktogramm,
    BEZ: "Keines",
  };

  if (!piktogramme.some((item) => item.BEZ === keinPiktogrammOption.BEZ)) {
    piktogramme.unshift(keinPiktogrammOption);
  }

  const selectedPiktogramm =
    piktogramme.find((piktogramm) => piktogramm.FILEID === pageState?.PIKTID) ||
    keinPiktogrammOption;

  if (!isValid) return <></>;

  if (piktogrammeLoadingError)
    return <div>{piktogrammeLoadingError.message}</div>;

  const handleDropDownChange = (event: DropDownListChangeEvent) => {
    setPageState({
      ...pageState,
      PIKTID: event.value.FILEID,
    } as EintragFrontend);
  };

  const previewText =
    selectedPiktogramm.BEZ === "Keines" ? OHNE_INHALT : selectedPiktogramm.BEZ;

  const isExpanded = sections?.get("piktogramm") || false;

  const handlePanelToggle = () => {
    if (updateSections && sections) {
      const newSections = new Map(sections);
      newSections.set("piktogramm", !isExpanded);
      updateSections(newSections);
    }
  };
  return (
    <ABKControlledExpansionPanel
      expanded={isExpanded}
      onAction={handlePanelToggle}
      title={
        <ExpansionPanelTitle
          title="Piktogramm"
          previewText={previewText}
          shouldShowPreview={!isExpanded}
        />
      }
    >
      {isPiktogrammeLoading ? (
        <Loader />
      ) : (
        <PiktogrammForm
          selectedPiktogramm={selectedPiktogramm}
          piktogramme={piktogramme}
          onChange={handleDropDownChange}
        />
      )}
    </ABKControlledExpansionPanel>
  );
}
