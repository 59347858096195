import { SvgIcon } from "@progress/kendo-react-common";
import { Breadcrumb } from "@progress/kendo-react-layout";
import React, { useLayoutEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { NavigationItem } from "src/abk-shared/interfaces/NavigationItem";
import useMainPageTemplateState from "src/core/stores/useMainPageTemplateState";
import useGetSingleParameterliste from "src/modules/Datei/queries/useGetSingleParameterliste";
import * as Icons from "../../../../abk-shared/interfaces/abk9SvgIcons";
import { OPEN } from "../../../constants/urls";
import ABKBreadcrumbLink from "./ABKBreadcrumbLink";
import { getActiveNavigationItems } from "./getActiveNavigationItems";

const StartBreadCrumb: NavigationItem = {
  id: "startseite",
  text: "Startseite",
  title: "Startseite",
  description: "",
  icon: <SvgIcon icon={Icons.Menu_Start} style={{ marginRight: 5 }} />,
  svgIcon: Icons.Menu_Start_FileOpen,
  path: OPEN,
  route: "",
  shouldOverrideBreadcrumb: false,
};

export default function Breadcrumbs() {
  const params = useParams();
  const dbName = params.db ?? "";
  const contId = params.contId ?? "";
  const plID = params.plID ?? "";

  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([StartBreadCrumb]);
  const { setMainPageTemplateTitle } = useMainPageTemplateState();
  const currentParameterListe = useGetSingleParameterliste(
    dbName,
    contId,
    plID
  );

  useLayoutEffect(() => {
    const breadcrumbData = getActiveNavigationItems({
      location,
      dbName,
      contId,
      customTitle: currentParameterListe.data?.value.Kenndaten.BEZ ?? plID,
    });
    setBreadcrumbs([StartBreadCrumb, ...(breadcrumbData as any)]);
  }, [
    location,
    dbName,
    contId,
    plID,
    currentParameterListe.data?.value?.Kenndaten?.BEZ,
  ]);

  useLayoutEffect(() => {
    // Side effect to set Title and Description of current page
    const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
    setMainPageTemplateTitle(lastBreadcrumb.title);
  }, [breadcrumbs, setMainPageTemplateTitle, breadcrumbs?.length]);

  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Breadcrumb
          size="medium"
          data={breadcrumbs}
          breadcrumbLink={(props) => <ABKBreadcrumbLink {...props} />}
        />
      </div>
    </React.Fragment>
  );
}
