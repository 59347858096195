import { forEachColumns } from "../../hooks/useGridColumns/columnUtils";
import { GridColumnPropsCustom } from "../../interfaces/GridColumns";

export default function getPropsFromColumns(
  columns: GridColumnPropsCustom[],
  field: string | undefined
) {
  let headerIsColumnGroup = false;
  let tooltip: string | undefined = undefined;
  let headerDataTestid: string | undefined = undefined;

  forEachColumns(columns, (column) => {
    if (column.field !== field) return;

    tooltip = column.tooltip;
    headerDataTestid = column.headerDataTestid;

    const hasChildren =
      Array.isArray(column.children) && column.children.length > 0;
    if (hasChildren) headerIsColumnGroup = true;
  });

  return { headerIsColumnGroup, tooltip, headerDataTestid };
}
