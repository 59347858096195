import React from "react";
import { ToolbarActionObject } from "src/abk-shared/components/molecules/ABKToolbar/abkToolbar.types";
import { createToolbarActions } from "src/abk-shared/components/molecules/ABKToolbar/createToolbarActions";
import {
  BasisGridPropsContext,
  SelectionContext,
} from "src/abk-shared/components/organisms/ABKBasisGrid/components/BasisGridContextProvider/contexts";
import DataStateContext from "../../hooks/useGridDataState/DataStateContext";

type Props = {
  isMobileGrid: boolean;
};

export default function ABKToolbarGridActions({ isMobileGrid }: Props) {
  const { dataState, dataResultWithSelectedField } =
    React.useContext(DataStateContext);
  const { gridActions } = React.useContext(BasisGridPropsContext);
  const gridRowSelection = React.useContext(SelectionContext);

  const actions = React.useMemo(() => {
    const actions =
      gridActions?.(gridRowSelection, dataResultWithSelectedField, dataState) ??
      [];

    for (const action of actions) {
      if (React.isValidElement(action)) continue;

      const actionObject = action as ToolbarActionObject;
      const actionObjectOnClick = actionObject.onClick;
      const onClickWithSelectedItems = (
        event: React.MouseEvent<HTMLElement, MouseEvent>
      ) => {
        actionObjectOnClick(event, gridRowSelection);
      };
      actionObject.onClick = onClickWithSelectedItems;
    }

    return actions;
  }, [dataResultWithSelectedField, dataState, gridActions, gridRowSelection]);

  const toolbarLeftElements = createToolbarActions(isMobileGrid, 3, actions);
  return toolbarLeftElements;
}
