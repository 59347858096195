import React from "react";
import { GridSelectionProps } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import { EintragFrontend } from "src/modules/Datei/interfaces/parameterlisten.types";
import { useRowIndexForInitialScrollTop } from "src/modules/Datei/pages/Parameterlisten/Eintraege/useEintraege/useSetCurrentEintrag/getRowIndexForInitialScrollTop";
import { useSetEigenschaftenfensterForEintrag } from "src/modules/Datei/pages/Parameterlisten/Eintraege/useEintraege/useSetCurrentEintrag/useSetEigenschaftenfensterForEintrag";
import { useEintraegeStore } from "src/modules/Datei/pages/Parameterlisten/Eintraege/useEintraegeStore";

type Props = {
  db: string;
  contId: string;
  plID: string;
  itemId: string;
  eintraege: EintragFrontend[] | undefined;
};

export function useSetCurrentEintrag({
  db,
  contId,
  plID,
  itemId,
  eintraege,
}: Props) {
  // Used to not display the grid before the `initialSelectedItems` has been set
  const [isSettingEintragFirstMount, setIsSettingEintragFirstMount] =
    React.useState(true);
  const [highlightedItem, setHighlightedItem] = React.useState<
    EintragFrontend | undefined
  >();
  const [initialSelectedItems, setInitialSelectedItems] = React.useState<
    EintragFrontend[]
  >([]);

  useSetEigenschaftenfensterForEintrag(
    db,
    contId,
    plID,
    itemId,
    eintraege,
    setIsSettingEintragFirstMount,
    setInitialSelectedItems,
    setHighlightedItem
  );

  const { setCurrentEintrag } = useEintraegeStore();

  const selection: GridSelectionProps = {
    onSelectionChange: (selectedItems) => {
      const newHighlightedItem = selectedItems[0] as
        | EintragFrontend
        | undefined;
      setHighlightedItem(newHighlightedItem);

      if (!newHighlightedItem) return setCurrentEintrag(null);
      setCurrentEintrag(newHighlightedItem as EintragFrontend);
    },
    highlightedItem,
    initialSelectedItems,
  };

  const rowIndexForInitialScrollTop = useRowIndexForInitialScrollTop(
    itemId,
    eintraege
  );

  return { isSettingEintragFirstMount, selection, rowIndexForInitialScrollTop };
}
