import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from "@progress/kendo-react-layout";
import Highlighter from "react-highlight-words";
import { FunctionOnSelectionChange } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import { Job } from "../../../interfaces/Job";

type Props = {
  rowRef: React.RefObject<HTMLDivElement>;
  item: Job;
  onSelectionChange: FunctionOnSelectionChange;
  filterValue: string;
};
const JobCard = ({ rowRef, item, onSelectionChange, filterValue }: Props) => (
  <div ref={rowRef}>
    <Card
      className="card-element"
      title={item.DESCRIPTION}
      onClick={() => {
        return onSelectionChange([item], item);
      }}
    >
      <CardHeader className="k-hbox">
        <div className="title-container">
          <CardTitle>
            <Highlighter
              searchWords={[filterValue]}
              textToHighlight={item.DESCRIPTION || ""}
            />
          </CardTitle>
          <CardSubtitle>
            <Highlighter
              searchWords={[filterValue]}
              textToHighlight={item.JOBID}
            />
          </CardSubtitle>
        </div>
      </CardHeader>
      <CardBody>
        <Highlighter
          searchWords={[filterValue]}
          textToHighlight={item.OWNER || ""}
        />
      </CardBody>
    </Card>
  </div>
);

export default JobCard;
