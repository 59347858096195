import React from "react";
import { GridReorderRows } from "./gridReorderRowsInterfaces";

export const ReorderContext = React.createContext<{
  reorder: GridReorderRows["reorder"];
  dragStart: GridReorderRows["dragStart"];
  dragEnd: GridReorderRows["dragEnd"];
  itemIsBeingDragged: GridReorderRows["itemIsBeingDragged"];
}>({
  reorder: () => Promise.resolve(),
  dragStart: () => {},
  dragEnd: () => {},
  itemIsBeingDragged: () => false,
});
