import React from "react";
import { GridRef } from "src/abk-shared/components/organisms/ABKBasisGrid/components/BasisGridContextProvider/contexts";
import { getGridVirtualScrollingContainer } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useFocusOnGridOnMount/getGridVirtualScrollingContainer";

/*
  We need to focus on the grid when it first mounts, to be able to scroll in
  the grid using the arrow keys. This function enables the following feature:
   - The user selects an item
   - The user uses `CTRL` + `Arrow Up` to reorder the item one line above
   - The grid loads
   -> After the loading, the user is able to scroll with `Arrow Up` and `Arrow Down`.
   Without this function, he would not be able to scroll with the arrow keys.
*/
export function useFocusOnGridOnMount(gridRef: GridRef) {
  React.useEffect(() => {
    const element = getGridVirtualScrollingContainer(gridRef);
    if (!element) return;

    /*
      `tabindex="-1"` is here used to enable focus on the `div` element.
      Without `tabindex="-1"` would `element.focus()` not work in Chrome.
    */
    element.tabIndex = -1;
    element.focus();
    // eslint-disable-next-line
  }, []);
}
