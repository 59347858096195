import { Link } from "react-router-dom";
import { ABKDraftHint } from "src/abk-shared/components/atoms/ABKDraftHint/ABKDraftHint";
import ABKBasisGridHighlighter from "src/abk-shared/components/organisms/ABKBasisGrid/components/ABKBasisGridHighlighter";
import { TestProps } from "../../../../../interfaces/TestProps";
import { PropsCustomCell } from "../CustomCell";
import "./style.scss";

type Props = PropsCustomCell & {
  text: string;
  to: string;
  testProps?: TestProps;
  hasDraft?: boolean;
  isBold?: boolean;
  isGreen?: boolean;
};

export default function ABKBasisGridCellLink(props: Props) {
  const linkColorClass = props.isGreen
    ? "abk-basis-grid-cell-link-green-link-decorator-green"
    : "abk-basis-grid-cell-link-green-link-decorator-normal";
  return (
    <td
      /*
        Not all "tdProps" are passed, to disable the default onClick event for
        the row, and allow the user to click on the link.
      */
      onContextMenu={props.tdProps?.onContextMenu}
      style={props.tdProps?.style}
      className={props.tdProps?.className}
      role="gridcell"
      // `aria-colindex` is used in the Playwright tests
      aria-colindex={props.tdProps?.["aria-colindex"]}
      title={props.text}
    >
      <span
        data-testid={props.testProps?.dataTestid}
        className="abk-basis-grid-cell-link-center-container"
      >
        {props.hasDraft && <ABKDraftHint />}
        <Link
          className={`link k-d-block k-text-ellipsis ${linkColorClass}`}
          to={props.to}
        >
          <ABKBasisGridHighlighter
            filterValue={props.filterValue}
            text={props.text}
            isBold={props.isBold}
            isGreen={props.isGreen}
          />
        </Link>
      </span>
    </td>
  );
}
