import { useParams } from "react-router-dom";
import { GliederungssystemLayout } from "src/modules/Datei/pages/Gliederungssysteme/GliederungssystemLayout/GliederungssystemLayout";
import { useIsValidDbAndContIdInURL } from "../../../../../core/utility/useIsValidDB";

export function GliederungssystemLayoutWrapper() {
  const { gsID } = useParams();

  const { isValid, db, contId } = useIsValidDbAndContIdInURL();
  if (!isValid || !gsID) return <></>;

  return (
    <GliederungssystemLayout
      key={`gliederungssystem-layout-${db}-${contId}-${gsID}`}
      dbName={db!}
      contId={contId!}
      gsID={gsID}
    />
  );
}
