import { Loader } from "@progress/kendo-react-indicators";
import { useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import ABKPictureCard from "src/abk-shared/components/atoms/ABKPictureCard";
import ABKConfirmationDialog from "src/abk-shared/components/molecules/ABKConfirmationDialog";
import { isMobile } from "src/abk-shared/utility/detectDevice";
import {
  generateBase64Image,
  LogoFormat,
} from "src/core/utility/imageProcessing";
import useGetSingleParameterliste from "src/modules/Datei/queries/useGetSingleParameterliste";
import { WrapperNewPiktogrammDialog } from "./Dialoge/NewPiktogrammDialog";
import { WrapperUpdatePiktogrammDialog } from "./Dialoge/UpdatePiktogrammDialog";
import { defaultPiktogramm, PiktogrammItem } from "./piktogramm.types";
import { PiktogrammToolbar } from "./PiktogrammToolbar";
import "./styles.scss";
import { useDeletePiktogramm } from "./useDeletePiktogramm";
import useGetPiktogramme from "./useGetPiktogramme";

type Props = { db: string; contId: string; plID: string };

const PiktogrammePage = ({ db, contId, plID }: Props) => {
  const [updatedPiktogramm, setUpdatedPiktogramm] = useState<{
    selectedFile?: PiktogrammItem;
  }>({
    selectedFile: undefined,
  });
  const [showNewDialog, setShowNewDialog] = useState(false);
  const [showDeleteMultipleDialog, setShowDeleteMultipleDialog] =
    useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [openedPiktogramm, setOpenedPiktogramm] = useState(defaultPiktogramm);
  const [selectedPiktogrammIds, setSelectedPiktogrammIds] = useState<string[]>(
    []
  );
  const {
    data: parameterliste,
    isLoading: isParameterlisteLoading,
    error: parameterlisteLoadingError,
  } = useGetSingleParameterliste(db, contId, plID!);
  const {
    data: piktogrammeFromBackend,
    isLoading: isPiktogrammeLoading,
    error: piktogrammeLoadingError,
  } = useGetPiktogramme(db, contId, plID!);
  const deletePiktogrammMutation = useDeletePiktogramm(db, contId, plID!);
  const piktogramme = piktogrammeFromBackend?.value?.Items ?? [];

  const { width } = useResizeDetector();
  const isCalculatingWidth = width == null;
  const isSmallGrid = !isCalculatingWidth && width < 600;
  const isMobileGrid = isMobile() || isSmallGrid;

  if (isParameterlisteLoading || !parameterliste || isPiktogrammeLoading)
    return <Loader />;

  if (parameterlisteLoadingError)
    return <div>{parameterlisteLoadingError.message}</div>;

  if (piktogrammeLoadingError)
    return <div>{piktogrammeLoadingError.message}</div>;

  const handleInsertButtonClick = (file: PiktogrammItem) => {
    setUpdatedPiktogramm({ selectedFile: file });
    setShowNewDialog(true);
    setOpenedPiktogramm(defaultPiktogramm);
  };

  const handleOpenEigenschaftenfenster = (id: string) => {
    const current = piktogramme.find((x) => x.FILEID === id);
    if (current) {
      setOpenedPiktogramm(current);
      setShowNewDialog(false);
    }
  };

  return (
    <div data-testid="piktogramm-page" className="piktogramm-page">
      {idToDelete && (
        <ABKConfirmationDialog
          message={`Möchten Sie das Piktogramm "${piktogramme.find((p) => p.FILEID === idToDelete)?.BEZ}" wirklich löschen?`}
          onClose={() => setIdToDelete("")}
          onConfirm={() => {
            setOpenedPiktogramm(defaultPiktogramm);
            deletePiktogrammMutation.mutate(idToDelete, {
              onSettled: () => setIdToDelete(""),
            });
          }}
        />
      )}
      {showDeleteMultipleDialog && (
        <ABKConfirmationDialog
          message={
            selectedPiktogrammIds.length === 1
              ? `Möchten Sie 1 Piktogramm wirklich löschen?`
              : `Möchten Sie ${selectedPiktogrammIds.length} Piktogramme wirklich löschen?`
          }
          onClose={() => setShowDeleteMultipleDialog(false)}
          onConfirm={() => {
            setOpenedPiktogramm(defaultPiktogramm);
            selectedPiktogrammIds.forEach((id) => {
              deletePiktogrammMutation.mutate(id);
            });
            setShowDeleteMultipleDialog(false);
            setSelectedPiktogrammIds([]);
          }}
        />
      )}
      {showNewDialog && (
        <WrapperNewPiktogrammDialog
          db={db}
          contId={contId}
          plID={plID}
          closeDialog={() => setShowNewDialog(false)}
          uploadedPiktogramm={updatedPiktogramm.selectedFile}
        />
      )}
      {openedPiktogramm.FILEID !== defaultPiktogramm.FILEID && (
        <WrapperUpdatePiktogrammDialog
          db={db}
          contId={contId}
          plID={plID}
          selectedPiktogramm={openedPiktogramm}
          setSelectedPiktogramm={setOpenedPiktogramm}
          closeDialog={() => setOpenedPiktogramm(defaultPiktogramm)}
        />
      )}
      <PiktogrammToolbar
        filterValue={filterValue}
        onFilterChange={(v) => setFilterValue(v)}
        isMobileGrid={isMobileGrid}
        showDeleteButton={selectedPiktogrammIds.length > 0}
        onInsertButtonClick={(file) => {
          handleInsertButtonClick(file);
        }}
        onDeleteButtonClick={() => setShowDeleteMultipleDialog(true)}
      />
      <div className="piktogramm-card-container">
        {piktogramme.map((p) => {
          const skipPiktogramm = !matchesPiktogrammFilter(p, filterValue);
          if (skipPiktogramm) {
            return null; // Ensure you return something
          }
          const { imageBase64 } = generateBase64Image(
            p.CONTENT,
            LogoFormat.SVG
          );
          return (
            <ABKPictureCard
              id={p.FILEID}
              key={p.FILEID}
              title={p.BEZ + ".svg"}
              iconBase64={imageBase64}
              isSelected={selectedPiktogrammIds.includes(p.FILEID)}
              onDelete={(id) => {
                setIdToDelete(id);
              }}
              onOpen={(id) => handleOpenEigenschaftenfenster(id)} // Corrected
              setSelected={(id, isSelected) => {
                if (isSelected) {
                  setSelectedPiktogrammIds((old) => [...old, id]);
                }
                if (!isSelected) {
                  setSelectedPiktogrammIds((old) => [
                    ...old.filter((x) => x !== id),
                  ]);
                }
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PiktogrammePage;

function matchesPiktogrammFilter(
  piktogramm: PiktogrammItem,
  filterValue: string
) {
  return Boolean(piktogramm.BEZ.match(filterValue));
}
