import {
  FILE_INFO_SUFFIX,
  FILE_PROTOCOL_SUFFIX,
  INFO_SUFFIX,
} from "src/core/constants/urls";

import TabsLayout from "src/Router/Layout/TabsLayout";

export function TabsLayoutFileInfo() {
  return (
    <TabsLayout
      routePrefix={FILE_INFO_SUFFIX}
      tabs={[
        {
          id: 0,
          label: "Info",
          to: `${FILE_INFO_SUFFIX}/${INFO_SUFFIX}`,
        },
        {
          id: 1,
          label: "Protokoll",
          to: `${FILE_INFO_SUFFIX}/${FILE_PROTOCOL_SUFFIX}`,
        },
      ]}
    />
  );
}
