import { DrawerSelectEvent } from "@progress/kendo-react-layout";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

import { useDraftNotifications } from "src/core/components/templates/useDraftNotifications/useDraftNotifications";
import { INotification } from "../../../abk-shared/components/organisms/ABKSideBar/NotificationBox";
import { useQuickInfoState } from "../../../abk-shared/stores/useQuickInfoState";
import useDrawerStore from "../../stores/useDrawerStore";
import useNotificationStore, {
  ErrorNotification,
} from "../../stores/useNotificationStore";
import ABKConfirmationDialogForJob from "../molecules/ABKConfirmationDialogForJob";
import useJobDialogStore from "../molecules/ABKConfirmationDialogForJob/useJobDialogStore";
import AbkAppBar from "../organisms/AbkAppBar";
import DesktopStructure from "./DesktopStructure";
import getQuickInfoForNotification from "./MainPageTemplate/generateSidebarElements/getQuickInfoForNotification";
import useJobNotifications from "./MainPageTemplate/useJobNotifications";
import useScrollableHeader from "./MainPageTemplate/useScrollableHeader";
import MobileStructure from "./MobileStructure";
import "./style.css";
import { WrapperTemplateProps } from "./WrapperTemplate";

const WebsiteStructure = ({
  children,
  includeTitle,
  includeDescription,
  appBarIncludesDropDown = true,
  isDesktop = false,
}: WrapperTemplateProps) => {
  const params = useParams();
  const database = params.db;

  const {
    errors,
    infos,
    warnings,
    removeNotification,
    clearAll,
    clearErrors,
    clearInfos,
    clearWarnings,
  } = useNotificationStore();

  const { setQuickInfo } = useQuickInfoState();

  const jobNotifications = useJobNotifications();
  const { isDialogOpen } = useJobDialogStore();

  const draftNotifications = useDraftNotifications(database);

  const { headerRef, scrollableContainerRef } = useScrollableHeader();
  const { currentDrawerItems, toggleDrawer } = useDrawerStore();

  const navigate = useNavigate();

  const onDrawerItemSelect = (e: DrawerSelectEvent) => {
    if (e.itemTarget.props.text === "") {
      toggleDrawer();
    } else {
      navigate(e.itemTarget.props.route);
    }
  };

  const onNotificationClick = async (
    notification: INotification | ErrorNotification
  ) => {
    const { quickInfoContent, quickInfoClipboard } =
      await getQuickInfoForNotification(notification, queryClient);
    setQuickInfo(quickInfoContent, notification, quickInfoClipboard);
  };

  const queryClient = useQueryClient();

  const notifications = [
    ...infos,
    ...draftNotifications,
    ...warnings,
    ...errors,
    ...jobNotifications,
  ];

  const infoHubOptions = <p>test</p>;

  const desktopAndMobileProps = {
    removeNotification,
    onCloseNotification: removeNotification,
    setQuickInfo,
    clearAll,
    clearWarnings,
    clearErrors,
    clearInfos,
    queryClient,
    isDesktop,
    headerRef,
    infoHubOptions,
    includeTitle,
    includeDescription,
    scrollableContainerRef,
    currentDrawerItems,
    onDrawerItemSelect,
    onNotificationClick,
    notifications,
    children,
  };

  return (
    <>
      <AbkAppBar isDesktop={isDesktop} isDropDown={appBarIncludesDropDown} />
      {isDesktop ? (
        <DesktopStructure {...desktopAndMobileProps} />
      ) : (
        <MobileStructure {...desktopAndMobileProps} />
      )}
      {isDialogOpen && <ABKConfirmationDialogForJob />}
    </>
  );
};

export default WebsiteStructure;
