import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Gliederungssystem } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import { QUERY_KEY_Gliederungssystem } from "src/modules/Datei/queries/useGetSingleGliederungssystem";

const useGetAllContainerGliederungssysteme = (
  db: string,
  contId: string,
  ignoreError?: boolean
) => {
  const getAllContainerGliederungssysteme = async () => {
    const response = await axios.get(`/api/db/${db}/cont/${contId}/gs`);
    try {
      const gliederungssysteme = response.data.Items;
      return gliederungssysteme as Gliederungssystem[];
    } catch (error) {
      if (ignoreError) {
        return [] as Gliederungssystem[];
      }
      throw error;
    }
  };

  return useQuery({
    queryKey: [QUERY_KEY_Gliederungssystem, db, contId],
    queryFn: () => getAllContainerGliederungssysteme(),
    enabled: Boolean(db) && Boolean(contId),
  });
};

export default useGetAllContainerGliederungssysteme;
