import { State } from "@progress/kendo-data-query";
import {
  GridColumnReorderEvent,
  GridColumnResizeEvent,
} from "@progress/kendo-react-grid";
import { produce } from "immer";
import React from "react";
import { OnRefChangeType } from "react-resize-detector/build/types/types";
import { ABK_BASIS_GRID_COLUMN_MIN_WIDTH } from "src/abk-shared/components/organisms/ABKBasisGrid/constants";
import {
  GridColumnPropsCustom,
  GridColumnsDefinition,
} from "../../interfaces/GridColumns";
import {
  createColumnsDisplayedFromColumns,
  editOrderIndexOfColumns,
  forEachColumns,
  getColumnFromIndex,
  getColumnIndexFromId,
  getWidthResizedOfColumn,
} from "./columnUtils";
import createPersistedColumnsStore from "./persistedColumnsStore/createPersistedColumnsStore";
import {
  FunctionColumnToggleVisibility,
  toggleShowForColumnHavingField,
} from "./toggleColumnVisibility";
import useExpandLastColumnIfGridTooWide from "./useExpandLastColumnIfGridTooWide";
import useHideDropClueIfOutsideGrid from "./useHideDropClueIfOutsideGrid";
import useInitializeGridMinimumWidth from "./useInitializeGridMinimumWidth";

export const DEFAULT_MIN_GRID_WIDTH = 0;
export const DEFAULT_NB_COLUMNS_DYNAMIC_WIDTH = 0;

function useGridColumns(
  gridSectorId: string,
  columnsDefinition: GridColumnsDefinition,
  wrapperGridRef: OnRefChangeType<HTMLDivElement>,
  dataState: State,
  hasCheckboxSelection: boolean,
  isMobileGrid: boolean,
  isReorderingEnabled: boolean
) {
  // From https://github.com/pmndrs/zustand/blob/main/docs/guides/initialize-state-with-props.md#basic-component-usage
  const columnsStore = React.useRef(
    createPersistedColumnsStore(gridSectorId, columnsDefinition)
  ).current;
  const { columns, setColumns } = columnsStore();

  const columnsDisplayed: GridColumnPropsCustom[] = React.useMemo(
    () =>
      createColumnsDisplayedFromColumns(
        columns,
        hasCheckboxSelection,
        isReorderingEnabled
      ),
    [columns, hasCheckboxSelection, isReorderingEnabled]
  );

  const minGridWidth = React.useRef<number>(DEFAULT_MIN_GRID_WIDTH);
  const nbColumnsDynamicWidth = React.useRef<number>(
    DEFAULT_NB_COLUMNS_DYNAMIC_WIDTH
  );
  const grid = React.useRef<any>(null);

  const [applyMinWidth, setApplyMinWidth] = React.useState(false);
  const [gridCurrent, setGridCurrent] = React.useState(0);

  useHideDropClueIfOutsideGrid(wrapperGridRef);

  const expandLastColumn = useExpandLastColumnIfGridTooWide(
    grid,
    columnsDisplayed,
    setColumns,
    dataState
  );

  useInitializeGridMinimumWidth(
    wrapperGridRef,
    isMobileGrid,
    grid,
    minGridWidth,
    nbColumnsDynamicWidth,
    columnsDisplayed,
    setGridCurrent,
    applyMinWidth,
    setApplyMinWidth
  );

  const setWidth = (
    minWidth: number | undefined,
    manualWidth: number | undefined
  ) => {
    const minWidthNumber = minWidth === undefined ? 0 : minWidth;

    if (manualWidth !== undefined) {
      return manualWidth;
    }

    let width = 0;

    // Divisor set to 1 instead of 0 to prevent the width to be NaN
    const divisor =
      nbColumnsDynamicWidth.current === 0 ? 1 : nbColumnsDynamicWidth.current;
    const supplementarySpaceToFillGrid =
      (gridCurrent - minGridWidth.current) / divisor;

    width = applyMinWidth
      ? minWidthNumber
      : minWidthNumber + supplementarySpaceToFillGrid;

    /*
      Adjust column width to not have a horizontal scroll bar when initializing the grid.
      From: https://www.telerik.com/kendo-react-ui/components/grid/columns/width/#toc-setting-a-minimum-column-width
    */
    const ADJUST_PADDING: number = 4;
    const COLUMN_MIN: number = ABK_BASIS_GRID_COLUMN_MIN_WIDTH;
    if (width >= COLUMN_MIN) width = width - ADJUST_PADDING;

    return width;
  };

  const lockedColumnFields = React.useMemo(() => {
    const result: string[] = [];
    forEachColumns(columnsDisplayed, (column) => {
      if (column.locked && column.field) result.push(column.field);
    });
    return result;
  }, [columnsDisplayed]);

  const onColumnReorder = (event: GridColumnReorderEvent) => {
    const reorderedColumns = event.columns;
    editOrderIndexOfColumns(
      columnsDisplayed,
      reorderedColumns,
      lockedColumnFields
    );
    setColumns([...columnsDisplayed]);
  };

  const onColumnResize = (event: GridColumnResizeEvent) => {
    /*
      Wir brauchen den "event.targetColumnId" und verwenden nicht "event.index",
      weil die Column Index können sich ändern, wenn wir die Columns gruppieren.
    */
    const targetColumnId = event.targetColumnId;
    const resizedColumns = event.columns;
    const { parentColumnIndex, childrenColumnIndex } = getColumnIndexFromId(
      resizedColumns,
      targetColumnId
    );

    const noColumnFound = parentColumnIndex === -1;
    if (noColumnFound) return;

    /*
      Not taking the "event.newWidth", because it is 0 in case of double-click
      on the column resizer for an automatic resize.
      However, "event.newWidth" is used as a fallback.
    */
    const fallbackWidth = event.newWidth;
    const widthResized = getWidthResizedOfColumn(
      resizedColumns,
      parentColumnIndex,
      childrenColumnIndex,
      fallbackWidth
    );

    const columnToChange = getColumnFromIndex(
      columnsDisplayed,
      parentColumnIndex,
      childrenColumnIndex
    );
    if (columnToChange) {
      columnToChange.manualWidth = Math.max(
        widthResized,
        ABK_BASIS_GRID_COLUMN_MIN_WIDTH
      );
      columnToChange.manualWidth = widthResized;
    }

    setColumns([...columnsDisplayed]);
    expandLastColumn.setShouldExpandLastColumn(true);
    expandLastColumn.columnsDuringLastResize.current = resizedColumns;
  };

  const onColumnToggleVisibility: FunctionColumnToggleVisibility =
    React.useCallback(
      (columnField: string | undefined) => {
        const newColumns = produce(columns, (draft) => {
          toggleShowForColumnHavingField(draft, columnField);
        });
        setColumns(newColumns);

        expandLastColumn.setShouldExpandLastColumn(true);
        const columnsDisplayedForResize = createColumnsDisplayedFromColumns(
          newColumns,
          hasCheckboxSelection,
          isReorderingEnabled
        );
        expandLastColumn.columnsDuringLastResize.current =
          columnsDisplayedForResize;
      },
      [columns, expandLastColumn, hasCheckboxSelection, isReorderingEnabled]
    );

  return {
    columns,
    columnsDisplayed,
    setWidth,
    onColumnReorder,
    onColumnResize,
    onColumnToggleVisibility,
  };
}

export default useGridColumns;
