import { hasDraft } from "src/modules/Datei/pages/Parameterlisten/Eintraege/useEintraege/eintraegeDrafts/eintraegeDraftHelperFunctions";
import { TestProps } from "../../../../../../../abk-shared/interfaces/TestProps";
import "./styles.scss";

import ABKBasisGridCellLink from "src/abk-shared/components/organisms/ABKBasisGrid/components/ABKBasisGridCellLink";
import { PropsCustomCell } from "src/abk-shared/components/organisms/ABKBasisGrid/components/CustomCell";
import { EintragDraft } from "src/core/db/dbDrafts";
import { parTypeMapper } from "src/modules/Datei/pages/Parameterlisten/Eintraege/maps";
import { createEintragURL } from "src/modules/Datei/pages/Parameterlisten/Eintraege/useEintraege/createEintragURL";

type Props = {
  testProps?: TestProps;
  drafts: EintragDraft[] | undefined;
  props: PropsCustomCell;
  dataItemKey: string;
};

const BezeichnungCell = ({ testProps, props, drafts, dataItemKey }: Props) => {
  const isBold = props.dataItem.ITEMTYPE === parTypeMapper.get("Set");
  const isGreen = props.dataItem.ITEMTYPE === parTypeMapper.get("Head");
  return (
    <>
      <ABKBasisGridCellLink
        {...props}
        testProps={testProps}
        text={props.dataItem.BEZ}
        to={createEintragURL(
          props.dataItem.DBNAME,
          props.dataItem.CONTID,
          "pl",
          props.dataItem.DATAID,
          props.dataItem.ITEMID
        )}
        hasDraft={hasDraft(drafts, props.dataItem[dataItemKey])}
        isBold={isBold}
        isGreen={isGreen}
      />
    </>
  );
};

export default BezeichnungCell;
