import { useState } from "react";
import ABKConfirmationDialog from "src/abk-shared/components/molecules/ABKConfirmationDialog";
import ABKSaveBalken from "src/abk-shared/components/molecules/ABKSaveBalken";
import ABKEigenschaftenfenster from "src/abk-shared/components/organisms/ABKEigenschaftenfenster";
import ValidationErrorStoreProvider, {
  useValidationError,
} from "src/abk-shared/stores/ValidationErrorStoreProvider";
import useIsMobileTemplate from "../../../../../../abk-shared/utility/useIsMobileTemplate";
import { isPiktogrammItemValid } from "../isPiktogrammItemValid";
import { defaultPiktogramm, PiktogrammItem } from "../piktogramm.types";
import { useCreatePiktogramm } from "../useCreatePiktogramm";
import PiktogrammDetailsPanel from "./PiktogrammDetailsPanel";

export const WrapperNewPiktogrammDialog = (props: Props) => (
  <ValidationErrorStoreProvider>
    <NewPiktogrammDialog {...props} />
  </ValidationErrorStoreProvider>
);

type Props = {
  db: string;
  contId: string;
  plID: string;
  closeDialog: () => void;
  uploadedPiktogramm?: PiktogrammItem;
};

export const NewPiktogrammDialog = ({
  db,
  contId,
  plID,
  closeDialog,
  uploadedPiktogramm,
}: Props) => {
  const [confirmationDialog, setConfirmationDialog] = useState<boolean>(false);

  const [newPiktogramm, setNewPiktogramm] = useState<PiktogrammItem>({
    ...defaultPiktogramm,
    ...uploadedPiktogramm,
  });

  const mutationCreatePiktogramm = useCreatePiktogramm(db, contId, plID);

  const isMobile = useIsMobileTemplate();

  const browserWindowHeight = isMobile
    ? window.innerHeight
    : window.innerHeight * 0.7;

  const onCancelConfirmationDialog = () => {
    setConfirmationDialog(false);
  };

  const onConfirmClosingNewItemDialog = () => {
    setConfirmationDialog(false);
    closeDialog();
  };

  const { setValidationErrors } = useValidationError();

  return (
    <div className="modal-overlay">
      {confirmationDialog && (
        <ABKConfirmationDialog
          message="Sind sie sicher? Ihre Eingaben gehen dabei verloren."
          onClose={onCancelConfirmationDialog}
          onConfirm={onConfirmClosingNewItemDialog}
        />
      )}

      <ABKEigenschaftenfenster
        title={`Piktogramm hinzufügen`}
        initialHeight={browserWindowHeight}
        onClose={() => {
          if (!isPiktogrammItemValid(newPiktogramm)) {
            closeDialog();
            return;
          }
          setConfirmationDialog(true);
        }}
      >
        <div className="wrapper-panels-and-balken">
          <div className="panels">
            <PiktogrammDetailsPanel
              piktogramm={newPiktogramm}
              setPiktogramm={setNewPiktogramm}
            />
          </div>
          <div className="save-balken-container">
            <ABKSaveBalken
              edited={newPiktogramm.BEZ !== ""}
              onSave={async () => {
                mutationCreatePiktogramm.mutate(newPiktogramm, {
                  onSuccess: () => closeDialog(),
                });
              }}
              onReset={() => {
                closeDialog();
                setValidationErrors([]);
              }}
              isInline={true}
              saveButtonText="Erstellen"
            />
          </div>
        </div>
      </ABKEigenschaftenfenster>
    </div>
  );
};
