import { Meta } from "src/core/interfaces/meta.types";
import { LogoFormat } from "src/core/utility/imageProcessing";

export interface PiktogrammItem {
  BEZ: string;
  CONTENT: string;
  CONTID: string;
  DATAID: string;
  DBNAME: string;
  FILEID: string;
  Meta: Meta;
  TYP: LogoFormat.SVG;
  FILE?: File | null;
}

export interface PiktogrammeBackendResponse {
  Meta: any;
  Items: PiktogrammItem[];
}

export const defaultPiktogramm = Object.freeze<PiktogrammItem>({
  FILEID: "",
  TYP: LogoFormat.SVG,
  BEZ: "",
  CONTENT: "",
  DBNAME: "",
  CONTID: "",
  DATAID: "",
  Meta: {
    Right: "",
  },
});

export const DUMMY_MISSING_BASE64 =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiBoZWlnaHQ9IjMycHgiIHZpZXdCb3g9IjAgMCAyMCAyMCIgd2lkdGg9IjMycHgiID48Zz48cmVjdCBmaWxsPSJub25lIiBoZWlnaHQ9IjIwIiB3aWR0aD0iMjAiLz48L2c+PGc+PGc+PHBhdGggZD0iTTUsMTFjLTEuNjUsMC0zLDEuMzUtMywzYzAsMS42NSwxLjM1LDMsMywzczMtMS4zNSwzLTNDOCwxMi4zNSw2LjY1LDExLDUsMTF6IE01LDE1LjVjLTAuODMsMC0xLjUtMC42Ny0xLjUtMS41IHMwLjY3LTEuNSwxLjUtMS41czEuNSwwLjY3LDEuNSwxLjVTNS44MywxNS41LDUsMTUuNXoiLz48cGF0aCBkPSJNMTMsNmMwLTEuNjUtMS4zNS0zLTMtM1M3LDQuMzUsNyw2YzAsMS42NSwxLjM1LDMsMywzUzEzLDcuNjUsMTMsNnogTTEwLDcuNUM5LjE3LDcuNSw4LjUsNi44Myw4LjUsNlM5LjE3LDQuNSwxMCw0LjUgczEuNSwwLjY3LDEuNSwxLjVTMTAuODMsNy41LDEwLDcuNXoiLz48cGF0aCBkPSJNMTUsMTFjLTEuNjUsMC0zLDEuMzUtMywzYzAsMS42NSwxLjM1LDMsMywzczMtMS4zNSwzLTNDMTgsMTIuMzUsMTYuNjUsMTEsMTUsMTF6IE0xNSwxNS41Yy0wLjgzLDAtMS41LTAuNjctMS41LTEuNSBzMC42Ny0xLjUsMS41LTEuNXMxLjUsMC42NywxLjUsMS41UzE1LjgzLDE1LjUsMTUsMTUuNXoiLz48L2c+PC9nPjwvc3ZnPg==";
