import {
  FILE_EINTRAEGE_SUFFIX,
  FILE_KENNDATEN_SUFFIX,
  FILE_PIKTOGRAMME_SUFFIX,
  FILE_WEITEREPARAMETERLISTEN_SUFFIX,
} from "src/core/constants/urls";
import { useSetLastBreadcrumbForDataType } from "src/modules/Datei/pages/components/useSetLastBreadcrumbForDataType";
import useGetAllContainerParameterlisten from "src/modules/Datei/queries/useGetAllContainerParameterlisten";
import TabsLayout from "src/Router/Layout/TabsLayout";

type Props = { plID: string; dbName: string; contId: string };

const ParameterlisteLayout = ({ plID, dbName, contId }: Props) => {
  const { data: parameterlisten } = useGetAllContainerParameterlisten(
    dbName,
    contId
  );

  useSetLastBreadcrumbForDataType(plID, parameterlisten);

  return (
    <TabsLayout
      routePrefix={FILE_WEITEREPARAMETERLISTEN_SUFFIX}
      tabs={[
        {
          id: 0,
          label: "Kenndaten",
          to: `${FILE_WEITEREPARAMETERLISTEN_SUFFIX}/${plID}/${FILE_KENNDATEN_SUFFIX}`,
        },
        {
          id: 1,
          label: "Einträge",
          to: `${FILE_WEITEREPARAMETERLISTEN_SUFFIX}/${plID}/${FILE_EINTRAEGE_SUFFIX}`,
        },
        {
          id: 2,
          label: "Piktogramme",
          to: `${FILE_WEITEREPARAMETERLISTEN_SUFFIX}/${plID}/${FILE_PIKTOGRAMME_SUFFIX}`,
        },
      ]}
    />
  );
};

export default ParameterlisteLayout;
