import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { ABKLoader } from "src/abk-shared/components/atoms/ABKLoader/ABKLoader";
import "./style.css";

const ServerLogPage = () => {
  const config: AxiosRequestConfig = { headers: { Accept: "text/html" } };

  const { data, isLoading } = useQuery({
    queryKey: ["syslogHTML"],
    queryFn: async () => axios.get("/api/sys/log", config),
  });

  if (isLoading) {
    return <ABKLoader />;
  }

  if (!data) {
    return <div>Keine Daten gefunden</div>;
  }

  return (
    <div style={{ width: "100%" }}>
      <div dangerouslySetInnerHTML={{ __html: data.data.toString() }} />
    </div>
  );
};

export default ServerLogPage;
