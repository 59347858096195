import { QueryClient } from "@tanstack/react-query";
import axios from "axios";
import { NavigateFunction } from "react-router-dom";
import { INotification } from "../../../../../../abk-shared/components/organisms/ABKSideBar/NotificationBox";
import { Command, Job, Status } from "../../../../../interfaces/Job";
import { getFileInfoUrlForContainer } from "../../../../../utility/cont";
import downloadFile from "../../../../../utility/downloadFile";
import ABKJobNotification from "../../../../molecules/ABKJobNotification";
import getContainerNameFromJob from "../../../../molecules/ABKJobNotification/getContainerNameFromJob";
import createQuickInfoForJob from "./createQuickInfoForJob";

export default function generateNotification(
  job: Job,
  databaseName: string | undefined,
  navigate: NavigateFunction,
  onCloseJob: (job: Job) => void,
  onCancelJob: (job: Job) => void,
  queryClient: QueryClient
): INotification | void {
  const notificationFunctions = getNotificationsMap(
    job,
    databaseName,
    navigate,
    onCloseJob,
    onCancelJob,
    queryClient
  )
    .get(job.COMMAND)
    ?.get(job.STATUS);

  if (notificationFunctions) {
    let counterProps: any;

    if (notificationFunctions.hasCounter) {
      counterProps = {
        errorCount: job.CNTERR,
        warningCount: job.CNTWARN,
        infoCount: job.CNTINFO,
      };
    }

    let buttonProps: any;

    if (notificationFunctions.buttonProps) {
      buttonProps = {
        text: notificationFunctions.buttonProps?.text ?? "",
        onClick: () => notificationFunctions.buttonProps?.onClick(job),
      };
    }

    const quickInfo = createQuickInfoForJob(job);
    const containerName = getContainerNameFromJob(job);

    const notification: INotification = {
      message: job.DESCRIPTION,
      type: "success",
      notificationState: "success",
      closable: true,
      quickInfo: quickInfo,
      quickInfoData: { job: job },
      renderNotification: (onNotificationClick) => (
        <span
          className="test-e2e-job-notification"
          onClick={() =>
            onNotificationClick && onNotificationClick(notification)
          }
          title={containerName}
        >
          <ABKJobNotification
            job={job}
            containerName={containerName}
            jobText={notificationFunctions.notificationText}
            notificationState={notificationFunctions.notificationState}
            buttonProps={buttonProps}
            counterProps={counterProps}
            onClose={notificationFunctions.onClose}
          />
        </span>
      ),
    };
    return notification;
  }
}

interface NotificationFunctions {
  notificationText: string;
  hasCounter?: boolean;
  buttonProps?: {
    text: string;
    onClick: (job: Job) => void;
  };
  onClose?: () => void;
  notificationState: "loading" | "success" | "error";
}

export const getDBFromJob = (job: Job) => {
  const partsOfLogUrl = job.LogUrl.split("/");
  // Wir starten mit dem Index 1, denn "partsOfLogUrl[0]" ist ""
  const firstPart = partsOfLogUrl[1];
  /*
    Wenn der Job angelegt ist, wird die URL mit "api" anfangen.
    Nach dem ersten GET Call zu "jobs", wird die URL mit "db" anfangen.
  */
  if (firstPart === "api") return partsOfLogUrl[3];
  if (firstPart === "db") return partsOfLogUrl[2];
  return partsOfLogUrl[0];
};

function getNotificationsMap(
  job: Job,
  databaseName: string | undefined,
  navigate: NavigateFunction,
  onCloseJob: (job: Job) => void,
  onCancelJob: (job: Job) => void,
  queryClient: QueryClient
) {
  const onClose = () => onCloseJob(job);
  const onCancel = () => onCancelJob(job);

  return new Map<string, Map<string, NotificationFunctions>>([
    [
      Command.Import,
      new Map<Status, NotificationFunctions>([
        [
          Status.Running,
          {
            notificationText: "Import läuft",
            notificationState: "loading",
            buttonProps: {
              text: "Abbrechen",
              onClick: onCancel,
            },
          },
        ],
        [
          Status.OK,
          {
            notificationText: "Import erfolgreich beendet",
            notificationState: "success",
            buttonProps: {
              text: "Öffnen",
              onClick: (job) => {
                const db = databaseName || getDBFromJob(job);
                navigate(
                  getFileInfoUrlForContainer(
                    db,
                    job.DefaultFile?.CONTID ?? job.CONTID
                  )
                );
              },
            },
            hasCounter: true,
            onClose: onClose,
          },
        ],
        [
          Status.Failed,
          {
            notificationText: "Import fehlgeschlagen",
            notificationState: "error",
            onClose: onClose,
            hasCounter: true,
          },
        ],
        [
          Status.AbortRequested,
          {
            notificationText: "Wird abgebrochen",
            notificationState: "loading",
          },
        ],
      ]),
    ],
    [
      Command.Export,
      new Map<Status, NotificationFunctions>([
        [
          Status.Running,
          {
            notificationText: "Export läuft",
            notificationState: "loading",
            buttonProps: {
              text: "Abbrechen",
              onClick: onCancel,
            },
          },
        ],
        [
          Status.OK,
          {
            notificationText: "Export erfolgreich beendet",
            notificationState: "success",
            buttonProps: {
              text: "Herunterladen",
              onClick: async (job) => {
                try {
                  const url = job.DefaultFile?.DownloadUrl;
                  /*
                    Es ist möglich, dass die Datei an der `DownloadUrl` nicht
                    mehr verfügbar ist. In diesem Fall wird die GET-Request einen
                    Fehler zurückgeben. Daher senden wir vorab einen Request,
                    und wir laden die Datei herunter, nur wenn die Datei existiert.
                    https://ib-data.atlassian.net/browse/ABK9-852
                  */
                  await queryClient.fetchQuery({
                    queryKey: ["downloadJob", url],
                    queryFn: () => axios.get(url),
                  });
                  downloadFile(url);
                } catch {}
              },
            },
            onClose: onClose,
            hasCounter: true,
          },
        ],
        [
          Status.Failed,
          {
            notificationText: "Export fehlgeschlagen",
            notificationState: "error",
            hasCounter: true,
            onClose: onClose,
          },
        ],
        [
          Status.AbortRequested,
          {
            notificationText: "Wird abgebrochen",
            notificationState: "loading",
          },
        ],
      ]),
    ],
    [
      Command.CreateDB,
      new Map<Status, NotificationFunctions>([
        [
          Status.Running,
          {
            notificationText: "Datenbank-Anlage läuft",
            notificationState: "loading",
            buttonProps: {
              text: "Abbrechen",
              onClick: onCancel,
            },
          },
        ],
        [
          Status.OK,
          {
            notificationText: "Datenbank-Anlage abgeschlossen",
            notificationState: "success",
            hasCounter: true,
            onClose: onClose,
          },
        ],
        [
          Status.Failed,
          {
            notificationText: "Datenbank-Anlage fehlgeschlagen",
            notificationState: "error",
            hasCounter: true,
            onClose: onClose,
          },
        ],
        [
          Status.AbortRequested,
          {
            notificationText: "Wird abgebrochen",
            notificationState: "loading",
          },
        ],
      ]),
    ],
    [
      Command.UpdateDB,
      new Map<Status, NotificationFunctions>([
        [
          Status.Running,
          {
            notificationText: "Datenbankaktualisierung läuft",
            notificationState: "loading",
            buttonProps: {
              text: "Abbrechen",
              onClick: onCancel,
            },
          },
        ],
        [
          Status.OK,
          {
            notificationText: "Datenbankaktualisierung abgeschlossen",
            notificationState: "success",
            hasCounter: true,
            onClose: onClose,
          },
        ],
        [
          Status.Failed,
          {
            notificationText: "Datenbankaktualisierung fehlgeschlagen",
            notificationState: "error",
            hasCounter: true,
            onClose: onClose,
          },
        ],
        [
          Status.AbortRequested,
          {
            notificationText: "Wird abgebrochen",
            notificationState: "loading",
          },
        ],
      ]),
    ],
    [
      Command.RepairDB,
      new Map<string, NotificationFunctions>([
        [
          Status.Running,
          {
            notificationText: "Datenbankreparatur läuft",
            notificationState: "loading",
            buttonProps: {
              text: "Abbrechen",
              onClick: onCancel,
            },
          },
        ],
        [
          Status.OK,
          {
            notificationText: "Datenbankreparatur abgeschlossen",
            notificationState: "success",
            hasCounter: true,
            onClose: onClose,
          },
        ],
        [
          Status.Failed,
          {
            notificationText: "Datenbankreparatur fehlgeschlagen",
            notificationState: "error",
            hasCounter: true,
            onClose: onClose,
          },
        ],
        [
          Status.AbortRequested,
          {
            notificationText: "Wird abgebrochen",
            notificationState: "loading",
          },
        ],
      ]),
    ],
    [
      Command.CheckContainer,
      new Map<Status, NotificationFunctions>([
        [
          Status.Running,
          {
            notificationText: "Prüfung läuft",
            notificationState: "loading",
            buttonProps: {
              text: "Abbrechen",
              onClick: onCancel,
            },
          },
        ],
        [
          Status.OK,
          {
            notificationText: "Prüfung erfolgreich beendet",
            notificationState: "success",
            hasCounter: true,
            onClose: onClose,
          },
        ],
        [
          Status.Failed,
          {
            notificationText: "Prüfung fehlgeschlagen",
            notificationState: "error",
            onClose: onClose,
            hasCounter: true,
          },
        ],
        [
          Status.AbortRequested,
          {
            notificationText: "Wird abgebrochen",
            notificationState: "loading",
          },
        ],
      ]),
    ],
  ]);
}
