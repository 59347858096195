import { ExpansionPanelActionEvent } from "@progress/kendo-react-layout";
import ABKControlledExpansionPanel from "src/abk-shared/components/atoms/ABKControlledExpansionPanel";
import ExpansionPanelTitle from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/ExpansionPanelTitle";
import generatePreviewTextForListOfIFields from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/previewTextGenerator";
import ABKFormFieldInput from "src/abk-shared/components/atoms/ABKFormFieldInput";
import ABKFormRow from "src/abk-shared/components/atoms/ABKFormRow";
import InputMitBeschriftung, {
  dateitypLabels,
} from "src/modules/Datei/pages/Allgemein/InputMitBeschriftung";

interface AllgemeinesPanelProps {
  isExpanded: boolean;
  onAction: ((event: ExpansionPanelActionEvent) => void) | undefined;
  allgemeines: boolean;
  setDateiname: ((value: string) => void) | undefined;
  setAnmerkung: ((value: string) => void) | undefined;
  dateiname: string;
  anmerkung: string;
  contType: string;
}

const AllgemeinesPanel = ({
  isExpanded,
  onAction,
  setDateiname,
  setAnmerkung,
  dateiname,
  anmerkung,
  contType,
}: AllgemeinesPanelProps) => {
  return (
    <ABKControlledExpansionPanel
      expanded={isExpanded}
      title={
        <ExpansionPanelTitle
          title="Allgemein"
          previewText={generatePreviewTextForListOfIFields([
            {
              label: "Bezeichnung",
              value: dateiname,
              isCheckbox: false,
            },
            {
              label: "Dateityp",
              value: dateitypLabels[contType],
              isCheckbox: false,
            },
            {
              label: "Interne Anmerkung",
              value: anmerkung,
              isCheckbox: false,
            },
          ])}
          shouldShowPreview={!isExpanded}
        />
      }
      key="allgemeines"
      className="description-section"
      onAction={onAction}
    >
      {isExpanded && (
        <>
          <ABKFormRow>
            <ABKFormFieldInput
              editorId="dateiname"
              label="Dateiname"
              value={dateiname}
              setValue={setDateiname}
              backendKey="BEZ"
            />
            <InputMitBeschriftung value={contType} />
          </ABKFormRow>
          <ABKFormRow isHalfRow={true}>
            <ABKFormFieldInput
              editorId="Anmerkung"
              label="Interne Anmerkung"
              value={anmerkung}
              setValue={setAnmerkung}
              backendKey="INTANMERK"
            />
          </ABKFormRow>
        </>
      )}
    </ABKControlledExpansionPanel>
  );
};

export default AllgemeinesPanel;
