import ExpansionPanelTitle from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/ExpansionPanelTitle";
import { generatePreviewForTextArea } from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/previewTextGenerator";
import { KenndatenFormProps } from "src/modules/Datei/pages/components/KenndatenPanels/KenndatenPanels";
import ABKControlledExpansionPanel from "../../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";
import AbkOenormEditor from "../../../../../../core/components/organisms/AbkOenormEditor";
import CustomViewHtmlHook from "../../../../../../core/components/organisms/AbkOenormEditor/CustomHTMLDialog/CustomViewHtmlHook";
import editorTools from "../../../../../../core/components/organisms/AbkOenormEditor/editorTools";

const BeschreibungForm = ({
  kenndaten,
  setKenndaten,
  isOpen,
  updateSections,
}: KenndatenFormProps) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(kenndaten.BESCH, "text/html");
  const text = doc.body.textContent || "";

  const preview = generatePreviewForTextArea({
    label: "Kommentar",
    value: text,
    isCheckbox: false,
  });

  return (
    <ABKControlledExpansionPanel
      title={
        <ExpansionPanelTitle
          title="Beschreibung"
          previewText={preview}
          shouldShowPreview={!isOpen}
        />
      }
      key="beschreibungform"
      expanded={isOpen}
      onAction={updateSections}
    >
      <AbkOenormEditor
        tools={[
          ...editorTools.oenormTools,
          [CustomViewHtmlHook],
          ...editorTools.oenormTabelleTools,
          ...editorTools.tableTools,
          ...editorTools.extraTools,
        ]}
        value={kenndaten.BESCH}
        setValue={(value) =>
          setKenndaten({
            ...kenndaten,
            BESCH: value,
          })
        }
      ></AbkOenormEditor>
    </ABKControlledExpansionPanel>
  );
};

export default BeschreibungForm;
