import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useEintraegeStore } from "src/modules/Datei/pages/Parameterlisten/Eintraege/useEintraegeStore";
import { useValidationError } from "../../../abk-shared/stores/ValidationErrorStoreProvider";
import { setValidationErrorFromABKError } from "../../../abk-shared/stores/ValidationErrorStoreProvider/useGetValidationErrorMessage";
import { EintragFrontend } from "../interfaces/parameterlisten.types";
import { frontendEintragToBackend } from "../pages/Parameterlisten/Eintraege/convertBackendEintrag";
import { SaveType } from "../pages/Parameterlisten/Eintraege/SaveTypeEnum";
import { QUERY_KEY_GET_EINTRAEGE } from "../pages/Parameterlisten/Eintraege/useGetEintraege";

export type MutationAddNewPLItem = {
  dbName: string;
  contId: string;
  plID: string;
  eintrag?: EintragFrontend;
  insertType: SaveType;
  fromItemId?: string;
};

const fetchData = async (variables: MutationAddNewPLItem) => {
  let backendEintrag;

  if (variables.eintrag) {
    backendEintrag = frontendEintragToBackend(variables.eintrag);
  }

  let url = `/api/db/${variables.dbName}/cont/${variables.contId}/pl/${variables.plID}/items`;
  switch (variables.insertType) {
    case "insertBefore":
      url += `?insertBefore=${variables.fromItemId}`;
      break;
    case "insertAfter":
      url += `?insertafter=${variables.fromItemId}`;
      break;
    case "copyFrom":
      url += `?copyfromitemid=${variables.fromItemId}`;
      break;
    case "newAtEnd":
      break;
  }
  const response = await axios.post(url, backendEintrag || {});
  return response.data;
};

const useAddNewPLItem = () => {
  const { setValidationErrors } = useValidationError();
  const { setShowNewItemDialog } = useEintraegeStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: fetchData,
    onSuccess: async (_response, variables) => {
      setValidationErrors([]);
      setShowNewItemDialog(false);
      return queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEY_GET_EINTRAEGE,
          variables.dbName,
          variables.contId,
          variables.plID,
        ],
      });
    },
    onError: (error) =>
      setValidationErrorFromABKError(error, setValidationErrors),
  });
};

export default useAddNewPLItem;
