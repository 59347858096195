import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  CatalogItem,
  CatalogType,
} from "src/modules/Datei/pages/components/CatalogItemsWrapper/CatalogItemsWrapper";
import { createCatalogItemURL } from "src/modules/Datei/pages/components/CatalogItemsWrapper/createCatalogItemURL";

export function useAddCatalogItem(
  catalogType: CatalogType,
  dbName: string,
  contId: string
) {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async () =>
      axios.post<CatalogItem>(
        `/api/db/${dbName}/cont/${contId}/${catalogType}`
      ),
    onSuccess: (response) => {
      const newCatalogItem = response.data;
      const catalogItemURL = createCatalogItemURL(
        catalogType,
        newCatalogItem.DATAID,
        newCatalogItem.DBNAME,
        newCatalogItem.CONTID
      );
      navigate(catalogItemURL);
    },
  });
}
