import React from "react";
import { AllgemeinFormGeneralProps } from "src/modules/Datei/pages/components/KenndatenPanels/Allgemein/AllgemeinForm";
import ABKNumericInput from "../../../../../../abk-shared/components/atoms/ABKNumericInput";

export default function Version({
  readOnly,
  setKenndaten,
  kenndaten,
}: AllgemeinFormGeneralProps) {
  const setVersionNumber = React.useCallback(
    (version: number | null) => {
      if (version === null) version = 1;

      return setKenndaten({
        ...kenndaten,
        VERSIONSNR: version,
      });
    },
    [kenndaten, setKenndaten]
  );

  return (
    <ABKNumericInput
      editorId="version"
      label="Version"
      value={kenndaten.VERSIONSNR}
      setValue={setVersionNumber}
      readOnly={readOnly}
      backendKey="VERSIONSNR"
    />
  );
}
