import ExpansionPanelTitle from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/ExpansionPanelTitle";
import { OHNE_INHALT } from "src/abk-shared/constants/text";
import {
  Firma,
  Kontaktperson,
} from "src/modules/Datei/interfaces/kenndaten.types";
import { KenndatenFormProps } from "src/modules/Datei/pages/components/KenndatenPanels/KenndatenPanels";
import ABKAddressSelect from "../../../../../../abk-shared/components/atoms/ABKAddressSelect";
import ABKControlledExpansionPanel from "../../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";
import ABKFormFieldInput from "../../../../../../abk-shared/components/atoms/ABKFormFieldInput";
import ABKFormRow from "../../../../../../abk-shared/components/atoms/ABKFormRow";
import ABKForm from "../../../../../../abk-shared/components/molecules/ABKForm";
import ABKKommunikationSelect from "./ABKKommunikationSelect";

const HerausgeberForm = ({
  kenndaten,
  setKenndaten,
  isOpen,
  updateSections,
  readOnly,
}: KenndatenFormProps) => {
  const firma = kenndaten.Herausgeber?.firma || {};
  const kontaktperson = kenndaten.Herausgeber?.person || {};

  const updateFirma = (updatedFirma: Partial<Firma>) =>
    setKenndaten({
      ...kenndaten,
      Herausgeber: {
        ...kenndaten.Herausgeber,
        firma: {
          ...firma,
          ...updatedFirma,
        },
      },
    });

  const updateKontaktperson = (updatedKontaktperson: Partial<Kontaktperson>) =>
    setKenndaten({
      ...kenndaten,
      Herausgeber: {
        ...kenndaten.Herausgeber,
        person: {
          ...kontaktperson,
          ...updatedKontaktperson,
        },
      },
    });

  const backendKeyPrefix = {
    firma: "Kenndaten.Herausgeber.firma",
    person: "Kenndaten.Herausgeber.person",
  };

  const addressParts = (adresse: any) =>
    `${adresse.strasse || ""}, ${adresse.plz || ""} ${adresse.ort || ""}, ${adresse.land || ""}`;

  const firmaInfo = firma?.name
    ? `Firma: ${firma.name}, ${addressParts(firma.kommunikation?.adresse || {})}`
    : "";

  const kontaktpersonInfo =
    kontaktperson?.vorname || kontaktperson?.nachname
      ? `Kontaktperson: ${kontaktperson.vorname || ""} ${
          kontaktperson.nachname || ""
        }, ${addressParts(kontaktperson.kommunikation?.adresse || {})}`
      : "";

  let previewText;

  if (firmaInfo && kontaktpersonInfo) {
    previewText = `${firmaInfo}; ${kontaktpersonInfo}`;
  } else if (firmaInfo) {
    previewText = firmaInfo;
  } else if (kontaktpersonInfo) {
    previewText = kontaktpersonInfo;
  } else {
    previewText = OHNE_INHALT;
  }

  return (
    <ABKControlledExpansionPanel
      title={
        <ExpansionPanelTitle
          title="Herausgeber"
          previewText={previewText}
          shouldShowPreview={!isOpen}
        />
      }
      expanded={isOpen}
      onAction={updateSections}
    >
      <ABKForm
        title="Firma"
        sections={[
          {
            formElements: [
              <ABKFormRow>
                <ABKFormFieldInput
                  editorId="name"
                  label="Name"
                  value={firma.name || ""}
                  backendKey={`${backendKeyPrefix.firma}.name`}
                  setValue={
                    readOnly
                      ? undefined
                      : (value) => updateFirma({ name: value })
                  }
                />
              </ABKFormRow>,
              <ABKAddressSelect
                value={firma.kommunikation?.adresse || {}}
                setValue={(value) =>
                  updateFirma({
                    kommunikation: {
                      ...firma.kommunikation,
                      adresse: {
                        ...firma.kommunikation?.adresse,
                        ...value,
                      },
                    },
                  })
                }
                readOnly={readOnly}
                backendKeyPrefix={backendKeyPrefix.firma}
              />,
              <ABKKommunikationSelect
                value={firma.kommunikation || {}}
                setValue={(value) => updateFirma({ kommunikation: value })}
                readOnly={readOnly}
                backendKeyPrefix={backendKeyPrefix.firma}
              />,
            ],
          },
        ]}
      />
      <ABKForm
        title="Kontaktperson"
        sections={[
          {
            formElements: [
              <ABKFormRow>
                <ABKFormFieldInput
                  editorId="vorname"
                  label="Vorname"
                  value={kontaktperson.vorname || ""}
                  setValue={
                    readOnly
                      ? undefined
                      : (value) => updateKontaktperson({ vorname: value })
                  }
                  backendKey={`${backendKeyPrefix.person}.vorname`}
                />
                <ABKFormFieldInput
                  editorId="nachname"
                  label="Nachname"
                  value={kontaktperson.nachname || ""}
                  setValue={
                    readOnly
                      ? undefined
                      : (value) => updateKontaktperson({ nachname: value })
                  }
                  backendKey={`${backendKeyPrefix.person}.nachname`}
                />
              </ABKFormRow>,
              <ABKAddressSelect
                value={kontaktperson.kommunikation?.adresse || {}}
                setValue={(value) =>
                  updateKontaktperson({
                    kommunikation: {
                      ...kontaktperson.kommunikation,
                      adresse: {
                        ...kontaktperson.kommunikation?.adresse,
                        ...value,
                      },
                    },
                  })
                }
                readOnly={readOnly}
                backendKeyPrefix={backendKeyPrefix.person}
              />,
              <ABKKommunikationSelect
                value={kontaktperson.kommunikation || {}}
                setValue={(value) =>
                  updateKontaktperson({ kommunikation: value })
                }
                readOnly={readOnly}
                backendKeyPrefix={backendKeyPrefix.person}
              />,
            ],
          },
        ]}
      />
    </ABKControlledExpansionPanel>
  );
};

export default HerausgeberForm;
