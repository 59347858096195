import Highlighter from "react-highlight-words";

type Props = {
  filterValue: string;
  text: string;
  isBold?: boolean;
  isGreen?: boolean;
};

const ABKBasisGridHighlighter = ({
  filterValue,
  text,
  isBold = false,
  isGreen = false,
}: Props) => {
  const cellContentStyle = {
    fontWeight: isBold || isGreen ? "bold" : "normal",
    color: isGreen ? "#999900" : "black",
  };

  return (
    <span style={cellContentStyle} title={text}>
      <Highlighter
        searchWords={[filterValue]}
        textToHighlight={text}
        /*
      Both of these classes are here to have a consistent font-size
      between the highlighted and unhighlighted cells
      */
        highlightClassName="k-table-sm"
        unhighlightClassName="k-table-sm"
      />
    </span>
  );
};

export default ABKBasisGridHighlighter;
