import { WrapperEintragBearbeitenDialog } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog";
import {
  EintraegePageStoreProvider,
  useEintraegeStore,
} from "src/modules/Datei/pages/Parameterlisten/Eintraege/useEintraegeStore";
import useInitiatePageWithContainerData from "../../useInitiatePageWithContainerData";
import { WrapperNewItemDialog } from "./Dialoge/NewItemDialog";
import PLEintraegeGrid from "./PLEintraegeGrid";
import { SaveType } from "./SaveTypeEnum";

type Props = {
  db: string;
  contId: string;
  plID: string;
  itemId: string;
};

export const EintraegePage = (props: Props) => (
  <EintraegePageStoreProvider>
    <EintraegePageWithStore {...props} />
  </EintraegePageStoreProvider>
);

const EintraegePageWithStore = ({ db, contId, plID, itemId }: Props) => {
  const { currentEintrag, openDialog, showNewItemDialog, newItemSaveType } =
    useEintraegeStore();

  useInitiatePageWithContainerData(db, contId);

  return (
    <>
      <PLEintraegeGrid
        dbName={db}
        contId={contId}
        plID={plID}
        itemId={itemId}
      />
      {openDialog && <WrapperEintragBearbeitenDialog />}
      {showNewItemDialog && (
        <WrapperNewItemDialog
          db={db}
          contId={contId}
          plID={plID}
          saveType={newItemSaveType}
          beforeItemId={
            newItemSaveType === SaveType.InsertBefore
              ? currentEintrag?.ITEMID
              : undefined
          }
        />
      )}
    </>
  );
};
