import { Button } from "@progress/kendo-react-buttons";
import {
  RadioButton,
  RadioButtonChangeEvent,
} from "@progress/kendo-react-inputs";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { ABKLoader } from "src/abk-shared/components/atoms/ABKLoader/ABKLoader";
import ABKExpansionPanel from "../../../../abk-shared/components/atoms/ABKExpansionPanel";

const ServerStatusPage = () => {
  const [selectedValue, setSelectedValue] = React.useState("normal-beenden");
  const [isKilling, setIsKilling] = React.useState(false);

  const handleChange = React.useCallback(
    (e: RadioButtonChangeEvent) => {
      setSelectedValue(e.value);
    },
    [setSelectedValue]
  );

  const rebootMutation = useMutation({
    mutationFn: () => axios.post("/api/reboot"),
    onMutate: () => setIsKilling(true),
    onSettled: () => setIsKilling(false),
  });

  const killTaskMutation = useMutation({
    mutationFn: () => axios.post("/api/reboot?method=killtask"),
    onMutate: () => setIsKilling(true),
    onSettled: () => setIsKilling(false),
  });

  const onButtonClick = () => {
    if (selectedValue === "normal-beenden") {
      rebootMutation.mutate();
    } else if (selectedValue === "task-killen") {
      killTaskMutation.mutate();
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <ABKExpansionPanel title="Server-Neustart" defaultExpanded>
        <div>
          <RadioButton
            name="normal-beenden"
            value="normal-beenden"
            checked={selectedValue === "normal-beenden"}
            label="Backend normal beenden"
            onChange={handleChange}
          />
          <br />
          <RadioButton
            name="task-killen"
            value="task-killen"
            checked={selectedValue === "task-killen"}
            label="Backend-Task killen (Achtung, Datenverlust!)"
            onChange={handleChange}
          />
          <br />
        </div>
        <div className="k-d-flex k-gap-sm k-flex-wrap k-mt-sm">
          <Button onClick={onButtonClick} fillMode={"solid"}>
            {isKilling ? <ABKLoader /> : "Backend neu starten"}
          </Button>
        </div>
      </ABKExpansionPanel>
    </div>
  );
};

export default ServerStatusPage;
