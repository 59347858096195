import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GridRowSelection } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import { deleteDefaultGridItems } from "src/core/components/organisms/DefaultGrid/useDefaultGridDeleteFunction/deleteDefaultGridItems";
import { dbJobs } from "src/core/db/dbJobs";
import useJobsDisplayed from "src/core/jobs/useJobsDisplayed";
import { findJobsDisplayedForDeletedContainers } from "src/modules/Startseite/hooks/useDeleteCont/findJobsDisplayedForDeletedContainers";
import { QUERY_KEY_GET_CONTAINER } from "src/modules/Startseite/hooks/useGetCont";
import { BACKEND_URL } from "../../../../core/constants/urls";
import { ContWithLastChangedModified } from "../../../../core/interfaces/Cont";

export const useDeleteCont = (
  dbName: string,
  setIsGridLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const jobsDisplayed = useJobsDisplayed(dbName);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (gridRowSelection: GridRowSelection) => {
      setIsGridLoading(true);

      const url = `${BACKEND_URL}/api/db/${dbName}/cont`;
      const itemIdsToDelete = gridRowSelection.selectedItems.map(
        (item) => item.CONTID
      );

      return deleteDefaultGridItems(url, itemIdsToDelete);
    },
    onSuccess: async (_response, gridRowSelection) => {
      /*
        Mache ein Refetch ganz am Ende, um zu vermeiden, dass das Backend
        gleichzeitig löscht und liest die Datenbank. Da hatten wir einmal
        eine Race Condition, in den Playwright-Tests.
      */
      await queryClient.invalidateQueries({
        queryKey: [dbName, QUERY_KEY_GET_CONTAINER],
      });

      const selectedContainers =
        gridRowSelection.selectedItems as ContWithLastChangedModified[];

      const jobsDisplayedToDelete = findJobsDisplayedForDeletedContainers(
        selectedContainers,
        jobsDisplayed
      );

      const keysToDelete = jobsDisplayedToDelete.map(
        (jobDisplayed) => jobDisplayed.job.JOBID
      );
      return dbJobs.jobsDisplayed.bulkDelete(keysToDelete);
    },
    onError: () => {
      setIsGridLoading(false);
    },
  });
};
