import { GridCustomCellProps } from "@progress/kendo-react-grid";
import React from "react";
import { GridColumnPropsCustom } from "../../interfaces/GridColumns";
import { HighlightedCell } from "./HighlightedCell";

type CustomCellFunction = (props: PropsCustomCell) => React.ReactElement;
export type CustomCellObject = {
  columnField: string;
  render: CustomCellFunction;
};

export type CustomCellAdditionalProps = {
  filterValue: string;
  columns: GridColumnPropsCustom[];
  customCells?: CustomCellObject[];
};

export type PropsCustomCell = GridCustomCellProps & CustomCellAdditionalProps;

export default function CustomCell(props: PropsCustomCell) {
  const columnCustomCell = React.useMemo(() => {
    let columnCustomCell: CustomCellFunction | undefined = undefined;
    const customCells = props.customCells;
    if (!customCells || customCells.length === 0) return columnCustomCell;

    for (const customCell of customCells) {
      if (customCell.columnField === props.field)
        columnCustomCell = customCell.render;
    }
    return columnCustomCell;
  }, [props.field, props.customCells]);

  if (columnCustomCell) return (columnCustomCell as CustomCellFunction)(props);

  return <HighlightedCell {...props} />;
}
