import { useEffect } from "react";

interface Props {
  handleSaveRef: React.MutableRefObject<(() => Promise<void>) | null>;
}

export function useSaveEintraegeSelectionChange({ handleSaveRef }: Props) {
  const sendDrafts = async () => {
    if (!handleSaveRef.current) return;

    void handleSaveRef.current();
    handleSaveRef.current = null;
  };

  useEffect(() => {
    return () => {
      void sendDrafts();
    };
  }, []);
}
