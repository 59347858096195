import React from "react";
import { FunctionSetValidationErrors, useValidationError } from ".";
import {
  ABKError,
  ValidationError,
  ValidationErrorMessage,
} from "../../interfaces/Error";

export default function useGetValidationErrorMessage(backendKey?: string) {
  const { validationErrors } = useValidationError();
  return getValidationErrorMessage(validationErrors, backendKey);
}

function getValidationErrorMessage(
  validationErrors: ValidationError[],
  fieldName: string | undefined
) {
  const messages: ValidationErrorMessage[] = validationErrors
    .filter((error) => error.Fieldname === fieldName)
    .map((validationError, index) => {
      const msg: ValidationErrorMessage = {
        message: validationError.title,
        id: index,
      };
      return msg;
    });
  if (messages.length === 0) return undefined;

  return <ValidationErrorText messages={messages} />;
}

const ValidationErrorText = ({
  messages,
}: {
  messages: ValidationErrorMessage[];
}) => (
  <>
    {messages.map((message, index) => (
      <React.Fragment key={index}>
        {message.message}
        <br />
      </React.Fragment>
    ))}
  </>
);

export function setValidationErrorFromABKError(
  error: unknown,
  setValidationErrors: FunctionSetValidationErrors
) {
  const abkError = (error as any)?.response?.data;

  if (!abkError) return;
  if (!abkError.hasOwnProperty("Validations")) return;
  setValidationErrors((abkError as ABKError).Validations);
}
