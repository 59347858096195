import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Versioned } from "src/abk-shared/interfaces/Versioned";
import { QUERY_KEY_GET_SINGLE_EINTRAG } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/useGetSingleEintrag";
import { QUERY_KEY_GET_EINTRAEGE } from "src/modules/Datei/pages/Parameterlisten/Eintraege/useGetEintraege";
import { formatWithEtag } from "src/modules/Datei/queries/formatWithEtag";
import { useValidationError } from "../../../../../../abk-shared/stores/ValidationErrorStoreProvider";
import { setValidationErrorFromABKError } from "../../../../../../abk-shared/stores/ValidationErrorStoreProvider/useGetValidationErrorMessage";
import {
  EintragBackend,
  EintragFrontend,
} from "../../../../interfaces/parameterlisten.types";
import {
  backendEintragToFrontend,
  frontendEintragToBackend,
} from "../convertBackendEintrag";

export const MUTATION_KEY_UPDATE_EINTRAG = "MUTATION_KEY_UPDATE_EINTRAG";

type MutationUpdateEintragData = {
  itemId: string;
  dbName: string;
  contId: string;
  dataId: string;
  eintrag: Versioned<EintragFrontend>;
};

const useUpdateEintrag = () => {
  const queryClient = useQueryClient();
  const { setValidationErrors } = useValidationError();

  const updateDataItem = async (data: MutationUpdateEintragData) => {
    const eintragForBackend = frontendEintragToBackend(data.eintrag.value);
    const response = await axios.put<EintragBackend>(
      `/api/db/${data.dbName}/cont/${data.contId}/pl/${data.dataId}/items/${data.itemId}`,
      eintragForBackend,
      {
        headers: {
          "Content-Type": "application/json",
          "If-Match": data.eintrag.etag,
        },
      }
    );
    return response;
  };

  return useMutation({
    mutationKey: [MUTATION_KEY_UPDATE_EINTRAG],
    mutationFn: (data: MutationUpdateEintragData) => updateDataItem(data),
    onSuccess: async (response) => {
      setValidationErrors([]);

      const data = response.data;
      const frontendEintrag = backendEintragToFrontend(data);

      queryClient.setQueryData(
        [
          QUERY_KEY_GET_SINGLE_EINTRAG,
          data.DBNAME,
          data.CONTID,
          data.DATAID,
          data.ITEMID,
        ],
        formatWithEtag({
          ...response,
          data: frontendEintrag,
        })
      );

      return queryClient.refetchQueries({
        queryKey: [
          QUERY_KEY_GET_EINTRAEGE,
          data.DBNAME,
          data.CONTID,
          data.DATAID,
        ],
      });
    },
    onError: (error) =>
      setValidationErrorFromABKError(error, setValidationErrors),
  });
};

export default useUpdateEintrag;
