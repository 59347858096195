import PageTemplate from "../../PageTemplate";
/*
  Dieser Import ist für die Vorversion, auskommentiert, um einen Spur für die Vorversion
  der Gliederungssysteme zu behalten.
  Wenn die Vorversion für die Gliederungssystem implementiert ist,
  dann kann der folgende Code gelöscht werden, mit diesem Kommentar.
*/
// import useChangePageTitleKenndaten from "./useChangePageTitleKenndaten";
import { AxiosError } from "axios";
import { produce } from "immer";
import _ from "lodash";
import { ABKLoader } from "src/abk-shared/components/atoms/ABKLoader/ABKLoader";
import {
  Gliederungssystem,
  GliederungssystemSubtype,
} from "src/modules/Datei/interfaces/gliederungssysteme.types";
import { Kenndaten } from "src/modules/Datei/interfaces/kenndaten.types";
import { KenndatenPanels } from "src/modules/Datei/pages/components/KenndatenPanels/KenndatenPanels";
import useGetSingleGliederungssystem from "src/modules/Datei/queries/useGetSingleGliederungssystem";
import useSaveGliederungssystem from "src/modules/Datei/queries/useSaveGliederungssystem";
import useDrafts from "../../../../../abk-shared/hooks/useDrafts";
import { Versioned } from "../../../../../abk-shared/interfaces/Versioned";
import {
  GliederungssystemDraft,
  dbDrafts,
} from "../../../../../core/db/dbDrafts";

type Props = { dbName: string; contId: string; gsID: string };
export const GliederungssystemKenndaten = ({ dbName, contId, gsID }: Props) => {
  /*
    Hier wurde die Vorversion auskommentiert. Die sollte nicht für die
    Parameterlisten verfügbar sein. Aber wurde trotzdem implementiert.
    Der folgende Code bleibt da, weil die Vorversion für die Gliederungssysteme
    implementiert sein wird. Und dieser Code wird uns dabei helfen.
  */
  // useChangePageTitleKenndaten(isPreviousVersion, dbName, contId);

  const {
    data: gliederungssystem,
    isLoading,
    isFetching,
    error: parameterlisteLoadingError,
    refetch,
  } = useGetSingleGliederungssystem(dbName, contId, gsID);

  const { mutateAsync } = useSaveGliederungssystem();

  const is404 =
    parameterlisteLoadingError instanceof AxiosError &&
    parameterlisteLoadingError.status === 404;

  const {
    isEdited,
    handleReset,
    handleSave,
    pageState,
    updatePageState,
    sections,
    updateSections,
    conflictMessage,
  } = useDrafts<Gliederungssystem, GliederungssystemDraft, [string, string]>({
    is404,
    isFetching,
    initialPageState: gliederungssystem,
    initialSections: new Map([
      ["allgemein", true],
      ["herausgeber", false],
      ["beschreibung", false],
      ["zuordnungen", false],
    ]),
    backend: {
      doSave: async (gliederungssystem: Versioned<Gliederungssystem>) =>
        mutateAsync({
          db: dbName,
          contId,
          gliederungssystem,
        }),
      doRefetch: refetch,
    },
    drafts: {
      key: (pl: Gliederungssystem) => [pl.CONTID, pl.DATAID],
      new: (
        data: Versioned<Gliederungssystem>,
        sections: Map<string, boolean>
      ): GliederungssystemDraft => ({
        database: dbName,
        contId: data.value.CONTID,
        dataId: data.value.DATAID,
        etag: data.etag,
        edited: _.cloneDeep(data.value),
        sections: sections,
      }),
      table: dbDrafts.gliederungssystemDrafts,
    },
  });

  if (isLoading || !pageState) return <ABKLoader />;

  const setKenndaten = (kenndaten: Kenndaten) => {
    const newPageStateValue = produce(pageState.value, (draft) => {
      draft.Kenndaten = kenndaten;
    });
    return updatePageState(newPageStateValue);
  };

  const setGliederungssystemSubtype = (subtype: GliederungssystemSubtype) => {
    const newPageStateValue = produce(pageState.value, (draft) => {
      draft.SUBTYPE = subtype;
    });
    return updatePageState(newPageStateValue);
  };

  /*
    `readOnly` ist hier absichtlich als `false` definiert.
    Vorher war hier die folgende Zeile:
    `const readOnly = pageState.value.Kenndaten.STATUS === ParameterlisteStatus.Freigegeben;`
    Der Grund ist, wir wollen dieses Feature mit `readOnly` für jetzt ausschalten.
    Dieses Feature wird später implementiert werden. Aber jetzt wollen wir nicht
    nur in einer Stelle es implementieren. Daher haben wir es ausgeschaltet.
    Mehr dazu hier: https://ib-data.atlassian.net/browse/ABK9-970
  */
  const readOnly = false;

  return (
    <PageTemplate
      db={dbName}
      contId={contId}
      balkenProps={{
        edited: is404 || isEdited,
        onSave: handleSave,
        onReset: handleReset,
        conflictMessage,
      }}
    >
      <KenndatenPanels
        dbName={dbName}
        contId={contId}
        kenndaten={pageState.value.Kenndaten}
        setKenndaten={setKenndaten}
        readOnly={readOnly}
        sections={sections}
        updateSections={updateSections}
        gliederungssystemSubtype={pageState.value.SUBTYPE}
        setGliederungssystemSubtype={setGliederungssystemSubtype}
      />
    </PageTemplate>
  );
};
