import ABKListView from "src/abk-shared/components/organisms/ABKListView";
import { GliederungssystemEintrag } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import { PropsZuordnungPanel } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel";
import { ZuordnungSelectionFunctions } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/hooks/useZuordnungSelection";

type Props = PropsZuordnungPanel & {
  zuordnungSelection: ZuordnungSelectionFunctions;
};

const ZuordnungSummary = ({
  zuordnungSelection,
  pageState,
  setPageState,
  zuordnungType,
}: Props) => (
  <ABKListView
    data={zuordnungSelection.selectedItems}
    setData={(itemsAfterDelete: GliederungssystemEintrag[]) => {
      setPageState({
        ...pageState,
        [zuordnungType]: itemsAfterDelete.map((item) => ({
          ItemID: item.ITEMID,
        })),
      });
    }}
    tools={{
      addItem: false,
      deleteAll: false,
      dragDrop: false,
    }}
    dataToDisplay={[
      {
        key: "NUMMER",
        name: "Nummer",
        type: "text",
      },
      {
        name: "Bezeichnung",
        key: "BEZ",
        type: "text",
      },
    ]}
  />
);

export default ZuordnungSummary;
