import { PromiseExtended } from "dexie";
import { GliederungssystemSubtype } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import { Kenndaten } from "src/modules/Datei/interfaces/kenndaten.types";
import AllgemeinForm from "src/modules/Datei/pages/components/KenndatenPanels/Allgemein/AllgemeinForm";
import BeschreibungForm from "src/modules/Datei/pages/components/KenndatenPanels/Beschreibung/BeschreibungForm";
import HerausgeberForm from "src/modules/Datei/pages/components/KenndatenPanels/Herausgeber/HerausgeberForm";
import ZuordnungenForm from "src/modules/Datei/pages/components/KenndatenPanels/Zuordnungen/ZuordnungenForm";
import "./styles.scss";

type CommonProps = {
  kenndaten: Kenndaten;
  setKenndaten: (kenndaten: Kenndaten) => Promise<void>;
  readOnly: boolean;
  updateSections: (
    newSections: Map<string, boolean>
  ) => PromiseExtended<[string, string]> | undefined;
};

export type GliederungssystemProps = {
  gliederungssystemSubtype?: GliederungssystemSubtype;
  setGliederungssystemSubtype?: (
    gliederungssystemSubtype: GliederungssystemSubtype
  ) => Promise<void>;
};

type KenndatenPanelsProps = CommonProps &
  GliederungssystemProps & {
    dbName: string;
    contId: string;
    sections: Map<string, boolean>;
  };

export type KenndatenFormProps = CommonProps & {
  isOpen: boolean;
  updateSections: () => void;
};

export const KenndatenPanels = ({
  dbName,
  contId,
  kenndaten,
  setKenndaten,
  sections,
  updateSections,
  readOnly,
  gliederungssystemSubtype,
  setGliederungssystemSubtype,
}: KenndatenPanelsProps) => (
  <>
    <AllgemeinForm
      kenndaten={kenndaten}
      setKenndaten={setKenndaten}
      isOpen={sections.get("allgemein") ?? false}
      updateSections={() => {
        const newSections = new Map(sections);
        newSections.set("allgemein", !sections.get("allgemein"));
        return updateSections(newSections);
      }}
      readOnly={readOnly}
      gliederungssystemSubtype={gliederungssystemSubtype}
      setGliederungssystemSubtype={setGliederungssystemSubtype}
    />
    <HerausgeberForm
      kenndaten={kenndaten}
      setKenndaten={setKenndaten}
      isOpen={sections.get("herausgeber") ?? false}
      updateSections={() => {
        const newSections = new Map(sections);
        newSections.set("herausgeber", !sections.get("herausgeber"));
        return updateSections(newSections);
      }}
      readOnly={readOnly}
    />
    <BeschreibungForm
      kenndaten={kenndaten}
      setKenndaten={setKenndaten}
      isOpen={sections.get("beschreibung") ?? false}
      updateSections={() => {
        const newSections = new Map(sections);
        newSections.set("beschreibung", !sections.get("beschreibung"));
        return updateSections(newSections);
      }}
      readOnly={readOnly}
    />
    <ZuordnungenForm
      db={dbName}
      contId={contId}
      kenndaten={kenndaten}
      setKenndaten={setKenndaten}
      isOpen={sections.get("zuordnungen") ?? false}
      updateSections={() => {
        const newSections = new Map(sections);
        newSections.set("zuordnungen", !sections.get("zuordnungen"));
        return updateSections(newSections);
      }}
      readOnly={readOnly}
    />
  </>
);
