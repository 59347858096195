import { ABKLoader } from "src/abk-shared/components/atoms/ABKLoader/ABKLoader";
import ABKBasisGrid, {
  ABKBasisGridProps,
} from "src/abk-shared/components/organisms/ABKBasisGrid";
import DeleteDialog from "src/core/components/organisms/DefaultGrid/useDefaultGridDeleteFunction/DeleteDialog";
import { useDefaultGridDeleteFunction } from "src/core/components/organisms/DefaultGrid/useDefaultGridDeleteFunction/useDefaultGridDeleteFunction";
import {
  CreateDefaultGridActionsFunction,
  DeleteItemsMutation,
} from "src/core/components/organisms/DefaultGrid/useDefaultGridDeleteFunction/useDefaultGridDeleteFunction.types";
import {
  FunctionGenerateQuickInfoForItem,
  useDefaultGridSelection,
} from "src/core/components/organisms/DefaultGrid/useDefaultGridSelection/useDefaultGridSelection";

type Props = Omit<ABKBasisGridProps, "gridActions"> & {
  deleteItemsMutation?: DeleteItemsMutation;
  gridActions?: CreateDefaultGridActionsFunction;
  isLoading?: boolean;
  generateQuickInfoForOneItem?: FunctionGenerateQuickInfoForItem;
};

export function DefaultGrid(props: Props) {
  const { selection, selectedItems } = useDefaultGridSelection(
    props.generateQuickInfoForOneItem,
    props.selection,
    props.dataItemKey
  );

  const {
    isDeleteDialogOpen,
    toggleDeleteDialog,
    deleteDialogPromise,
    gridActionsWithDeleteFunction,
  } = useDefaultGridDeleteFunction(
    props.deleteItemsMutation,
    props.dataItemKey,
    props.gridActions
  );

  /*
    Wir re-rendern das Grid bei `isLoading`.
    Wenn wir eine Aktion ausführen, die die Daten im Grid ändert, wird das Grid
    defaultmäßig re-rendern.
    Die Scroll-Position wird dadurch zurückgesetzt: die Scrollbar wird am ersten
    Item sein. Das ist nicht gut, insbesondere für lange Listen.
    Wir wollen die Scroll-Position behalten, also zeigen wir nicht das Grid während wir
    die neue Liste abfragen.

    Wenn wir das Grid gezeigt hätten, wäre die Scroll-Position korrekt eingestellt,
    aber der User würde merken, dass die Scrollbar von der ersten Zeile zur vorherigen
    Scroll-Position springt.
    Um das zu vermeiden, zeigen wir das Grid nicht, und wir initialisieren das Grid mit
    der vorherigen Scroll-Position.
    https://ib-data.atlassian.net/browse/ABK9-826
  */
  if (props.isLoading) return <ABKLoader />;

  return (
    <>
      <ABKBasisGrid
        {...props}
        gridActions={gridActionsWithDeleteFunction}
        selection={selection}
      />
      {isDeleteDialogOpen && (
        <DeleteDialog
          toggleDialog={toggleDeleteDialog}
          deleteDialogPromise={deleteDialogPromise}
          selectedItems={selectedItems}
        />
      )}
    </>
  );
}
