import { GridFieldWithInternalValue } from "src/abk-shared/components/organisms/ABKBasisGrid/constants";
import { Meta } from "src/core/interfaces/meta.types";
import {
  Kenndaten,
  KenndatenFrontend,
} from "src/modules/Datei/interfaces/kenndaten.types";
import {
  ItemType,
  ItemTypeDisplayed,
} from "../pages/Parameterlisten/Eintraege/maps";

export interface Parameterliste {
  Meta: Meta;
  DBNAME: string;
  CONTID: string;
  DATAID: string;
  SUBTYPE: string;
  Kenndaten: Kenndaten;
  NOTIZ: string;
  ANMERK: string;
  REFCONTID: string;
  REFDATAID: string;
  ONDTEXP: boolean;
}

export type ParameterlisteFrontend = Omit<Parameterliste, "Kenndaten"> & {
  Kenndaten: KenndatenFrontend;
};

export enum EintragBerechnungsart {
  keine = "Keine (Eingabe Kennwert)",
  summeBilden = " Summe bilden",
}

interface EintragBase {
  Meta?: {
    InstanceApiUrl: string;
    Rowchanged: string;
    Right: string;
  };
  DBNAME: string;
  CONTID: string;
  DATAID: string;
  ITEMID: string;
  BEZ: string;
  KOMMENTAR: string;
  Funktion: GliederungssystemEintragId[];
  Produkt: GliederungssystemEintragId[];
  VARNAME: string;
  PIKTID: string;
  IFCReferenzen:
    | {
        Entity: string;
        Set: string;
        Property: string;
      }[]
    | null;
  EH: string;
  NUMMIN: number;
  NUMMAX: number;
  FORMEL: string;
  AWUSEREN: boolean;
}

export type GliederungssystemEintragId = { ItemID: string };

export type EintragBackend = EintragBase & {
  ITEMTYPE: ItemType;
  FAVORIT: boolean;
  REQUIRED: boolean;
  SUMMEBILDEN: boolean;
  MENGENABH: boolean;
  NUMNK: number;
  NUMMIN: number;
  NUMMAX: number;
  SHOWIF: ShowIf;
  AWList_Num: number[];
  AWList_Text: AWList_Text[] | null;
};
export enum ShowIf {
  ueberallAnzeigen = "",
  hoehererEbene = "Pos",
  niedrigerEbene = "Sum",
  immer = "X",
}

export type EintragFrontend = EintragBase & {
  ITEMTYPE: ItemTypeDisplayed;
  FAVORIT: EintragCheckboxValue;
  REQUIRED: EintragCheckboxValue;
  SUMMEBILDEN: EintragCheckboxValue;
  MENGENABH: EintragCheckboxValue;
  NUMNK: GridFieldWithInternalValue;
  NUMMIN: GridFieldWithInternalValue;
  NUMMAX: GridFieldWithInternalValue;
  SHOWIF: ShowIfDisplayed;
  AWList_Text_string: string;
  AWList_Num_string: string;
  Funktion_string: string;
  Produkt_string: string;
  AWList_Num: { Wert: number; id: string }[];
  AWList_Text: { Eintrag: string; id: string; Bezeichnung: string }[] | null;
};

export enum ShowIfDisplayed {
  ueberallAnzeigen = "Überall anzeigen (ÖNORM)",
  hoehererEbene = "Bei höherer Ebene (Summendarstellung) ausblenden",
  niedrigerEbene = "Bei niedrigerer Ebene (Positionswerte) ausblenden",
  immer = "Immer ausblenden",
}

export enum EintragCheckboxValue {
  selected = "Selektiert",
  notSelected = "Nicht selektiert",
}

export interface AWList_Text {
  Eintrag: string;
  Bezeichnung: string;
}
