import { produce } from "immer";
import React from "react";
import { CreateGridActionsFunction } from "src/abk-shared/components/organisms/ABKBasisGrid/interfaces/GridAction";
import { useQuickInfoState } from "src/abk-shared/stores/useQuickInfoState";
import { DialogResponse } from "src/core/components/organisms/DefaultGrid/useDefaultGridDeleteFunction/DeleteDialog";
import { itemsAreDisplayedInQuickInfo } from "src/core/components/organisms/DefaultGrid/useDefaultGridDeleteFunction/removeInfoInSideBar";
import {
  CreateDefaultGridActionsFunction,
  DeleteFunctionDefaultGrid,
  DeleteItemsMutation,
} from "src/core/components/organisms/DefaultGrid/useDefaultGridDeleteFunction/useDefaultGridDeleteFunction.types";

export function useDefaultGridDeleteFunction(
  deleteItemsMutation: DeleteItemsMutation | undefined,
  dataItemKey: string,
  gridActions: CreateDefaultGridActionsFunction | undefined
) {
  const [deleteDialogPromise, setDeleteDialogPromise] = React.useState<{
    resolve: (value: DialogResponse) => void;
  } | null>(null);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const toggleDeleteDialog = React.useCallback(
    () => setIsDeleteDialogOpen(!isDeleteDialogOpen),
    [isDeleteDialogOpen]
  );

  const { resetQuickInfo, variableDisplayed } = useQuickInfoState();

  const defaultGridDeleteFunction: DeleteFunctionDefaultGrid =
    React.useCallback(
      async (_event, gridRowSelection) => {
        toggleDeleteDialog();

        const { shouldProceed } = await new Promise<DialogResponse>(
          (resolve) => {
            setDeleteDialogPromise({ resolve });
          }
        );
        if (!shouldProceed) return;

        deleteItemsMutation?.mutate(gridRowSelection, {
          onSuccess: (_data, gridRowSelection) => {
            const selectedItems = gridRowSelection.selectedItems;

            const newSelectedState = produce(
              gridRowSelection.selectedState,
              (draft) => {
                for (const item of selectedItems)
                  delete draft[item[dataItemKey]];
              }
            );
            gridRowSelection.setSelectedState(newSelectedState);

            if (itemsAreDisplayedInQuickInfo(selectedItems, variableDisplayed))
              resetQuickInfo();
          },
        });
      },
      [
        dataItemKey,
        deleteItemsMutation,
        resetQuickInfo,
        toggleDeleteDialog,
        variableDisplayed,
      ]
    );

  const gridActionsWithDeleteFunction = gridActions
    ? (((gridRowSelection, dataResult, dataState) =>
        gridActions(
          gridRowSelection,
          dataResult,
          dataState,
          defaultGridDeleteFunction
        )) as CreateGridActionsFunction)
    : undefined;

  return {
    isDeleteDialogOpen,
    toggleDeleteDialog,
    deleteDialogPromise,
    gridActionsWithDeleteFunction,
  };
}
