import "./style.css";

type Props = {
  children: React.ReactNode;
  className?: string;
  ["data-testid"]?: string;
};

export default function ABKFormFieldInputWrapper({
  children,
  className,
  ["data-testid"]: testid,
}: Props) {
  return (
    <div className={createClassName(className)} data-testid={testid}>
      {children}
    </div>
  );
}

function createClassName(className?: string) {
  let finalClassName = `abk-form-field-input-wrapper test-e2e-abk-form-field-input-wrapper`;

  if (!className) return finalClassName;

  return `${finalClassName} ${className}`;
}
