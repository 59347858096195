import { useParams } from "react-router-dom";
import ValidationErrorStoreProvider from "../../../../../abk-shared/stores/ValidationErrorStoreProvider";
import { useIsValidDbAndContIdInURL } from "../../../../../core/utility/useIsValidDB";
import { ParameterlisteKenndaten } from "./ParameterlisteKenndaten";

export function ParameterlisteKenndatenWrapper() {
  const { isValid, db, contId } = useIsValidDbAndContIdInURL();
  const { plID } = useParams();

  if (!isValid || !plID) return <></>;

  return (
    <ValidationErrorStoreProvider>
      <ParameterlisteKenndaten
        key={`parameterliste-kenndaten-${db}-${contId}-${plID}`}
        dbName={db!}
        contId={contId!}
        plID={plID}
      />
    </ValidationErrorStoreProvider>
  );
}
