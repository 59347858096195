import { JobDisplayed } from "src/core/db/dbJobs";
import { ContWithLastChangedModified } from "src/core/interfaces/Cont";

export function findJobsDisplayedForDeletedContainers(
  deletedContainers: ContWithLastChangedModified[],
  jobsDisplayed: JobDisplayed[] | undefined
) {
  if (!Array.isArray(deletedContainers)) return [];
  if (!jobsDisplayed) return [];

  const jobsToDelete: JobDisplayed[] = [];

  for (const jobDisplayed of jobsDisplayed) {
    for (const container of deletedContainers) {
      if (jobDisplayed.job.ContInfo?.CONTID === container.CONTID) {
        jobsToDelete.push(jobDisplayed);
        break;
      }
    }
  }

  return jobsToDelete;
}
