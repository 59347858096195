import { FunctionOnSelectionChange } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import { OHNE_INHALT } from "src/abk-shared/constants/text";
import { GenericObject } from "src/abk-shared/interfaces/GenericObject";
import { QuickinfoContent } from "../../../../../abk-shared/components/organisms/ABKSideBar/QuickInfo";
import { useQuickInfoState } from "../../../../../abk-shared/stores/useQuickInfoState";

export function useQuickInfoOnGridSelection(
  generateQuickInfoForOneItem?: (item: any) => Promise<QuickinfoContent>
) {
  const generateQuickInfo = generateQuickInfoForOneItem ?? (() => OHNE_INHALT);

  const { setQuickInfo, resetQuickInfo } = useQuickInfoState();

  const changeQuickInfo: FunctionOnSelectionChange = async (
    selectedItems: GenericObject[],
    lastClickedItem?: GenericObject
  ) => {
    resetQuickInfo();
    if (!lastClickedItem) {
      return;
    }
    const quickInfo = await generateQuickInfo(lastClickedItem);
    setQuickInfo(quickInfo);
  };

  return { changeQuickInfo };
}
