import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import PiktogrammDropDown from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/PiktogrammDropDown";
import PiktogrammVorschau from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/PiktogrammVorschau";
import { PiktogrammItem } from "src/modules/Datei/pages/Parameterlisten/Piktogramme/piktogramm.types";
import ABKFormRow from "../../../../../../../abk-shared/components/atoms/ABKFormRow";
import ABKForm from "../../../../../../../abk-shared/components/molecules/ABKForm";

interface PiktogrammFormProps {
  selectedPiktogramm: PiktogrammItem;
  piktogramme: PiktogrammItem[];
  onChange: (event: DropDownListChangeEvent) => void;
}
export default function PiktogrammForm({
  selectedPiktogramm,
  piktogramme,
  onChange,
}: PiktogrammFormProps) {
  return (
    <ABKForm
      sections={[
        {
          formElements: [
            <ABKFormRow>
              <PiktogrammDropDown
                selectedPiktogramm={selectedPiktogramm}
                piktogramme={piktogramme}
                onChange={onChange}
              />
              <PiktogrammVorschau piktogramm={selectedPiktogramm} />
            </ABKFormRow>,
          ],
        },
      ]}
    />
  );
}
