import { OHNE_INHALT } from "src/abk-shared/constants/text";
import { GliederungssystemEintrag } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import {
  EintragFrontend,
  GliederungssystemEintragId,
} from "src/modules/Datei/interfaces/parameterlisten.types";

export function generateSummaryGsEintragNummer(
  data: GliederungssystemEintrag[]
) {
  if (!data || data.length == 0) return OHNE_INHALT;
  const numbers = data.map((gsEintrag) => gsEintrag.NUMMER);
  return numbers.join("; ");
}

export function generatePreviewGsEintragNummer(
  alleGsEintraege: GliederungssystemEintrag[],
  zugeordneteGsEintraege: GliederungssystemEintragId[]
) {
  if (alleGsEintraege.length === 0 || zugeordneteGsEintraege.length == 0)
    return "";
  const nummern = alleGsEintraege.map((gsEintrag) => {
    if (zugeordneteGsEintraege.some((z) => z.ItemID === gsEintrag.ITEMID)) {
      return gsEintrag.NUMMER;
    }
    return false;
  });
  return nummern.filter(Boolean).join("; ");
}

export function generatePreviewTextOfStrings(
  strings: (string | undefined)[],
  differentDefault: string = OHNE_INHALT
) {
  if (strings.length === 0) return differentDefault;

  return strings.filter((str) => str !== "").join("; ");
}

export function generatePreviewTextForAuswahlliste(
  feEintrag: EintragFrontend
): string {
  const previewText = feEintrag.AWList_Num_string;
  if (!previewText) {
    return OHNE_INHALT;
  }

  return previewText;
}
