import { Checkbox } from "@progress/kendo-react-inputs";
import { GridColumnPropsCustom } from "../../interfaces/GridColumns";
import { forEachColumns, isColumnShown } from "./columnUtils";

export type FunctionColumnToggleVisibility = (
  columnField: string | undefined
) => void;

export function toggleShowForColumnHavingField(
  columns: GridColumnPropsCustom[],
  columnField: string | undefined
) {
  forEachColumns(columns, (column, hasChildren, _depth, parentColumn) => {
    if (column.field !== columnField) return;

    const show = isColumnShown(column) ? false : true;
    column.show = show;

    if (hasChildren) updateChildrenColumnsShow(column, show);

    const shouldUpdateParentColumn = parentColumn && parentColumn.show !== show;
    if (shouldUpdateParentColumn) updateParentColumnShow(parentColumn, show);
  });
}

function updateChildrenColumnsShow(
  column: GridColumnPropsCustom,
  show: boolean
) {
  const children = column.children as GridColumnPropsCustom[];
  forEachColumns(children, (column) => {
    column.show = show;
  });
}

function updateParentColumnShow(
  parentColumn: GridColumnPropsCustom,
  show: boolean
) {
  if (show) {
    parentColumn.show = show;
    return;
  }

  const siblings = parentColumn.children as GridColumnPropsCustom[];
  const shouldHideParentColumn = siblings.every(
    (sibling) => sibling.show === show
  );

  if (shouldHideParentColumn) parentColumn.show = show;
}

const MARGIN_LEFT_REM = 1.25;

export function createColumnCheckboxes(
  columns: GridColumnPropsCustom[],
  onClick: FunctionColumnToggleVisibility
) {
  const columnCheckboxes: React.ReactElement[] = [];

  forEachColumns(columns, (column, _, depth) => {
    if (column.canBeHidden === false) return;

    const marginLeftRem = MARGIN_LEFT_REM * depth;

    columnCheckboxes.push(
      <div key={column.field} style={{ marginLeft: `${marginLeftRem}rem` }}>
        <Checkbox
          checked={column.show ?? true}
          label={column.title}
          onClick={() => onClick(column.field)}
        />
      </div>
    );
  });

  return columnCheckboxes;
}
