/* eslint-disable no-console */
import { cloneDeep, isArray } from "lodash";
import {
  GridColumnPropsCustom,
  GridColumnsMigrateFunction,
  PersistedColumns,
} from "src/abk-shared/components/organisms/ABKBasisGrid/interfaces/GridColumns";

/**
 * @param maxVersion All versions lower will trigger the overwriting
 * @param props String that contains property names to overwrite for new version
 */
export type OverwriteProps = {
  maxVersion: number;
  props: Array<keyof GridColumnPropsCustom>;
};

/**
 * @param currentVersion newest version, hard coded in code of invoker function
 * @param currentColumnsDefinition Object that was saved in localStorage
 * @param overwriteProps Object that defines if properties should be overwritten or copied from localStorage
 * @returns function migrateColumns
 */
export default function createFunctionToMigrateColumns(
  currentVersion: number,
  currentColumnsDefinition: GridColumnPropsCustom[],
  overwriteProps: OverwriteProps | undefined
) {
  /**
   * function as described in https://zustand.docs.pmnd.rs/integrations/persisting-store-data#migrate
   */
  const migrateColumns: GridColumnsMigrateFunction = (
    persistedColumns,
    version
  ) => {
    const propsToOverwrite = overwriteProps?.props;
    let versionNeedsOverwrittenProps = false;
    if (typeof overwriteProps?.maxVersion === "number") {
      versionNeedsOverwrittenProps = overwriteProps.maxVersion < currentVersion;
    }

    if (version < currentVersion) {
      console.log(
        `migrateColumns function: Version found: ${version}, newest version: ${currentVersion}. `
      );
      if (versionNeedsOverwrittenProps) {
        console.log(
          `Props ${String(propsToOverwrite)} will be ignored from local storage and reset to newest version (${currentVersion}).`
        );
      }
      if (!isArray((persistedColumns as any)?.columns)) {
        // Not migratable: Reset columns to default
        (persistedColumns as PersistedColumns).columns = cloneDeep(
          currentColumnsDefinition
        );
        return persistedColumns as PersistedColumns;
      }
      const oldCopy = cloneDeep((persistedColumns as PersistedColumns).columns);
      // To migrate versions, we set columnsDefinition as default.
      (persistedColumns as PersistedColumns).columns = cloneDeep(
        currentColumnsDefinition
      );
      // Then we look into old persistedColumns (from localStorage) to copy the saved values into new persistedColumns
      oldCopy.forEach((columnCopy) => {
        const currentObj = (persistedColumns as PersistedColumns).columns.find(
          (x) => x.migrationId === columnCopy.migrationId
        );
        if (!currentObj) {
          return;
        }

        // This works because objects are call-by-reference, so we change the original object persistedColumns here
        if (
          !propsToOverwrite?.includes("show") &&
          !versionNeedsOverwrittenProps
        )
          currentObj.show = columnCopy.show;
        if (
          !propsToOverwrite?.includes("manualWidth") &&
          !versionNeedsOverwrittenProps
        )
          currentObj.manualWidth = columnCopy.manualWidth;
        if (
          !propsToOverwrite?.includes("orderIndex") &&
          !versionNeedsOverwrittenProps
        )
          currentObj.orderIndex = columnCopy.orderIndex;

        if (isArray(columnCopy.children)) {
          columnCopy.children.forEach((childCopy: GridColumnPropsCustom) => {
            const currentChild = currentObj.children?.find(
              (x: GridColumnPropsCustom) =>
                x.migrationId === childCopy.migrationId
            ) as GridColumnPropsCustom;
            if (currentChild) {
              if (
                !propsToOverwrite?.includes("show") &&
                !versionNeedsOverwrittenProps
              )
                currentChild.show = childCopy.show;
              if (
                !propsToOverwrite?.includes("manualWidth") &&
                !versionNeedsOverwrittenProps
              )
                currentChild.manualWidth = childCopy.manualWidth;
              if (
                !propsToOverwrite?.includes("orderIndex") &&
                !versionNeedsOverwrittenProps
              )
                currentChild.orderIndex = childCopy.orderIndex;
            }
          });
        }
      });

      return persistedColumns as PersistedColumns;
    }
    console.log(`migrateColumns function: Already newest version`);

    // Newest version
    return persistedColumns as PersistedColumns;
  };

  return migrateColumns;
}
