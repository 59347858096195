import { createDataTree } from "@progress/kendo-react-treelist";
import {
  GliederungssystemEintrag,
  ZuordnungType,
} from "src/modules/Datei/interfaces/gliederungssysteme.types";
import {
  DATA_ITEM_KEY,
  SUB_ITEMS_FIELD,
} from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/constants";
import useGetGliederungssystemEintraege from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/queries/useGetGliederungssystemEinträge";
import zuordnungColumns from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/zuordnungColumns";
import useGetSingleParameterliste from "src/modules/Datei/queries/useGetSingleParameterliste";

export type ZuordnungData = ReturnType<typeof useQueryZuordnungData>;

export default function useQueryZuordnungData(
  db: string,
  contId: string,
  plID: string,
  zuordnungType: ZuordnungType,
  filterValue = ""
) {
  const { data: parameterliste, isLoading: isLoadingParameterliste } =
    useGetSingleParameterliste(db, contId, plID);

  const keyGliederungssystemId =
    zuordnungType === "Funktion" ? "FUNKTGSDATAID" : "PRODGSDATAID";
  const gliederungssystemId =
    parameterliste?.value?.Kenndaten?.[keyGliederungssystemId];

  const enabled = Boolean(gliederungssystemId) && !isLoadingParameterliste;

  const {
    data: gliederungssystemEintraege,
    isLoading: isLoadingGliederungssystemEintraege,
  } = useGetGliederungssystemEintraege(
    db,
    contId,
    gliederungssystemId,
    enabled
  );

  const fieldsFiltered = Object.values(zuordnungColumns).map(
    (column) => column.field as keyof GliederungssystemEintrag
  );
  const eintraege = gliederungssystemEintraege?.Items.filter((eintrag: any) => {
    for (const field of fieldsFiltered) {
      const matchesFilter =
        eintrag[field] &&
        typeof eintrag[field] === "string" &&
        eintrag[field].toLowerCase().includes(filterValue.toLowerCase());

      if (matchesFilter) return true;
    }

    return false;
  });

  const dataTree = createDataTree(
    eintraege ?? [],
    (item) => item[DATA_ITEM_KEY],
    (item) => item.PARENTID,
    SUB_ITEMS_FIELD
  );

  return {
    data: gliederungssystemEintraege?.Items ?? [],
    isLoading: isLoadingParameterliste || isLoadingGliederungssystemEintraege,
    dataTree,
  };
}
