import { useCallback, useEffect, useState } from "react";
import ABKSaveBalken from "src/abk-shared/components/molecules/ABKSaveBalken";
import { WindowCloseDialog } from "src/abk-shared/components/organisms/ABKEigenschaftenfenster/WindowCloseDialog";
import useDrafts, {
  Draft,
  UseDraftsProps,
} from "src/abk-shared/hooks/useDrafts";

type EigenschaftenfensterContentProps<
  FormDataType,
  DraftType extends Draft<FormDataType>,
  KeyType,
> = {
  onClose: () => void;
  draftsProps: UseDraftsProps<FormDataType, DraftType, KeyType>;
  setCloseWindow: (fn: () => void) => void;
  renderChildren: (
    draftValues: ReturnType<typeof useDrafts<FormDataType, DraftType, KeyType>>
  ) => React.ReactNode;
};

export function EigenschaftenfensterContent<
  FormDataType,
  DraftType extends Draft<FormDataType>,
  KeyType,
>({
  onClose,
  draftsProps,
  setCloseWindow,
  renderChildren,
}: EigenschaftenfensterContentProps<FormDataType, DraftType, KeyType>) {
  const draftValues = useDrafts<FormDataType, DraftType, KeyType>(draftsProps);
  const [confirmationDialog, setConfirmationDialog] = useState(false);

  const closeWindow = useCallback(() => {
    if (draftValues.isEdited) {
      setConfirmationDialog(true);
    } else {
      onClose();
    }
  }, [draftValues.isEdited, onClose]);

  useEffect(() => {
    setCloseWindow(() => closeWindow);
  }, [closeWindow, setCloseWindow]);

  const onCancelConfirmationDialog = () => {
    setConfirmationDialog(false);
  };

  const onCancelCloseWindow = useCallback(async () => {
    await draftValues.handleReset();
    setConfirmationDialog(false);
    onClose();
  }, [draftValues, onClose]);

  const handleSave = useCallback(async () => {
    await draftValues.handleSave();
    setConfirmationDialog(false);
    onClose();
  }, [draftValues, onClose]);

  return (
    <>
      {confirmationDialog && (
        <WindowCloseDialog
          message="Wollen Sie die Änderungen speichern?"
          onCloseDialog={onCancelConfirmationDialog}
          onSaveDialog={handleSave}
          onCancelDialog={onCancelCloseWindow}
          onCloseWindow={onClose}
        />
      )}

      <div className="wrapper-content-and-balken">
        <div className="window-content">{renderChildren(draftValues)}</div>

        <div className="save-balken-container">
          <ABKSaveBalken
            edited={draftValues.isEdited}
            onSave={handleSave}
            onReset={onCancelCloseWindow}
            isInline
            saveButtonText="Speichern"
            conflictMessage={draftValues.conflictMessage}
          />
        </div>
      </div>
    </>
  );
}
