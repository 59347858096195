import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useHotkeys } from "react-hotkeys-hook";
import "./style.scss";

type Props = {
  message: React.ReactNode;
  onCloseDialog: () => void;
  onSaveDialog: () => void;
  onCancelDialog: () => void;
  onCloseWindow: () => void;
};

export const WindowCloseDialog = ({
  message,
  onCloseDialog,
  onSaveDialog,
  onCancelDialog,
  onCloseWindow,
}: Props) => {
  useHotkeys(
    "S",
    () => {
      onSaveDialog();
    },
    {},
    [onSaveDialog]
  );

  useHotkeys(
    "V",
    () => {
      onCancelDialog();
    },
    {},
    [onCancelDialog]
  );

  return (
    <Dialog
      title="Bestätigen"
      onClose={onCloseDialog}
      className="confirmation-dialog"
    >
      <div data-testid="content-of-dialog-close-eigenschaftenfenster">
        {message}
      </div>
      <DialogActionsBar>
        <Button onClick={onSaveDialog}>Speichern</Button>
        <Button onClick={onCancelDialog}>Verwerfen</Button>
        <Button onClick={onCloseWindow}>Entwurf anlegen</Button>
      </DialogActionsBar>
    </Dialog>
  );
};
