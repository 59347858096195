import { useState } from "react";
import ABKControlledExpansionPanel from "src/abk-shared/components/atoms/ABKControlledExpansionPanel";
import ExpansionPanelTitle from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/ExpansionPanelTitle";
import generatePreviewTextForListOfIFields from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/previewTextGenerator";
import { ABKLoader } from "src/abk-shared/components/atoms/ABKLoader/ABKLoader";
import ABKFormFieldInput from "../../../../abk-shared/components/atoms/ABKFormFieldInput";
import ABKFormRow from "../../../../abk-shared/components/atoms/ABKFormRow";
import useGetVersion from "../../../Startseite/hooks/useGetVersion";
import "./style.css";

const SysInfoPage = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  const { data, isLoading } = useGetVersion();

  if (isLoading) {
    return <ABKLoader />;
  }

  if (!data) {
    return null;
  }

  const sysInfoFields = [
    {
      label: "Serverversion",
      value: data?.ServerVersion || "",
      isCheckbox: false,
    },
    {
      label: "Hostinformationen",
      value: data?.HostInfo || "",
      isCheckbox: false,
    },
    {
      label: "Redis-Server",
      value: data?.RedisServer || "",
      isCheckbox: false,
    },
    {
      label: "Portal-Server",
      value: data?.PortalServer || "",
      isCheckbox: false,
    },
  ];
  const sysInfoPreview = generatePreviewTextForListOfIFields(sysInfoFields);

  return (
    <div style={{ width: "100%" }}>
      <ABKControlledExpansionPanel
        expanded={isExpanded}
        onAction={() => setIsExpanded(!isExpanded)}
        title={
          <ExpansionPanelTitle
            title="Systeminformationen"
            previewText={sysInfoPreview}
            shouldShowPreview={!isExpanded}
          />
        }
      >
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="InfoCreated"
            label="Information erstellt"
            value={data?.InfoCreated}
          />
          <ABKFormFieldInput
            editorId="ServerInfo"
            label="Serverinformationen"
            value={data?.ServerInfo}
          />
        </ABKFormRow>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="ServerVersion"
            label="Serverversion"
            value={data?.ServerVersion}
          />
          <ABKFormFieldInput
            editorId="ServerExe"
            label="Server-Executable"
            value={data?.ServerExe}
          />
        </ABKFormRow>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="HostInfo"
            label="Hostinformationen"
            value={data?.HostInfo}
          />
          <ABKFormFieldInput
            editorId="RedisServer"
            label="Redis-Server"
            value={data?.RedisServer}
          />
        </ABKFormRow>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="PortalServer"
            label="Portal-Server"
            value={data?.PortalServer}
          />
          <ABKFormFieldInput
            editorId="Client"
            label="Client"
            value={data?.Client}
          />
        </ABKFormRow>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="UserAgent"
            label="User-Agent"
            value={data?.UserAgent}
          />
          <ABKFormFieldInput
            editorId="Language"
            label="Sprache"
            value={data?.Language}
          />
        </ABKFormRow>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="Tenantname"
            label="Tenantname"
            value={data?.Tenantname}
          />
          <ABKFormFieldInput
            editorId="TenantnameDisplay"
            label="Anzeigename des Tenants"
            value={data?.TenantnameDisplay}
          />
        </ABKFormRow>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="AuthUser"
            label="Authentifizierter Benutzer"
            value={data?.AuthUser}
          />
          <ABKFormFieldInput
            editorId="AuthUserDisplay"
            label="Anzeigename des Benutzers"
            value={data?.AuthUserDisplay}
          />
        </ABKFormRow>
      </ABKControlledExpansionPanel>
    </div>
  );
};

export default SysInfoPage;
