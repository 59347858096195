import { Loader } from "@progress/kendo-react-indicators";
import _ from "lodash";
import { ABKEigenschaftenfensterWithDrafts } from "src/abk-shared/components/organisms/ABKEigenschaftenfenster/ABKEigenschaftenfensterWithDrafts";
import { Versioned } from "src/abk-shared/interfaces/Versioned";
import ValidationErrorStoreProvider from "src/abk-shared/stores/ValidationErrorStoreProvider";
import { dbDrafts, EintragDraft } from "src/core/db/dbDrafts";
import { useRemoveParam } from "src/core/utility/useRemoveParam";
import { EintragFrontend } from "src/modules/Datei/interfaces/parameterlisten.types";
import { useEintraegeStore } from "src/modules/Datei/pages/Parameterlisten/Eintraege/useEintraegeStore";
import useAddNewPLItem from "src/modules/Datei/queries/useAddNewPLItem";
import useIsMobileTemplate from "../../../../../../abk-shared/utility/useIsMobileTemplate";
import { parTypePanelsMap } from "../EintragBearbeitenDialog/EigenschaftenfensterPanels/ParTypePanelsMap";
import { SaveType } from "../SaveTypeEnum";
import "./ModalStyles.scss";
import useGetTemplateItem from "./useGetTemplateItem";

export const ITEM_ID_NEW_EINTRAG = "new-item";

type Props = {
  db: string;
  contId: string;
  plID: string;
  saveType: SaveType;
  beforeItemId?: string;
};

export const WrapperNewItemDialog = (props: Props) => (
  <ValidationErrorStoreProvider>
    <NewItemDialog {...props} />
  </ValidationErrorStoreProvider>
);

const NewItemDialog = ({ db, contId, plID, saveType, beforeItemId }: Props) => {
  const { data, isLoading, refetch } = useGetTemplateItem(db, contId, plID);
  const mutationAddNewPLItem = useAddNewPLItem();

  const { setShowNewItemDialog } = useEintraegeStore();

  const isMobile = useIsMobileTemplate();

  const browserWindowHeight = isMobile
    ? window.innerHeight
    : window.innerHeight * 0.7;

  const draftOptions = {
    is404: false,
    isFetching: false,
    initialPageState: data,
    initialSections: new Map([
      ["allgemein", true],
      ["zahl", false],
      ["rechenansatz", false],
      ["awlist", false],
      ["kommentar", false],
      ["zuordnungFunktion", false],
      ["zuordnungProduktgruppe", false],
      ["piktogramm", false],
      ["erweitert", false],
    ]),
    backend: {
      doSave: async (eintrag: Versioned<EintragFrontend>) =>
        mutationAddNewPLItem.mutateAsync({
          dbName: eintrag?.value?.DBNAME || "",
          contId: eintrag?.value?.CONTID || "",
          plID: eintrag?.value?.DATAID || "",
          insertType: saveType,
          fromItemId: beforeItemId,
          eintrag: eintrag.value,
        }),
      doRefetch: refetch,
    },
    drafts: {
      key: (eintrag: EintragFrontend): [string, string, string] => [
        eintrag.CONTID,
        eintrag.DATAID,
        ITEM_ID_NEW_EINTRAG,
      ],
      new: (
        data: Versioned<EintragFrontend>,
        sections: Map<string, boolean>
      ): EintragDraft => ({
        database: data.value.DBNAME,
        contId: data.value.CONTID,
        dataId: data.value.DATAID,
        itemId: ITEM_ID_NEW_EINTRAG,
        etag: data.etag,
        edited: _.cloneDeep(data.value),
        sections: sections,
      }),
      table: dbDrafts.eintraegeDrafts,
    },
  };

  const { removeParam } = useRemoveParam();

  return (
    <div className="modal-overlay">
      <ABKEigenschaftenfensterWithDrafts
        fensterKey={`${contId}-${plID}-new-item`}
        draftsProps={draftOptions}
        title="Eintrag hinzufügen"
        initialHeight={browserWindowHeight}
        onClose={() => {
          setShowNewItemDialog(false);
          removeParam(ITEM_ID_NEW_EINTRAG);
        }}
        renderChildren={(draftValues) => (
          <NewDialogContent
            isLoading={isLoading}
            pageState={draftValues.pageState}
            updatePageState={draftValues.updatePageState}
            sections={draftValues.sections}
            updateSections={draftValues.updateSections}
          />
        )}
      />
    </div>
  );
};

const NewDialogContent = ({
  isLoading,
  pageState,
  updatePageState,
  sections,
  updateSections,
}: {
  isLoading: boolean;
  pageState: Versioned<EintragFrontend> | undefined;
  updatePageState: (pageState: Partial<EintragFrontend>) => Promise<void>;
  sections: Map<string, boolean>;
  updateSections: (sections: Map<string, boolean>) => void;
}) => {
  const panels: Function[] = pageState
    ? parTypePanelsMap.get(pageState.value.ITEMTYPE)
    : ([] as any);

  return (
    <>
      {isLoading && (
        <div className="loader-new-item-dialog">
          <Loader type="infinite-spinner" size="large" />
        </div>
      )}
      {!isLoading && (
        <>
          {panels.map((panel, index) => (
            <div key={index}>
              {panel &&
                panel(
                  pageState?.value,
                  updatePageState,
                  sections,
                  updateSections
                )}
            </div>
          ))}
        </>
      )}
    </>
  );
};
