import {
  TreeListSelectionCell,
  TreeListSelectionCellProps,
} from "@progress/kendo-react-treelist";
import { uniqueId } from "lodash";
import React from "react";

enum CustomCellState {
  ALL_CHILDREN_SELECTED = "ALL_CHILDREN_SELECTED",
  AT_LEAST_ONE_CHILD_SELECTED = "AT_LEAST_ONE_CHILD_SELECTED",
  NO_CHILDREN_AVAILABLE = "NO_CHILDREN_AVAILABLE",
  NO_CHILDREN_SELECTED = "NO_CHILDREN_SELECTED",
}

export const CustomTreeListSelectionCell = (
  props: TreeListSelectionCellProps
) => {
  const id = uniqueId(`tree-list-${props.id}-`);

  const cellState = React.useMemo(() => {
    if (!props.hasChildren) {
      return CustomCellState.NO_CHILDREN_AVAILABLE;
    }

    const hasAllChildrenSelected =
      props.hasChildren &&
      props.dataItem?.subItems?.every((s: any) => s.selected);
    if (hasAllChildrenSelected) {
      return CustomCellState.ALL_CHILDREN_SELECTED;
    }

    const hasAtLeastOneSelectedChild =
      props.hasChildren && hasSelectedItemRecursively(props.dataItem);
    if (hasAtLeastOneSelectedChild) {
      return CustomCellState.AT_LEAST_ONE_CHILD_SELECTED;
    }

    // Default
    return CustomCellState.NO_CHILDREN_SELECTED;
  }, [props.dataItem, props.hasChildren]);

  if (cellState === CustomCellState.NO_CHILDREN_SELECTED) {
    return <TreeListSelectionCell {...props} id={id} />;
  }
  if (cellState === CustomCellState.NO_CHILDREN_AVAILABLE) {
    return <TreeListSelectionCell {...props} id={id} />;
  }
  if (cellState === CustomCellState.ALL_CHILDREN_SELECTED) {
    return <TreeListSelectionCell {...props} id={id} isSelected={true} />;
  }
  if (cellState === CustomCellState.AT_LEAST_ONE_CHILD_SELECTED) {
    return (
      <IndeterminateCellCheckbox
        id={id}
        title={props.dataItem?.NUMMER}
        selectionChange={props.selectionChange}
      />
    );
  }

  return <TreeListSelectionCell {...props} id={id} />;
};

const IndeterminateCellCheckbox = ({
  id,
  title,
  selectionChange,
}: {
  id: string;
  title: string;
  selectionChange?: (e: any) => void;
}) => {
  const handleOnChange = React.useCallback(
    (syntheticEvent: React.SyntheticEvent<HTMLInputElement>) => {
      selectionChange?.({ syntheticEvent });
    },
    [selectionChange]
  );

  return (
    <>
      <td
        className="tree-list-checkbox-indeterminate"
        role="gridcell"
        title={title}
      >
        <input
          id={id}
          type="checkbox"
          className="k-checkbox k-checkbox-md k-rounded-md"
          onClick={handleOnChange}
        />
      </td>
    </>
  );
};

function hasSelectedItemRecursively(dataItem: any): boolean {
  if (dataItem.selected) {
    return true;
  }
  // Check if there are subItems and iterate through them recursively
  if (dataItem.subItems) {
    for (const subItem of dataItem.subItems) {
      if (hasSelectedItemRecursively(subItem)) {
        return true;
      }
    }
  }
  // No selected items found
  return false;
}
