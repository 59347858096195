import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GridRowSelection } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import { deleteDefaultGridItems } from "src/core/components/organisms/DefaultGrid/useDefaultGridDeleteFunction/deleteDefaultGridItems";
import { CatalogType } from "src/modules/Datei/pages/components/CatalogItemsWrapper/CatalogItemsWrapper";
import { QUERY_KEY_CATALOG_ITEM } from "src/modules/Datei/pages/components/CatalogItemsWrapper/useGetAllCatalogItems";

export function useDeleteCatalogItem(
  catalogType: CatalogType,
  dbName: string,
  contId: string,
  setIsGridLoading: React.Dispatch<React.SetStateAction<boolean>>
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (gridRowSelection: GridRowSelection) => {
      setIsGridLoading(true);

      const url = `/api/db/${dbName}/cont/${contId}/${catalogType}`;
      const itemIdsToDelete = gridRowSelection.selectedItems.map(
        (item) => item.DATAID
      );

      return deleteDefaultGridItems(url, itemIdsToDelete);
    },
    onSuccess: async () => {
      return queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_CATALOG_ITEM, dbName, contId, catalogType],
      });
    },
    onError: () => {
      setIsGridLoading(false);
    },
  });
}
