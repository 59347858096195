import { GenericObject } from "src/abk-shared/interfaces/GenericObject";

export function itemsAreDisplayedInQuickInfo(
  selectedContainers: GenericObject[],
  variableDisplayed: GenericObject | GenericObject[]
) {
  if (!Array.isArray(selectedContainers)) return false;

  if (selectedContainers.length === 1) {
    if (Array.isArray(variableDisplayed)) return false;
    return selectedContainers.includes(variableDisplayed);
  }

  if (!Array.isArray(variableDisplayed)) return false;
  return selectedContainers.every(
    (selectedContainer, index) => selectedContainer === variableDisplayed[index]
  );
}
