import {
  Drawer,
  DrawerContent,
  DrawerItemProps,
  DrawerSelectEvent,
} from "@progress/kendo-react-layout";
import React from "react";
import * as Icons from "../../../../abk-shared/interfaces/abk9SvgIcons";
import { NavigationItem } from "../../../interfaces/NavigationItem";
import "./drawer.scss";
import DrawerCustomItem from "./DrawerCustomItem";
import ResizeHandle from "./ResizeHandle";

type Props = {
  children: React.ReactNode;
  currentDrawerItems: NavigationItem[];
  onDrawerItemSelect: (e: DrawerSelectEvent) => void;
};

const AbkDrawer: React.FC<Props> = ({
  currentDrawerItems,
  children,
  onDrawerItemSelect,
}: Props) => {
  const localStorageDrawerOpen = localStorage.getItem("drawerOpen");
  const initialDrawerOpen = localStorageDrawerOpen
    ? localStorageDrawerOpen === "true"
    : true;
  const [drawerOpen, setDrawerOpen] = React.useState(initialDrawerOpen);

  const localStorageDrawerWidth = localStorage.getItem("drawerWidth");
  const initialDrawerWidth = localStorageDrawerWidth
    ? parseInt(localStorageDrawerWidth)
    : 300;
  const [drawerWidth, setDrawerWidth] = React.useState(initialDrawerWidth);

  const { location } = window;

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    localStorage.setItem("drawerOpen", (!drawerOpen).toString());
  };

  const items: DrawerItemProps[] = [
    {
      text: "",
      svgIcon: drawerOpen
        ? Icons.Symbol_ChevronLeft
        : Icons.Symbol_ChevronRight,
      selected: false,
      title: drawerOpen ? "Menü schließen" : "Menü Öffnen",
    },
    { separator: true },
    ...currentDrawerItems.map((item) => ({
      text: item.text,
      route: item.route,
      svgIcon: item.svgIcon,
      selected: location.pathname.includes(item.route),
    })),
  ];

  return (
    <div
      className={
        "drawer-container" + (drawerOpen ? "" : " drawer-container-closed")
      }
    >
      <Drawer
        expanded={drawerOpen}
        position="start"
        mode="push"
        mini={true}
        items={items}
        onSelect={(e: DrawerSelectEvent) => {
          if (e.itemTarget.props.text === "") {
            toggleDrawer();
          } else {
            onDrawerItemSelect(e);
          }
        }}
        width={drawerOpen ? drawerWidth : 50}
        style={{ height: "100%" }}
        item={DrawerCustomItem}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <ResizeHandle
            resizeable={drawerOpen}
            drawerWidth={drawerWidth}
            setDrawerWidth={setDrawerWidth}
          />
          <div
            style={{
              height: "100%",
              display: "flex",
              flex: 1,
              width: "100%",
              overflowY: "auto",
              backgroundColor: "#DFDFDF",
              paddingRight: "0",
              paddingLeft: "0",
              paddingTop: "0.25rem",
              paddingBottom: "0",
            }}
          >
            <DrawerContent>{children}</DrawerContent>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default AbkDrawer;
