import { Button } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Card, CardImage } from "@progress/kendo-react-layout";
import { AbkIconsMap } from "src/abk-shared/interfaces/abk9SvgIcons";
import "./styles.scss";

interface ABKPictureCardProps {
  id: string;
  title: string;
  iconBase64: string;
  isSelected: boolean;
  onDelete: (id: string) => void;
  onOpen: (id: string) => void;
  setSelected: (id: string, selected: boolean) => void;
}

const ABKPictureCard = ({
  id,
  title,
  iconBase64,
  isSelected,
  onDelete,
  onOpen,
  setSelected,
}: ABKPictureCardProps) => {
  return (
    <Card
      data-testid="abk-picture-card"
      className="picture-element-card-container"
      key={`picture-element-${id}`}
    >
      <div
        data-testid="abk-picture-card-buttons"
        className="picture-element-card-buttons-container"
      >
        <div
          data-testid="abk-picture-card-buttons"
          className="picture-element-card-buttons-left-container"
        >
          <Button
            onClick={() => onOpen(id)}
            svgIcon={AbkIconsMap.get("View.Show")}
            title="Ansehen"
          />
          <Button
            onClick={() => onDelete(id)}
            svgIcon={AbkIconsMap.get("Edit.Delete")}
            title="Löschen"
          />
        </div>
        <div
          data-testid="abk-picture-card-buttons"
          className="picture-element-card-buttons-right-container"
        >
          <Checkbox
            style={{ width: 30, height: 30 }}
            checked={isSelected}
            onChange={(e) => setSelected(id, Boolean(e.target.value))}
          />
        </div>
      </div>

      <div
        data-testid="abk-picture-card-picture"
        className="picture-element-card-picture"
      >
        <CardImage src={iconBase64} alt="Icon fehlt" />
      </div>

      <div
        data-testid="abk-picture-card-title"
        className="picture-element-card-title"
      >
        {title ?? ""}
      </div>
    </Card>
  );
};

export default ABKPictureCard;
