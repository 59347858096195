import { Popup } from "@progress/kendo-react-popup";
import React from "react";
import { ToolbarActionObject } from "src/abk-shared/components/molecules/ABKToolbar/abkToolbar.types";
import {
  BasisGridPropsContext,
  SelectionContext,
} from "src/abk-shared/components/organisms/ABKBasisGrid/components/BasisGridContextProvider/contexts";
import ABKNavigationButtonItem from "../../../atoms/ABKNavigationButtonItem";
import { GridContextMenuHandler } from "../hooks/useGridContextMenu";
import DataStateContext from "../hooks/useGridDataState/DataStateContext";

type Props = {
  gridContextMenu: GridContextMenuHandler;
};

const ABKBasisGridContextMenu: React.FC<Props> = ({ gridContextMenu }) => {
  React.useEffect(() => {
    const handleBodyClick = () => {
      gridContextMenu.setShow(false);
    };

    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  const { dataState, dataResultWithSelectedField } =
    React.useContext(DataStateContext);
  const { gridActions } = React.useContext(BasisGridPropsContext);
  const gridRowSelection = React.useContext(SelectionContext);

  const actions = gridActions
    ? gridActions(gridRowSelection, dataResultWithSelectedField, dataState)
    : [];

  const menuItems = actions.map((action, index) => {
    if (React.isValidElement(action)) {
      return (
        <p key={index} className="k-px-lg">
          <i>{action}</i>
        </p>
      );
    }

    const actionObject = action as ToolbarActionObject;
    return (
      <ABKNavigationButtonItem
        text={actionObject.text}
        onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
          actionObject.onClick(event, gridRowSelection.selectedItems);
        }}
        icon={actionObject.icon}
        key={actionObject.text}
      />
    );
  });

  return (
    <Popup show={gridContextMenu.show} offset={gridContextMenu.offset.current}>
      <div data-testid="grid-row-context-menu">{menuItems}</div>
    </Popup>
  );
};

export default ABKBasisGridContextMenu;
