import { useState } from "react";
import ABKControlledExpansionPanel from "src/abk-shared/components/atoms/ABKControlledExpansionPanel";
import ExpansionPanelTitle from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/ExpansionPanelTitle";
import { ZuordnungType } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import { PropsEintragPanel } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ParTypePanelsMap";
import { generateSummaryGsEintragNummer } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/previewTextGenerator";
import ZuordnungPanelContent from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent";
import useZuordnungSelection from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/hooks/useZuordnungSelection";
import useQueryZuordnungData from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/queries/useFetchZuordnungData";
import "./style.scss";

export type PropsZuordnungPanel = PropsEintragPanel & {
  zuordnungType: ZuordnungType;
};

const ZuordnungPanel = ({
  pageState,
  setPageState,
  zuordnungType,
}: PropsZuordnungPanel) => {
  const [expanded, setExpanded] = useState(false);

  const zuordnungData = useQueryZuordnungData(
    pageState.DBNAME,
    pageState.CONTID,
    pageState.DATAID,
    zuordnungType
  );
  const zuordnungSelection = useZuordnungSelection(
    pageState,
    setPageState,
    zuordnungType,
    zuordnungData
  );

  const previewText = generateSummaryGsEintragNummer(
    zuordnungSelection.selectedItems
  );

  return (
    <ABKControlledExpansionPanel
      testProps={{
        ariaLabel: `expansion-panel-zuordnung-${zuordnungType === "Funktion" ? "funktionen" : "produktgruppen"}`,
      }}
      title={
        <ExpansionPanelTitle
          title={`Zuordnung ${zuordnungType === "Funktion" ? "Funktionen" : "Produktgruppen"}`}
          previewText={previewText}
          shouldShowPreview={!expanded}
        />
      }
      expanded={expanded}
      onAction={() => setExpanded(!expanded)}
    >
      <ZuordnungPanelContent
        pageState={pageState}
        setPageState={setPageState}
        zuordnungType={zuordnungType}
        zuordnungSelection={zuordnungSelection}
      />
    </ABKControlledExpansionPanel>
  );
};

export default ZuordnungPanel;
