import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import createFunctionToMigrateColumns, {
  OverwriteProps,
} from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridColumns/persistedColumnsStore/createFunctionToMigrateColumns";
import { PRIMARY_COLUMN_PROPS } from "src/core/components/organisms/DefaultGrid/constants";
import { DefaultGrid } from "src/core/components/organisms/DefaultGrid/DefaultGrid";
import { DATA_KEY } from "../../../../abk-shared/components/organisms/ABKBasisGrid/constants";
import {
  GridColumnPropsCustom,
  GridColumnsMigrateFunction,
} from "../../../../abk-shared/components/organisms/ABKBasisGrid/interfaces/GridColumns";
import { formatDateTimeToBrowserDefault } from "../../../../abk-shared/utility/dateUtils";
import { Job } from "../../../interfaces/Job";
import createQuickInfoForJob from "../../templates/MainPageTemplate/generateSidebarElements/job/createQuickInfoForJob";
import generateQuickInfoForLogsAndFiles from "../../templates/MainPageTemplate/generateSidebarElements/job/generateQuickInfoForLogsAndFiles";
import { useQuickInfoOnGridSelection } from "../DefaultGrid/useDefaultGridSelection/useQuickInfoOnGridSelection";
import JobCard from "./JobCard";

type Props = { jobs: Job[]; gridUniqueId: string };

export default function JobGrid({ jobs, gridUniqueId }: Props) {
  const jobsWithFormattedDate = React.useMemo(
    () =>
      jobs.map((job) => ({
        ...job,
        TIMESTART: {
          [DATA_KEY.INTERNAL]: new Date(job.TIMESTART),
          [DATA_KEY.DISPLAYED]: formatDateTimeToBrowserDefault(job.TIMESTART),
        },
        TIMEEND: {
          [DATA_KEY.INTERNAL]: new Date(job.TIMEEND),
          [DATA_KEY.DISPLAYED]: formatDateTimeToBrowserDefault(job.TIMEEND),
        },
      })),
    [jobs]
  );

  const columnsDefinition = React.useMemo(() => {
    const columns: GridColumnPropsCustom[] = [
      {
        migrationId: "DESCRIPTION",
        field: "DESCRIPTION",
        title: "DESCRIPTION",
        orderIndex: 100,
        minWidth: 300,
        ...PRIMARY_COLUMN_PROPS,
      },
      {
        migrationId: "CONTID",
        field: "CONTID",
        title: "CONTID",
        orderIndex: 200,
      },
      {
        migrationId: "JOBID",
        field: "JOBID",
        title: "JOBID",
        orderIndex: 300,
      },
      {
        migrationId: "OWNER",
        field: "OWNER",
        title: "OWNER",
        orderIndex: 400,
      },
      {
        migrationId: "TIMESTART",
        field: "TIMESTART",
        title: "TIMESTART",
        orderIndex: 500,
        hasInternalValue: true,
      },
      {
        migrationId: "TIMEEND",
        field: "TIMEEND",
        title: "TIMEEND",
        orderIndex: 600,
        hasInternalValue: true,
      },
      {
        migrationId: "COMMAND",
        field: "COMMAND",
        title: "COMMAND",
        orderIndex: 700,
      },
      {
        migrationId: "PARAMS",
        field: "PARAMS",
        title: "PARAMS",
        orderIndex: 800,
      },

      {
        migrationId: "STATUS",
        field: "STATUS",
        title: "STATUS",
        orderIndex: 900,
      },
      {
        migrationId: "CNTERR",
        field: "CNTERR",
        title: "CNTERR",
        orderIndex: 1000,
      },
      {
        migrationId: "CNTWARN",
        field: "CNTWARN",
        title: "CNTWARN",
        orderIndex: 1100,
      },
      {
        migrationId: "CNTINFO",
        field: "CNTINFO",
        title: "CNTINFO",
        orderIndex: 1200,
      },
      {
        migrationId: "LogUrl",
        field: "LogUrl",
        title: "LogUrl",
        orderIndex: 1300,
      },
      {
        migrationId: "FilesUrl",
        field: "FilesUrl",
        title: "FilesUrl",
        orderIndex: 1400,
      },
    ];

    const version = 6;

    // migrate from version 5 or lower to a higher version needs to overwrite "orderIndex" property
    const overwriteProps: OverwriteProps = {
      maxVersion: 5,
      props: ["orderIndex"],
    };

    const migrate: GridColumnsMigrateFunction = createFunctionToMigrateColumns(
      version,
      columns,
      overwriteProps
    );

    return { columns, version, migrate };
  }, []);

  const queryClient = useQueryClient();
  const generateQuickInfoForOneItem = async (item: any) => {
    const quickInfo = createQuickInfoForJob(item);
    const quickInfoForLogsAndFiles = await generateQuickInfoForLogsAndFiles(
      queryClient,
      item
    );
    const quickInfoContent = [...quickInfo, ...quickInfoForLogsAndFiles];
    return quickInfoContent;
  };
  const { changeQuickInfo } = useQuickInfoOnGridSelection(
    generateQuickInfoForOneItem
  );

  return (
    <DefaultGrid
      data={jobsWithFormattedDate}
      columnsDefinition={columnsDefinition}
      persistedDataStateId={{ sector: "jobs", unique: gridUniqueId }}
      dataItemKey="JOBID"
      selection={{
        onSelectionChange: changeQuickInfo,
        hasCheckboxSelection: false,
      }}
      mobileGrid={{
        rowHeight: 110,
        renderRow: (rowRef, item, filterValue) => (
          <JobCard
            rowRef={rowRef}
            item={item as Job}
            filterValue={filterValue}
            onSelectionChange={changeQuickInfo}
          />
        ),
      }}
      generateQuickInfoForOneItem={generateQuickInfoForOneItem}
    />
  );
}
