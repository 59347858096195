import React from "react";
import ABKFormFieldInputWrapper from "src/abk-shared/components/atoms/ABKFormFieldInputWrapper";
import ABKFormRow from "src/abk-shared/components/atoms/ABKFormRow";
import { ABKLoader } from "src/abk-shared/components/atoms/ABKLoader/ABKLoader";
import useIsMobileTemplate from "src/abk-shared/utility/useIsMobileTemplate";

import { PropsZuordnungPanel } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel";
import { ZuordnungSelectionFunctions } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/hooks/useZuordnungSelection";

import useZuordnungTreeData from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/hooks/useZuordnungTreeData/useZuordnungTreeData";
import useQueryZuordnungData from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/queries/useFetchZuordnungData";
import ZuordnungSelection from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/ZuordnungSelection";
import ZuordnungSummary from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/EigenschaftenfensterPanels/ZuordnungPanel/ZuordnungPanelContent/ZuordnungSummary";

type Props = PropsZuordnungPanel & {
  zuordnungSelection: ZuordnungSelectionFunctions;
};

export default function ZuordnungPanelContent({
  pageState,
  setPageState,
  zuordnungType,
  zuordnungSelection,
}: Props) {
  const [filterValue, setFilterValue] = React.useState("");

  const zuordnungData = useQueryZuordnungData(
    pageState.DBNAME,
    pageState.CONTID,
    pageState.DATAID,
    zuordnungType,
    filterValue
  );

  const zuordnungTreeData = useZuordnungTreeData(
    zuordnungData.dataTree,
    zuordnungSelection.selectedState
  );

  const isMobileTemplate = useIsMobileTemplate();

  if (zuordnungData.isLoading) return <ABKLoader />;

  if (zuordnungData.data.length === 0) {
    return (
      <p>
        Es wurde keine Zuordnung für{" "}
        {zuordnungType === "Funktion" ? "Funktionen" : "Produktgruppen"} in
        Kenndaten ausgewählt.
      </p>
    );
  }

  return (
    <ABKFormRow className="zuordnung-expansion-panel-content">
      <ABKFormFieldInputWrapper className="zuordnung-expansion-panel-limit-size">
        <ZuordnungSelection
          zuordnungData={zuordnungData}
          zuordnungTreeData={zuordnungTreeData}
          zuordnungSelection={zuordnungSelection}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
        />
      </ABKFormFieldInputWrapper>

      {!isMobileTemplate && (
        <ABKFormFieldInputWrapper className="zusammenfassung">
          <ZuordnungSummary
            zuordnungSelection={zuordnungSelection}
            pageState={pageState}
            setPageState={setPageState}
            zuordnungType={zuordnungType}
          />
        </ABKFormFieldInputWrapper>
      )}
    </ABKFormRow>
  );
}
