import { OHNE_INHALT } from "src/abk-shared/constants/text";
import { IField } from "./IField";

export function generatePreviewTextOfStrings(
  strings: (string | undefined)[],
  differentDefault: string = OHNE_INHALT
) {
  if (strings.length === 0) return differentDefault;

  return strings.filter((str) => str !== "").join("; ");
}

export default function generatePreviewTextForListOfIFields(fields: IField[]) {
  // case there are no fields
  if (fields.length === 0) return OHNE_INHALT;

  // case all fields are empty -> return ohneInhaltText
  let emptyFields = fields.filter((field) => fieldValueIsEmpty(field));
  if (emptyFields.length === fields.length) return OHNE_INHALT;

  //in case input is empty, then field should not be displayed (neither label nor value)
  let nonEmptyFields = fields.filter((field) => !fieldValueIsEmpty(field));

  let convertedFields = nonEmptyFields.map((field) => {
    if (field.isCheckbox) return field.label; // case it is checkbox -> only return the label
    return field.label + ": " + String(field.value);
  });

  let previewText = convertedFields.join("; ");
  return previewText;
}

export function generatePreviewForTextArea(field: IField | undefined) {
  if (field === undefined) return "";
  if (field.value === "" || field.value === undefined || field.value === null)
    return OHNE_INHALT;
  return String(field.value);
}

function fieldValueIsEmpty(
  field: IField,
  checkboxNotSelectedBox: string = "Nicht selektiert"
) {
  const fieldValue = field.value;

  if (field.isCheckbox) return fieldValue === checkboxNotSelectedBox;

  return fieldValue === "" || fieldValue === undefined || fieldValue === null;
}
