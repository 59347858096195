import { Button } from "@progress/kendo-react-buttons";
import { createSearchParams, Link, NavigateFunction } from "react-router-dom";
import { INotification } from "src/abk-shared/components/organisms/ABKSideBar/NotificationBox";
import { DraftNotificationMessage } from "src/core/components/templates/useDraftNotifications/DraftNotificationMessage";
import { EintragDraft } from "src/core/db/dbDrafts";
import { createEintragURL } from "src/modules/Datei/pages/Parameterlisten/Eintraege/useEintraege/createEintragURL";

export type DraftNotificationNavigationState = {
  uniqueId: number;
};

export const SEARCH_PARAM_SCROLL_TO_ITEM = "scroll-to-item";

export function generateDraftNotificationForEintrag(
  draft: EintragDraft,
  navigate: NavigateFunction
) {
  const url = createEintragURL(
    draft.database,
    draft.contId,
    "pl",
    draft.dataId,
    draft.itemId
  );
  const searchParams = createSearchParams({
    [SEARCH_PARAM_SCROLL_TO_ITEM]: true.toString(),
  }).toString();

  return {
    message: (
      <DraftNotificationMessage>
        <>
          Ungespeicherte Eingabe in Ordner Parameterliste (
          <Link
            to={{
              pathname: url,
              search: searchParams,
            }}
          >
            {draft.edited.BEZ}
          </Link>
          )
        </>
      </DraftNotificationMessage>
    ),
    type: "info",
    quickInfo: [
      {
        title: "Entwurf",
        content: "Ungespeicherte Eingabe in Ordner Parameterliste",
      },
      { title: "Type", content: "INFO" },
    ],
    notificationButton: (
      <Button
        themeColor="primary"
        onClick={() => {
          navigate({
            pathname: url,
            search: searchParams,
          });
        }}
      >
        Öffnen
      </Button>
    ),
    closable: false,
  } as INotification;
}
