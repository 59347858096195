import React from "react";
import ABKControlledExpansionPanel from "src/abk-shared/components/atoms/ABKControlledExpansionPanel";
import ExpansionPanelTitle from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/ExpansionPanelTitle";
import generatePreviewTextForListOfIFields from "src/abk-shared/components/atoms/ABKControlledExpansionPanel/previewTextGenerator";
import { OHNE_INHALT } from "src/abk-shared/constants/text";
import QuickInfoContentWithMultipleLines from "../../../../../../abk-shared/components/organisms/ABKSideBar/QuickInfo/QuickInfoContentWithMultipleLines";
import QuickInfoItem from "../../../../../../abk-shared/components/organisms/ABKSideBar/QuickInfo/QuickInfoItem";
import { ABKError } from "../../../../../../abk-shared/interfaces/Error";

export const TECHNICAL_INFORMATION = "Technische Informationen";
export default function TechnicalInformation({
  abkError,
}: {
  abkError: ABKError;
}) {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const previewFields = [
    {
      label: "HTTP-Statuscode",
      value: abkError.status?.toString() || OHNE_INHALT,
      isCheckbox: false,
    },
    {
      label: "Meldungscode",
      value: abkError.type || OHNE_INHALT,
      isCheckbox: false,
    },
  ];
  const previewText = generatePreviewTextForListOfIFields(previewFields);

  return (
    <ABKControlledExpansionPanel
      expanded={isExpanded}
      title={
        <ExpansionPanelTitle
          title={TECHNICAL_INFORMATION}
          previewText={previewText}
          shouldShowPreview={!isExpanded}
        />
      }
      onAction={() => setIsExpanded(!isExpanded)}
    >
      <QuickInfoItem title="Meldungscode" content={abkError.type} />
      <QuickInfoItem title="HTTP-Statuscode" content={abkError.status} />
      <QuickInfoItem
        title="TechInfo"
        content={
          <QuickInfoContentWithMultipleLines itemContent={abkError.TechInfo} />
        }
      />
      <QuickInfoItem
        title="Callstack"
        content={
          <QuickInfoContentWithMultipleLines itemContent={abkError.CallStack} />
        }
      />
    </ABKControlledExpansionPanel>
  );
}
