export const DATA_KEY = {
  INTERNAL: "internal",
  DISPLAYED: "displayed",
} as const;

// From https://stackoverflow.com/a/67911065
export type GridKeys = (typeof DATA_KEY)[keyof typeof DATA_KEY];

export type GridFieldWithInternalValue = Record<GridKeys, any>;

export const KEY_REMOVE_FROM_FILTERING = "removeFromFiltering";

export const GRID_ROW_HEIGHT = 33.8;

export const ABK_BASIS_GRID_COLUMN_MIN_WIDTH = 72;
