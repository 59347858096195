import { GliederungssystemSubtype } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import { GliederungssystemProps } from "src/modules/Datei/pages/components/KenndatenPanels/KenndatenPanels";
import ABKFormDropdown from "../../../../../../abk-shared/components/atoms/ABKFormDropdown";

export function DropDownGliederungsart({
  gliederungssystemSubtype,
  setGliederungssystemSubtype,
}: GliederungssystemProps) {
  const dataItemKey = "id";
  const textField = "text";
  const defaultValue = {
    [textField]: "Allgemein",
    [dataItemKey]: GliederungssystemSubtype.Allgemein,
  };

  const gliederungsartOptions = [
    defaultValue,

    {
      [textField]: "Funktion",
      [dataItemKey]: GliederungssystemSubtype.Funktion,
    },
    {
      [textField]: "Produkt",
      [dataItemKey]: GliederungssystemSubtype.Produkt,
    },
  ];
  const backendKey = "SUBTYPE";
  const value = gliederungsartOptions.find(
    (option) => option[dataItemKey] === gliederungssystemSubtype
  );

  return (
    <ABKFormDropdown
      label="Gliederungsart"
      name="allgemein-gliederungsart"
      textField={textField}
      dataItemKey={dataItemKey}
      data={gliederungsartOptions}
      value={value}
      onChange={(event) =>
        setGliederungssystemSubtype!(event.value[dataItemKey])
      }
      editorId={"status"}
      backendKey={backendKey}
    />
  );
}
