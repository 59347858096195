import { Button } from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import React from "react";
import ABKInfoPopup from "src/abk-shared/components/atoms/ABKInfoPopup";
import ABKLabel from "src/abk-shared/components/atoms/ABKLabel";
import { ABKUploadButtonWrapper } from "src/abk-shared/components/atoms/ABKUploadButtonWrapper";
import {
  Dummy_Missing,
  Edit_Delete,
  General_Export,
} from "src/abk-shared/interfaces/abk9SvgIcons";
import LogoDisplayer from "src/core/components/atoms/LogoDisplayer";
import downloadFile from "src/core/utility/downloadFile";
import {
  generateBase64Image,
  getLogoFormat,
  imageToBase64,
  LogoFormat,
  removeBase64Prefix,
} from "src/core/utility/imageProcessing";
import { removeFileType } from "src/core/utility/removeFileType";
import { DUMMY_MISSING_BASE64 } from "../piktogramm.types";
import "./styles.scss";

interface Props {
  base64Image: string;
  imageFormat: LogoFormat.SVG;
  imageName: string;
  setBase64Image: (base64: string) => void;
  setFileName: (name: string) => void;
  disabled: boolean;
}

const messageAvailableFormats = "Erlaubte Dateiformate: SVG";

export function UploadLogoPiktogramm({
  imageName,
  imageFormat,
  base64Image,
  setBase64Image,
  setFileName,
  disabled,
}: Props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setErrorMessage("");
    setIsLoading(true);
    const inputFile = event.target.files?.[0];
    const userCanceledFileSelection = !inputFile;
    if (userCanceledFileSelection) {
      setIsLoading(false);
      return;
    }

    const nameWithoutType = removeFileType(inputFile.name);
    setFileName(nameWithoutType);
    const type = inputFile.type;
    const logoFormat = getLogoFormat(type);
    if (logoFormat !== LogoFormat.SVG) {
      setErrorMessage(`Dateiformat nicht erlaubt. ${messageAvailableFormats}`);
      setIsLoading(false);
      return;
    }

    if (logoFormat !== LogoFormat.SVG) {
      setErrorMessage(`Dateiformat nicht erlaubt. ${messageAvailableFormats}`);
      setIsLoading(false);
      return;
    }

    const fileBase64WithoutPrefix = await imageToBase64(inputFile);
    setIsLoading(false);
    setBase64Image(fileBase64WithoutPrefix);
  };

  const tooltip = `${messageAvailableFormats}; Kein Limit bei der Dateigröße.`;

  const { hasImage, imageBase64 } = generateBase64Image(
    base64Image,
    imageFormat
  );

  const downloadImage = React.useCallback(() => {
    downloadFile(imageBase64, imageName + ".svg");
  }, [imageBase64, imageName]);

  return (
    <div>
      <ABKLabel editorId="allgemein-status">
        Piktogramm
        <ABKInfoPopup popupContent={tooltip} />
      </ABKLabel>
      <div className="upload-logo-piktogramm-img-button-container">
        <div className="upload-logo-piktogramm-img-container">
          {hasImage && (
            <LogoDisplayer
              isLoading={isLoading}
              hasImage={hasImage}
              imageBase64={imageBase64}
              errorMessage={errorMessage}
            />
          )}
          {!hasImage && (
            <div
              style={{
                height: "110px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SvgIcon icon={Dummy_Missing} width={40} height={40} />
            </div>
          )}
        </div>

        <div className="upload-logo-piktogramm-button-container">
          <ABKUploadButtonWrapper
            onChange={handleFileUpload}
            buttonId="uplaodLogoButton"
            className="k-d-inline-block"
            disabled={disabled}
            multiple={false}
            accept=".svg"
            svgIconKey="General.Import"
            title="Hochladen"
          />
          <Button
            onClick={async () => {
              const fileBase64WithoutPrefix =
                removeBase64Prefix(DUMMY_MISSING_BASE64);
              setBase64Image(fileBase64WithoutPrefix);
            }}
            svgIcon={Edit_Delete}
            title="Löschen"
            disabled={disabled || !hasImage}
          />
          <Button
            onClick={downloadImage}
            svgIcon={General_Export}
            title="Herunterladen"
            disabled={disabled || !hasImage}
          />
        </div>
      </div>
    </div>
  );
}
