import { formatDateTimeToBrowserDefault } from "src/abk-shared/utility/dateUtils";
import { KenndatenStatus } from "src/modules/Datei/interfaces/kenndaten.types";
import { statusMapper } from "src/modules/Datei/pages/components/CatalogItemsWrapper/statusMapper";

export function generatePreviewTextAllgemeinKenndaten(
  bezeichnung: string,
  kennung: string,
  version: number,
  datum: string | null,
  status: KenndatenStatus
) {
  let previewText = "";

  previewText += "Bezeichnung: ";
  previewText += bezeichnung;
  previewText += ` [${kennung}]`;

  previewText += ", ";

  previewText += "Version ";
  previewText += version.toString();
  previewText += " vom ";
  previewText += formatDateTimeToBrowserDefault(datum, true);

  previewText += "; ";

  previewText += "Status: ";
  previewText += statusMapper.get(status);

  return previewText;
}
