import "./style.scss";

type Props = { children: React.ReactNode };
export default function ABKFormErrorMessage({ children }: Props) {
  return (
    <div
      className={`form-field-input-error-message test-e2e-abk-form-error-message`}
    >
      {children}
    </div>
  );
}
