import { useLocation, useNavigate } from "react-router-dom";

export const useRemoveParam = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const removeParam = (paramValue: string) => {
    if (!paramValue) {
      return;
    }
    const newPath = location.pathname.replaceAll(`/${paramValue}`, "");
    navigate(newPath);
  };

  return { removeParam };
};
