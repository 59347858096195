export const BACKEND_URL = "";
export const PORTAL_URL = "https://www.abk.today";

export const DATABASE_PLACEHOLDER = "%datenbank%";
const datenbank = `/db/${DATABASE_PLACEHOLDER}`;
export const DEFAULT_DB = "_default_";

// Startseite
export const STARTSEITE = `${datenbank}/start`;
export const OPEN = `${STARTSEITE}/open`;
export const IMPORT = `${STARTSEITE}/import`;
export const EXPORT = `${STARTSEITE}/export`;
export const TEMPLATES = `${STARTSEITE}/new`;
export const FILES = `${STARTSEITE}/files`;

// Tabs (auch Filter-Buttons genannt)
export const LAST_USED = "last-used";
// N.B.: Die anderen sind jetzt nicht hier, sondern direkt in den URLs geschrieben

export const CONTID_PLACEHOLDER = "%contId%";
export const DATA_ID_PLACEHOLDER = "%dataId%";
export const ITEM_ID_PLACEHOLDER = "%itemId%";
export const CATALOG_TYPE_PLACEHOLDER = "%catalogType%";

// Datei
export const FILE_IN_URL = "file";
export const FILE_PREFIX = `${datenbank}/${FILE_IN_URL}`;

export const FILE_INFO_SUFFIX = "file-info";
export const FILE_INFO = `${FILE_PREFIX}/${CONTID_PLACEHOLDER}/${FILE_INFO_SUFFIX}`;

export const INFO_SUFFIX = "info";
export const INFO = `${FILE_PREFIX}/${CONTID_PLACEHOLDER}/${INFO_SUFFIX}`;

export const FILE_PROTOCOL_SUFFIX = "protocol";
export const FILE_PROTOCOL = `${FILE_PREFIX}/${CONTID_PLACEHOLDER}/${FILE_PROTOCOL_SUFFIX}`;

export const FILE_AUFBAU_SUFFIX = "aufbau";
export const FILE_AUFBAU = `${FILE_PREFIX}/${CONTID_PLACEHOLDER}/${FILE_AUFBAU_SUFFIX}`;

// Catalogs
export const FILE_CATALOG_ITEM = `${FILE_PREFIX}/${CONTID_PLACEHOLDER}/${CATALOG_TYPE_PLACEHOLDER}`;

export const FILE_KENNDATEN_SUFFIX = "overview";
export const FILE_KENNDATEN = `${FILE_CATALOG_ITEM}/${DATA_ID_PLACEHOLDER}/${FILE_KENNDATEN_SUFFIX}`;

export const FILE_KENNDATEN_VORVERSION_SUFFIX = "vorversion";
export const FILE_KENNDATEN_VORVERSION = `${FILE_CATALOG_ITEM}/${DATA_ID_PLACEHOLDER}/${FILE_KENNDATEN_VORVERSION_SUFFIX}`;

export const FILE_EINTRAEGE_SUFFIX = "items";
export const FILE_EINTRAEGE = `${FILE_PREFIX}/${CONTID_PLACEHOLDER}/${FILE_EINTRAEGE_SUFFIX}`;
export const FILE_EINTRAG_ID = `${FILE_CATALOG_ITEM}/${DATA_ID_PLACEHOLDER}/${FILE_EINTRAEGE_SUFFIX}/${ITEM_ID_PLACEHOLDER}`;

export const FILE_GRAFIKEN_SUFFIX = "grafiken";
export const FILE_GRAFIKEN = `${FILE_PREFIX}/${CONTID_PLACEHOLDER}/${FILE_GRAFIKEN_SUFFIX}`;

export const FILE_PIKTOGRAMME_SUFFIX = "piktogramme";
export const FILE_PIKTOGRAMME = `${FILE_PREFIX}/${CONTID_PLACEHOLDER}/${FILE_PIKTOGRAMME_SUFFIX}`;

export const FILE_WEITEREGLIEDERUNGSSYSTEME_SUFFIX = "gs";

export const FILE_WEITEREPARAMETERLISTEN_SUFFIX = "pl";

// Admin
export const ADMIN_SUFFIX = "admin";
export const ADMIN = `${datenbank}/${ADMIN_SUFFIX}`;

export const ADMIN_ORG_SUFFIX = "org";
export const ADMIN_ORG = `${ADMIN}/${ADMIN_ORG_SUFFIX}`;

export const ADMIN_DEPARTMENTS_SUFFIX = "departments";
export const ADMIN_DEPARTMENTS = `${ADMIN}/${ADMIN_DEPARTMENTS_SUFFIX}`;

export const ADMIN_DATABASES_SUFFIX = "databases";
export const ADMIN_DATABASES = `${ADMIN}/${ADMIN_DATABASES_SUFFIX}`;

export const ADMIN_LICENSES_SUFFIX = "licenses";
export const ADMIN_LICENSES = `${ADMIN}/${ADMIN_LICENSES_SUFFIX}`;

export const ADMIN_USERS_SUFFIX = "users";
export const ADMIN_USERS = `${ADMIN}/${ADMIN_USERS_SUFFIX}`;

export const ADMIN_SYSINFO_SUFFIX = "sysinfo";
export const ADMIN_SYSINFO = `${ADMIN}/${ADMIN_SYSINFO_SUFFIX}`;

export const ADMIN_DB_STATUS_SUFFIX = "db-status";
export const ADMIN_DB_STATUS = `${ADMIN}/${ADMIN_DB_STATUS_SUFFIX}`;

export const ADMIN_DB_PROTOCOL_SUFFIX = "db-protocol";
export const ADMIN_DB_PROTOCOL = `${ADMIN}/${ADMIN_DB_PROTOCOL_SUFFIX}`;

export const ADMIN_SERVER_STATUS_SUFFIX = "server-status";
export const ADMIN_SERVER_STATUS = `${ADMIN}/${ADMIN_SERVER_STATUS_SUFFIX}`;

export const ADMIN_SERVER_LOG_SUFFIX = "server-log";
export const ADMIN_SERVER_LOG = `${ADMIN}/server-log`;

export const ADMIN_SERVER_INFO_SUFFIX = "server-info";
export const ADMIN_SERVER_INFO = `${ADMIN}/${ADMIN_SERVER_INFO_SUFFIX}`;

// User
export const USER_SUFFIX = "user";
export const USER = `${datenbank}/${USER_SUFFIX}`;

export const USER_PROFILE_SUFFIX = "profile";

export const USER_PROFILE = `${USER}/${USER_PROFILE_SUFFIX}`;

export const USER_LOCAL_BROWSER_CONFIG_SUFFIX = "local-browser-configuration";

export const USER_LOCAL_BROWSER_CONFIG = `${USER}/${USER_LOCAL_BROWSER_CONFIG_SUFFIX}`;

export const USER_OPTIONS = `${USER}/options`;

export const USER_BROWSER_OPTIONS = `${USER_OPTIONS}/browser`;

export const USER_VIEW_OPTIONS = `${USER_OPTIONS}/view`;

export const USER_AVAS_OPTIONS = `${USER_OPTIONS}/ava`;

// HELP
export const HELP_SUFFIX = "help";
export const HELP = `/${HELP_SUFFIX}`;

export const HELP_START_SUFFIX = "appinfo";
export const HELP_START = `${HELP}/${HELP_START_SUFFIX}`;

export const HELP_SYSINFO_SUFFIX = "sysinfo";
export const HELP_SYSINFO = `${HELP}/${HELP_SYSINFO_SUFFIX}`;

export const HELP_ADMIN_HELP_SUFFIX = "admin-help";
export const HELP_ADMIN_HELP = `${HELP}/${HELP_ADMIN_HELP_SUFFIX}`;

export const HELP_ABOUT = `${HELP}/appinfo`;

export const HELP_VERSION = `${HELP}/version`;

export const HELP_SUPPORT = `${HELP}/support`;

export const HELP_IMPRESSUM = `${HELP}/impressum`;

export const HELP_DATENSCHUTZ = `${HELP}/datenschutz`;
